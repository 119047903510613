import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "components/layouts/AuthLayout";
import { AuthContextProvider } from "context/AuthContext";

import LoadingOverlay from "components/shared/fallback-spin/LoadingOverlay";
import MainLayout from "components/layouts/MainLayout";

import DefaultLayout from "components/layouts/DefaultLayout";
// import AdminLayout from "components/layouts/AdminLayout";
import Logout from "pages/admin/components/Logout";

import {
	AboutUsPage,
	LandingPage,
	LoginPage,
	PortfolioPage,
	SignUpPage,
	SoftwareDevelopmentPage,
	ArtificialIntelligencePage,
	QualityAssurancePage,
	UIDesignPage,
	BlogPage,
	CareersPage,
	SinglePortfolioPage,
	CareersApplyPage,
	ContactUsPage,
	ProductDevelopmentPage,
	MinimumViableProductPage,
	ExtendedTeamsPage,
	FaqPage,
	TermsAndConditionsPage,
	PrivacyPolicyPage,
	SingleArticlePage,
} from "pages";
import {
	AdminBlogPostPage,
	AdminCandidatesResumePage,
	AdminDashboardPage,
	AdminMessagesPage,
	AdminNewEmploymentPage,
	AdminPortfolioPage,
	AdminTeamEventsPage,
	AdminTestimonialPage,
} from "pages/admin";

const LazyNotFoundExcept = React.lazy(() =>
	import("components/errors/NotFoundExcept"),
);
const LazyAdminLayout = React.lazy(() =>
	import("components/layouts/AdminLayout"),
);

// { path: "/", element: <Navigate replace to="/" /> },
export default function Router() {
	const element = useRoutes([
		{
			element: <MainLayout />,
			children: [
				{ path: "/", element: <LandingPage /> },
				{ path: "about-us", element: <AboutUsPage /> },
				{ path: "contact-us", element: <ContactUsPage /> },
				{ path: "portfolio", element: <PortfolioPage /> },
				{ path: "portfolio/:name", element: <SinglePortfolioPage /> },
				{ path: "software-development", element: <SoftwareDevelopmentPage /> },
				{ path: "product-development", element: <ProductDevelopmentPage /> },
				{
					path: "minimum-viable-product",
					element: <MinimumViableProductPage />,
				},
				{ path: "teams", element: <ExtendedTeamsPage /> },
				{
					path: "artificial-intelligence",
					element: <ArtificialIntelligencePage />,
				},
				{ path: "quality-assurance", element: <QualityAssurancePage /> },
				{ path: "ui-design", element: <UIDesignPage /> },
				{ path: "blog", element: <BlogPage /> },
				{ path: "article/:tag", element: <SingleArticlePage /> },
				{ path: "careers", element: <CareersPage /> },
				{ path: "careers/:name", element: <CareersApplyPage /> },
				{ path: "faq", element: <FaqPage /> },
				{
					path: "privacy-policy",
					element: <PrivacyPolicyPage />,
				},
				{ path: "terms-and-conditions", element: <TermsAndConditionsPage /> },
			],
		},
		{
			element: <AuthLayout />,
			children: [
				{ path: "signin", element: <LoginPage /> },
				{ path: "signup", element: <SignUpPage /> },
			],
		},

		{
			path: "es-admin",
			element: (
				<AuthContextProvider>
					<React.Suspense fallback={<LoadingOverlay />}>
						<LazyAdminLayout />
					</React.Suspense>
				</AuthContextProvider>
			),
			children: [
				{
					path: "*",
					element: <Navigate replace to="dash" />,
				},
				{
					path: "",
					element: <Navigate replace to="dash" />,
				},
				{ path: "dash", element: <AdminDashboardPage /> },
				{ path: "employment", element: <AdminNewEmploymentPage /> },
				{ path: "portfolio", element: <AdminPortfolioPage /> },
				{ path: "blogs", element: <AdminBlogPostPage /> },
				{ path: "testimonials", element: <AdminTestimonialPage /> },
				{ path: "candidates", element: <AdminCandidatesResumePage /> },
				{ path: "events", element: <AdminTeamEventsPage /> },
				{ path: "messages", element: <AdminMessagesPage /> },
				{ path: "logout", element: <Logout /> },
			],
		},
		{
			element: <DefaultLayout />,
			children: [
				{
					path: "*",
					element: <Navigate replace to="/404" />,
				},
				{
					path: "404",
					element: (
						<React.Suspense fallback={<LoadingOverlay />}>
							<LazyNotFoundExcept />
						</React.Suspense>
					),
				},
			],
		},
	]);
	return element;
}
