import React from "react";

function MapMarkPoint({
	name = "",
	top = "",
	right = "",
	flag = null,
	projects = [],
}) {
	// const coordinate = {
	// 	top: `top-[${top}%]`,
	// 	right: `top-[${right}%]`,
	// };

	return (
		<div
			style={{ top: `${top}%`, right: `${right}%` }}
			className={`
			group
			absolute z-0
			cursor-pointer
			rounded-full
			bg-gray-500
			p-1
			text-arsenic-900`}
		>
			{/* #5b18ce */}
			<div className="mark-wrapper relative">
				<div className="flag relative flex h-8 w-8 items-center justify-center">
					<span
						className="
						map-pointer absolute  
						-bottom-5 left-[21%]
					 	inline-block
					  	h-5 w-5 rotate-180
					   bg-gray-500"
					/>
					<img
						src={flag}
						alt={name}
						className="
						h-full w-full
						rounded-full
						border-2
					  border-gray-300
					    object-contain"
					/>
				</div>

				<div
					className="
					absolute
					top-[0%] left-[120%]
					hidden
					w-auto rounded
           			bg-gray-100 p-4
					text-yankees-blue-900
					 shadow-xl group-hover:block"
				>
					<small
						className="
						ff-urban
						block 
						whitespace-nowrap
						text-base 
						font-bold
						capitalize"
					>
						{name}
					</small>
					<ul className="space-y-2 py-2">
						{projects &&
							projects.map((p, i) => (
								<li
									key={i}
									className="
									ff-urban
								 	whitespace-nowrap
									text-sm
									font-semibold
								  	tracking-wide"
								>
									{p}
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default React.memo(MapMarkPoint);
