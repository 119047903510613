import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "services/auth-amplify-service";

function Logout() {
	const navigation = useNavigate();
	useEffect(() => {
		const requestLogout = async () => {
			const res = await signOut();
			if (res.isLogout) {
				navigation("/");
			} else if (res.err) {
				console.log("Error while logout: ", res.err);
			}
		};

		requestLogout();
	}, []);
	return (
		<div className="block p-2 text-base">
			request system logout admin user...
		</div>
	);
}

export default Logout;
