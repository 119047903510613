import React, { useEffect, useState } from "react";

import { MdNoteAdd } from "react-icons/md";
import {
	getAllEmployments,
	getEmploymentById,
} from "services/all-admin-service";
import PopupWizardOverlay from "components/shared/popup-wizard";
import ExclusiveSetting from "../components/ExclusiveSetting";
import FormPopupWizard from "../components/FormPopupWizard";
import AddRPForm from "../components/form/AddRPForm";
import GerneralView from "../components/employment/GerneralView";
import DataTableView from "../components/DataTableView";
import { tblrecruitment } from "../admin.data";

const modalInitialState = { modal: null, state: false };

function AdminNewEmploymentPage() {
	const [viewModal, setViewModal] = useState(modalInitialState);
	const [tableSheet, setTableSheet] = useState([]);
	const [openRow, setOpenRow] = useState(null);

	async function onLoadTableSheet() {
		const res = await getAllEmployments();
		if (res?.response && res?.response.status === 200) {
			const { response } = res;
			setTableSheet(response.data);
		}
	}

	async function getRowById(id) {
		if (id === null) return;

		const res = await getEmploymentById(id);
		if (res?.response && res?.response.status === 200) {
			const { response } = res;
			setOpenRow(response.data);
		}
	}

	useEffect(() => {
		onLoadTableSheet();
	}, []);

	function handleUpdatePage(request) {
		console.debug(request);
	}

	function handleModal(modal, row = null) {
		if (!(row === null)) {
			getRowById(row.id);
		}
		setViewModal((prev) => ({
			...prev,
			modal,
			state: !prev.state,
		}));
	}

	function handleExitModal() {
		getRowById(null);
		setViewModal(modalInitialState);
	}

	function handleAfterFormActionEnd() {
		setTimeout(() => {
			getRowById(null);
			setViewModal(modalInitialState);
			onLoadTableSheet();
		}, 2000);
	}

	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div className="content-header block items-center justify-between lg:flex">
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								employment / recruitment
							</h3>

							<p
								className="ff-urban max-w-full py-2
								text-base tracking-wider text-dark-jungle/70 lg:max-w-[80%] xl:lg:max-w-[60%]"
							>
								Recruitment is the process of finding, screening, hiring and
								eventually onboarding qualified job candidates.
							</p>
						</div>
						<div className="top-right">
							<div className="actions">
								<button
									type="button"
									onClick={() => handleModal("insert")}
									className="inline-flex items-center whitespace-nowrap
							 		rounded bg-dark-cyan px-3 py-2 text-[15px]
									tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<MdNoteAdd size={19} className="text-white" />
									<span className="ff-urban ml-1">
										Generate new employement position
									</span>
								</button>
							</div>
						</div>
					</div>
					{/* END HEADER */}
					<DataTableView
						handleClickActionButton={handleModal}
						handlePageNavsClick={handleUpdatePage}
						theadCols={tblrecruitment.headings}
						sheet={tableSheet}
						cols={[
							"id",
							"group",
							"title",
							"worktype",
							"published",
							"createdAt",
						]}
					/>
					{/* content-Panel box end */}
				</div>
			</div>

			{/* Modal popup modal */}
			<PopupWizardOverlay
				isOpen={viewModal.state}
				handleClickOverlay={() => console.debug("overlay")}
			>
				<FormPopupWizard
					onExit={handleExitModal}
					title={
						viewModal.modal === "insert"
							? "add new recruitment / employement"
							: "exclusive setting recruitment / employement"
					}
				>
					{viewModal.modal === "insert" ? (
						<AddRPForm
							form="insert"
							onAfterFormActionEnd={handleAfterFormActionEnd}
						/>
					) : null}
					{viewModal.modal === "settings" ? (
						<ExclusiveSetting
							tabsviews={[
								<GerneralView exclusive={openRow} />,
								<AddRPForm
									form="advance"
									exclusive={openRow}
									onAfterFormActionEnd={handleAfterFormActionEnd}
								/>,
							]}
						/>
					) : null}
				</FormPopupWizard>
			</PopupWizardOverlay>
		</section>
	);
}

export default AdminNewEmploymentPage;
