import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { trustedByList } from "assets/data/home.dto";
import { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "../Landing.styles.css";

function TrustedByList() {
	return (
		<section className="h-auto w-full bg-[#F8F8F8] py-0">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="heading-title mx-auto max-w-3xl pt-16 text-center">
					<h3 className="ff-sanspro mb-8 inline-block text-xl font-semibold text-arsenic-600">
						Trusted By
					</h3>
					<p className="ff-urban text-sm font-medium tracking-wide text-arsenic-600 lg:text-base">
						Our software development company is trusted by top clients and
						partners around the world. We are proud to have worked with these
						leading organizations, and are grateful for their trust in our
						expertise and solutions.
					</p>
				</div>
				<div className="trusted-wrapper pt-3 pb-6 lg:pb-12">
					<Swiper
						className="trusted-by-swiper"
						modules={[Autoplay]}
						speed={1000}
						loop={false}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						breakpoints={{
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 15,
							},
							1024: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
							1366: {
								slidesPerView: 4,
								spaceBetween: 24,
							},
						}}
					>
						{trustedByList.map((trust, index) => (
							<SwiperSlide
								key={index}
								className="w-full sm:w-1/2 lg:py-4 xl:w-1/4"
							>
								<div className="flex h-full w-full flex-1 items-center justify-center opacity-75">
									<img
										className="h-[150px] w-auto
                     					object-contain object-center drop-shadow-xl
										grayscale invert-[0.2]  hover:grayscale-0 hover:invert-0"
										src={trust.image}
										alt={trust.title}
									/>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default TrustedByList;
