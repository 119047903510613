import React from "react";
import { spirit } from "assets/data/aboutus.dto";

function OurSpirit() {
	const { tagname, quotes, para, image1, image2 } = spirit;

	return (
		<section className="our-spirit-section mt-24 mb-8 h-full w-full lg:mb-32">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="spirit-wrapper flex h-full w-full flex-col items-center gap-10 lg:flex-row">
					<div className="left order-2 w-full lg:order-1 lg:w-1/2">
						<div className="double-image-box relative flex w-full gap-0 pt-12 lg:pt-4">
							{/* image background */}
							<div className="-rotate-10 absolute top-8 z-[-1] bg-orange-100  lg:left-[55%] lg:h-60 lg:w-48 xl:h-72 xl:w-64 2xl:left-[62%]" />
							<div className="-rotate-10 absolute bottom-8 z-[-1] bg-yankees-blue-100 lg:right-[55%] lg:h-60 lg:w-48 xl:h-72 xl:w-64 2xl:right-[62%]" />
							{/* image background */}
							<div className="imgbox relative -top-4 w-1/2">
								<img
									className="h-[85%] w-full object-contain xl:h-[90%]"
									src={image1}
									alt="image1"
								/>
							</div>
							<div className="imgbox relative top-16 w-1/2">
								<img
									className="h-[85%] w-full object-contain xl:h-[90%]"
									src={image2}
									alt="image2"
								/>
							</div>
						</div>
					</div>
					<div className="right order-1 w-full lg:order-2 lg:w-1/2">
						<div className="content-box mx-auto w-full lg:max-w-full">
							<div className="title">
								<h4
									className="ff-sanspro block text-base font-bold capitalize
							    tracking-wide text-halloween-orange-dark lg:text-xl"
								>
									{tagname}
								</h4>
								<h1
									className="block py-4 text-3xl font-normal leading-[2.5rem] text-arsenic-900
                    				xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
								>
									{quotes}
								</h1>
								<p
									className="ff-urban max-w-full text-sm leading-normal tracking-wide
								 text-arsenic-600 lg:text-base lg:leading-7"
								>
									{para}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default OurSpirit;
