import React from "react";

function TechServiceStack(props) {
	const { tagname, title, para, techbrands } = props;

	return (
		<section className="techstack-section mb-8 h-full w-full lg:mb-12">
			<div className="container z-[1] mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="techstack-wrapper h-full w-full">
					<div className="block-container flex flex-col justify-center py-8  lg:py-16">
						<div className="title text-center">
							<h4
								className="ff-sanspro block text-base font-bold capitalize
							    tracking-wide text-halloween-orange-dark lg:text-xl"
							>
								{tagname}
							</h4>
							<h1
								className="block py-4 text-3xl font-normal leading-[2.5rem] text-arsenic-900
                    			xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
							>
								{title}
							</h1>
							<p
								className="ff-urban mx-auto max-w-[90%] text-sm
							 tracking-wider text-arsenic-600 lg:max-w-[75%] lg:text-base xl:max-w-[45%]"
							>
								{para}
							</p>
						</div>

						<div className="tech-brand-wrapper mx-auto mt-10 w-full lg:mt-20">
							<div className="flex w-full flex-wrap items-start justify-evenly gap-10 xl:gap-20">
								{techbrands.map((stack, index) => (
									<img
										key={index}
										src={stack}
										alt={`icon-${index}`}
										className="h-[60px] w-[90px] object-contain lg:w-auto xl:h-[75px]"
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default TechServiceStack;
