import Logoicon from "assets/images/Logo-icon.png";
import ReadyBusinessPic from "assets/images/photo/ready-to-start-your-business.jpg";
import { IoLogoGithub, IoLogoInstagram } from "react-icons/io";

import { FaFacebookF, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { BsBuilding, BsTwitter } from "react-icons/bs";
import {
	AiOutlineAntDesign,
	AiOutlineMail,
	AiOutlineRobot,
} from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { MdOutlineScience } from "react-icons/md";

export const Logo = Logoicon;

export const navigations = [
	{
		name: "our services",
		href: "services",
		type: "dropdown",
	},
	{
		name: "about us",
		href: "about-us",
		type: "link",
	},
	{
		name: "portfolio",
		href: "portfolio",
		type: "link",
	},
	{
		name: "blog",
		href: "blog",
		type: "link",
	},
	{
		name: "careers",
		href: "careers",
		type: "link",
	},
];

export const socialIcon = [
	{
		icon: <FaFacebookF />,
		hrefURL: "https://www.facebook.com/eintegersolutions",
	},
	{
		icon: <BsTwitter />,
		hrefURL: "https://twitter.com/einteger_s",
	},
	{
		icon: <FaLinkedinIn />,
		hrefURL: "https://www.linkedin.com/company/eintegersolutions/",
	},
	{
		icon: <IoLogoInstagram />,
		hrefURL: "https://www.instagram.com/eintegersl/",
	},
	{
		icon: <IoLogoGithub />,
		hrefURL: "https://github.com/eintegersolutions/",
	},
];

export const connectIcon = [
	{
		icon: <FaPhoneAlt />,
		text: "+(94)-77 661 0303",
	},
	{
		icon: <AiOutlineMail />,
		text: "hello@einteger.com",
	},
];

export const dropdownMenu = {
	services: {
		categories: [
			{
				icon: <VscGitPullRequestCreate size={32} />,
				text: "Software Development",
				path: "/software-development",
				subtext: "Building great software backed by modern technology.",
			},
			{
				icon: <AiOutlineAntDesign size={32} />,
				text: "UI/UX Design",
				path: "/ui-design",
				subtext:
					"Designing great UX so that your digital products will be adored by users.",
			},
			{
				icon: <MdOutlineScience size={32} />,
				text: "Quality Assurance",
				path: "/quality-assurance",
				subtext:
					"Ensuring quality by identifying bugs, corner cases & performance bottlenecks.",
			},
			{
				icon: <AiOutlineRobot size={32} />,
				text: "Artificial Intelligence",
				path: "/artificial-intelligence",
				subtext:
					"Build AI products, intelligently automate your business workflows & leverage the power of data.",
			},
		],
		readyServices: {
			image: ReadyBusinessPic,
			title: "Ready to Get Started?",
			para: "Are you ready to begin your journey towards success? Our team is here to provide the tools and support you need to get started and reach your goals.",
			types: [
				{
					icon: <BsBuilding size={21} />,
					text: "Services for Enterprise",
					path: "/",
					subtext:
						"Let's build your next enterprise-grade application, together.",
				},
				{
					icon: <HiOutlineUserGroup size={21} />,
					text: "Services for Startups",
					path: "/",
					subtext: "Let's translate your great idea, into a great product.",
				},
			],
		},
	},
	careers: {},
};
