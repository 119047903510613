import React from "react";
import useExternalLink from "hook/useExternalLink";
import { connectIcon, socialIcon } from "assets/data/header.dto";

function NavTopbar() {
	const { openHref } = useExternalLink();
	return (
		<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
			<div className="flex w-full items-center justify-between">
				<div
					className="social-link-wrapper -ml-3 flex items-center
       				border-l-0 border-r py-1.5 lg:ml-0 lg:border-l"
				>
					{socialIcon.map((social, index) => (
						<button
							key={index}
							className="
							hover:
							transform
							border-r py-2
							px-3.5 text-[15px]
							text-arsenic-900
							transition-all
							duration-300 last:border-r-0
							hover:-translate-y-1 hover:text-halloween-orange-900"
							type="button"
							onClick={() => openHref(social.hrefURL)}
						>
							{social.icon}
						</button>
					))}
				</div>
				<div className="connect-serach-wrapper">
					<div
						className="social-link-wrapper -ml-3 flex items-center
           				border-l border-r-0 py-0 lg:ml-0 lg:border-r"
					>
						{connectIcon.map((connect, index) => (
							<button
								className="
								flex
								cursor-default
								items-center
								border-r
								py-2 px-4
							   	text-[15px]
								text-arsenic-900
								last:border-r-0
								hover:text-arsenic-900"
								type="button"
								key={index}
								onCopy={(e) => e.preventDefault()}
							>
								{connect.icon}
								<span
									className="ff-urban ml-2.5 hidden
               						text-xs font-semibold lg:inline-block"
								>
									{connect.text}
								</span>
							</button>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default NavTopbar;
