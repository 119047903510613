import React from "react";
import { Link } from "react-router-dom";

function PortfolioCard({ post }) {
	function renderWithSubstring(text = "") {
		let renderText = text;
		if (renderText.length > 92) {
			renderText = `${renderText.substring(0, 92)}...`;
		}

		return renderText;
	}

	function renderReplaceSpaceWithLowercase(name = "") {
		return name.replace(/\s+/g, "-").toLowerCase();
	}

	return (
		<Link
			to={`/portfolio/${renderReplaceSpaceWithLowercase(post?.title)}`}
			state={{ portfolio: post }}
			className="
			group h-auto
			w-full max-w-[325px] 
			rounded-md shadow-md hover:opacity-75"
		>
			<div className="relative h-56 w-full overflow-hidden">
				<img
					src={post?.fileUrl}
					alt={post?.title}
					className="h-full w-full rounded-t-md object-cover
					transition-all duration-300 group-hover:scale-105"
				/>
				<span
					className="
					absolute
					bottom-5
					left-0
					inline-block rounded-r-md bg-orange-100
					px-3 py-1 text-base font-bold
					capitalize tracking-wide text-orange-900
				  	shadow-md"
				>
					{post?.subject}
				</span>
			</div>
			<div className="content p-3">
				<h1 className="my-2 text-lg capitalize text-arsenic-900">
					{post?.title}
				</h1>
				<p className="ff-urban text-xs text-arsenic-600">
					{renderWithSubstring(post?.subtitle)}
				</p>
			</div>
		</Link>
	);
}

export default PortfolioCard;
