import React from "react";

// const imageURL =
// 	"https://designshack.net/wp-content/uploads/Marketing-Plan-2.0-Powerpoint-Template.jpg";

function ViewEndInfo({ meta }) {
	return (
		<div className="info-container my-6 flex h-auto w-full  flex-col  py-4 lg:my-12 lg:py-8 xl:my-16">
			<div className="order-2 mt-8 flex w-full justify-center lg:order-1">
				<div className="h-auto max-h-[60vh] w-full lg:w-[50vw] xl:w-[60vw]">
					<img
						src={meta?.fileUrl}
						alt="Accessibility Considerations"
						className="h-full w-full object-contain"
					/>
				</div>
			</div>

			<div className="text-content order-1 mt-4 px-0 text-arsenic-900 lg:order-2 lg:mt-12 lg:px-16 xl:mt-16 xl:px-20 2xl:px-24">
				<h4 className="ff-urban mb-4 flex text-xl font-semibold capitalize text-yankees-blue-900 lg:text-2xl xl:text-3xl">
					{meta?.title}
				</h4>

				<p className=" ff-urban w-full text-sm leading-6 tracking-wider lg:text-base lg:leading-7 ">
					{meta?.description}
				</p>
			</div>
		</div>
	);
}

export default ViewEndInfo;
