import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import SEO from "components/shared/seo";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { ARTICLES, ARTICLES_PROD } from "./article-context";

function SingleArticlePage() {
	const location = useLocation();
	const navigate = useNavigate();
	const { state } = location;
	const { article = {} } = state || {};

	const HOST = process.env.REACT_APP_HOST_PREFIX;
	const ENV = process.env.REACT_APP_NODE_ENV;

	useEffect(() => {
		if (!state || _.isNil(state?.article)) {
			navigate("/blog");
		}
	}, [location, navigate, state]);

	const fetchStaticArticles = (id = "") => {
		let sectionList = [];
		if (id !== "") {
			sectionList = ENV === "development" ? ARTICLES[id] : ARTICLES_PROD[id];
		}

		return sectionList;
	};

	const matchParagraph = useMemo(
		() => fetchStaticArticles(article?.sk),
		[article?.sk],
	);
	const shareUrl = state?.shareUrl || `${HOST}/blog`;

	return (
		<>
			<SEO
				link={shareUrl}
				postfixSlug="true"
				noIndex={false}
				title={article?.title}
				description="blog"
				keywords={article?.title}
			/>
			<section className="top-portfolio-section h-full min-h-[70vh] w-full bg-white">
				<div className="container mx-auto px-4 xl:px-0">
					<div className="article-wrapper mx-auto mt-16 w-full max-w-3xl">
						<div className="article-heading mb-6">
							<h1
								className="
                            block
                            max-w-[96%]
                            text-2xl font-semibold text-arsenic-900 xl:text-4xl
                            xl:leading-[3rem]"
							>
								{article?.title}
							</h1>
						</div>

						<div className="article-image relative h-[275px] md:h-[325px] xl:h-[425px]">
							<img
								src={article?.fileUrl}
								alt={article?.title}
								className="h-full w-full object-cover"
							/>
						</div>

						<div className="meta flex items-center gap-10 border-b py-5">
							<div className="postdate inline-flex items-center gap-2 text-base text-gray-400">
								<FaRegCalendarAlt size={16} />
								<span>{moment(article?.createdAt).format("ll")}</span>
							</div>
							<div className="shared-media flex items-center gap-3">
								<FacebookShareButton
									url={shareUrl}
									quote={article?.title}
									hashtag="#eintegersolution"
								>
									<FacebookIcon size={32} />
								</FacebookShareButton>
								<TwitterShareButton
									url={shareUrl}
									quote={article?.title}
									hashtag="#eintegersolution"
								>
									<TwitterIcon size={32} />
								</TwitterShareButton>
								<WhatsappShareButton
									url={shareUrl}
									quote={article?.title}
									hashtag="#eintegersolution"
								>
									<WhatsappIcon size={32} />
								</WhatsappShareButton>
								<LinkedinShareButton
									url={shareUrl}
									quote={article?.title}
									hashtag="#eintegersolution"
								>
									<LinkedinIcon size={32} />
								</LinkedinShareButton>
							</div>
						</div>

						<div className="posted-content my-5 max-w-[96%]">
							{matchParagraph?.length > 0 &&
								matchParagraph.map((data, idx) => (
									<section key={idx} className="ff-urban">
										{data?.title !== null ? (
											<h3
												className="
											mt-4 block text-lg
											font-semibold leading-7 
									  		text-arsenic-900 lg:text-xl"
											>
												{data.title}
											</h3>
										) : null}
										<p
											className="
										py-3
										text-sm
										leading-6
									  text-arsenic-600
										lg:text-base
										lg:leading-7"
										>
											{data?.para}
										</p>
									</section>
								))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default SingleArticlePage;
