import _ from "lodash";
import React from "react";
import { CgSpinner } from "react-icons/cg";
import { FaBoxOpen } from "react-icons/fa";
import PostedCard from "./PostedCard";

const renderComponent = {
	nodata: (
		<div className="flex items-start gap-2 text-gray-400">
			<FaBoxOpen size={24} />
			No blog article to show at the moment & coming soon...
		</div>
	),
	pending: (
		<div className="flex items-start gap-2 text-gray-400">
			<CgSpinner size={24} className="animate-spin" />
			Loading...
		</div>
	),
};

function RenderRequestOrPendingLayout({ isLoading, posts }) {
	let status = "nodata";
	let ComponentLayout;
	if (isLoading) {
		status = "pending";
	} else if (!isLoading && _.isEmpty(posts)) {
		status = "nodata";
	} else if (!isLoading && !_.isEmpty(posts)) {
		status = "fulfilled";
	}

	if (status === "fulfilled") {
		ComponentLayout = (
			<>
				{posts.map((post, index) =>
					post.published === 1 ? <PostedCard key={index} post={post} /> : null,
				)}
			</>
		);
	} else {
		ComponentLayout = renderComponent[status];
	}

	return (
		<div
			className="
			my-8
			flex
			h-full 
			w-full 
			flex-wrap
			justify-center 
			gap-12 lg:mt-16"
		>
			{ComponentLayout}
		</div>
	);
}

export default React.memo(RenderRequestOrPendingLayout);
