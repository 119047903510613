import React from "react";
import SEO from "components/shared/seo";
import { useNavigate } from "react-router-dom";
import { prodDevelopment } from "assets/data/home.dto";
import CaseStudiesExamples from "components/shared/case-studies/CaseStudiesExamples";
import HowItWorkLayout from "./components/HowItWorkLayout";
import DefaultContentColsLayout from "./components/DefaultContentColsLayout";
import SimpleBannerLayout from "./components/SimpleBannerLayout";

function ProductDevelopmentPage() {
	const { caseStudies, defaultSection, mainSection } = prodDevelopment;
	const navigate = useNavigate();

	function handleCallToAction(path) {
		navigate(path);
	}

	return (
		<>
			<SEO
				link="product-development"
				postfixSlug="true"
				noIndex={false}
				title="Product Development Services - Custom Software Solutions for Business Growth"
				description="Einteger Solutions is a leading software development company that specializes in providing custom product development services to businesses. Our experienced team combines your vision with our expertise to create innovative software solutions using the latest technologies and techniques. Our process includes gathering customer requirements, prioritizing features, breaking down tasks, and providing deployment and maintenance support. Browse our case studies to see examples of our successful projects"
				keywords="product development, software development, custom solutions, business growth, latest technologies, expert team, case studies, Einteger Solutions"
			/>
			<SimpleBannerLayout {...mainSection} ctaLinkEvent={handleCallToAction} />
			<DefaultContentColsLayout {...defaultSection} />
			<HowItWorkLayout workType="development" />
			{/* TODO move to component itself API call get all cases */}
			<CaseStudiesExamples {...caseStudies} />
		</>
	);
}

export default ProductDevelopmentPage;
