import React, { useEffect, useState } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";

function MessageOrProcessing({ processing, success, failed, message = "" }) {
	const [isShow, setIsShow] = useState(false);
	const themeStyle = {};

	useEffect(() => {
		if (success || failed) {
			setIsShow(true);
			setTimeout(() => setIsShow(false), 5000);
		}
		return () => setIsShow(false);
	}, [success, failed]);

	if (success) {
		themeStyle.icon = <AiOutlineFileDone size={30} />;
		themeStyle.color = "text-green-600";
		themeStyle.bgColor = "bg-green-50";
	} else if (failed) {
		themeStyle.icon = <RiErrorWarningLine size={30} />;
		themeStyle.color = "text-red-600";
		themeStyle.bgColor = "bg-red-50";
	} else {
		// themeStyle.icon = <GrInfo size={32} />;
		// themeStyle.color = "text-gray-600";
	}

	console.debug(processing);

	return (
		<div
			className="
			msg-wraper my-2 flex h-auto
			w-full items-center justify-center"
		>
			{isShow && (
				<div
					className={`flex w-full items-center px-4 py-2
         			${themeStyle?.color} ${themeStyle?.bgColor}`}
				>
					<span className="flex flex-shrink-0">{themeStyle?.icon}</span>
					<span className="ml-2 text-sm">{message}</span>
				</div>
			)}
		</div>
	);
}

export default React.memo(MessageOrProcessing);
