import { FaUsers, FaUserShield } from "react-icons/fa";
import { MdHomeRepairService } from "react-icons/md";
import spirit1 from "assets/images/photo/our-spirit1.jpg";
import spirit2 from "assets/images/photo/our-spirit2.jpg";
import vissionPic from "assets/images/photo/vission.jpg";
import missionPic from "assets/images/photo/mission.jpg";
import worldMapVector from "assets/images/photo/dotted_world_map_vector_trans.png";

export const banner = {
	quotes: "Get to know us!",
	title: "about us",
	para: "Einteger Solutions is a software development company that specializes in custom technology solutions for businesses. Our team of experienced developers offers services including software development, product development, artificial intelligence, and software QA. We are dedicated to helping businesses grow and succeed through innovative technology solutions.",

	stats: [
		{ icon: <MdHomeRepairService />, value: "10+", tag: "industries served" },
		{ icon: <FaUserShield />, value: "1M+", tag: "users reached" },
		{ icon: <FaUsers />, value: "8+", tag: "employees" },
	],
};

export const footprint = {
	tagname: "Customer Footprint",
	para: "Our solutions across industries help enterprises to adapt new realities of business and technology",
	worldmap: worldMapVector,
};

export const spirit = {
	tagname: "our spirit",
	quotes: "To do something extraordinary, you need extraordinary people.",
	para: "At Einteger Solutions, we believe that to do something extraordinary, you need extraordinary people. That's why we have assembled a team of experienced developers who are passionate about what they do and committed to delivering the best possible solutions for our clients. We are driven by a spirit of innovation and a desire to make a difference in the world through the use of technology.",
	image1: spirit1,
	image2: spirit2,
};

export const visionAndMission = {
	vision: {
		title: "Our Vision",
		para: "Our vision is to help businesses of all sizes achieve their goals through the use of innovative technology solutions. We are dedicated to delivering high-quality solutions that make a difference in the world.",
		image: vissionPic,
	},
	mission: {
		title: "Our Mission",
		para: "At Einteger Solutions, our mission is to be a leader in the software development industry, delivering innovative solutions that help businesses achieve their goals. We believe in the power of technology to drive positive change and are dedicated to delivering the best possible solutions to our clients.",
		image: missionPic,
	},
};
