import GCRImage from "assets/images/photo/how-it-work/gcr-image.jpg";
import PFBImage from "assets/images/photo/how-it-work/pfb-image.jpg";
import BTGImage from "assets/images/photo/how-it-work/btg-image.jpg";
import DMImage from "assets/images/photo/how-it-work/dm-image.jpg";

import SIDEAImage from "assets/images/photo/how-it-work/sidea-image.jpg";
import DPPImage from "assets/images/photo/how-it-work/dpp-image.jpg";
import PROTODEVImage from "assets/images/photo/how-it-work/protodev-image.jpg";
import TGImage from "assets/images/photo/how-it-work/tg-image.jpg";

export const prodDevStepsGuidlines = [
	{
		step: 1,
		title: "Gather customer requirements",
		para: "Our team is dedicated to gathering comprehensive customer requirements to ensure that our software solutions meet your unique needs and goals.",
		image: GCRImage,
	},
	{
		step: 2,
		title: "Prioritize a list of features to build",
		para: "Prioritizing your list of features to build is crucial to the success of your software solutions. Our team is here to help you every step of the way.",
		image: PFBImage,
	},
	{
		step: 3,
		title: "Break down tasks & groom the backlog",
		para: "We work with you to break down tasks and groom the backlog, resulting in efficient, effective software development.",
		image: BTGImage,
	},
	{
		step: 4,
		title: "Deployment and maintenance",
		para: "Expert deployment and maintenance services from our team ensure that your software solutions are always running at their best.",
		image: DMImage,
	},
];

export const vibleProdStepsGuidlines = [
	{
		step: 1,
		title: "Share your ideas with us",
		para: "We encourage you to share your ideas with us. Our team is here to help you turn your MVP vision into a reality.",
		image: SIDEAImage,
	},
	{
		step: 2,
		title: "Design brief and project planning",
		para: "We work with you to create a clear design brief and project plan, ensuring that your MVP development process is efficient and successful.",
		image: DPPImage,
	},
	{
		step: 3,
		title: "Prototyping and development",
		para: "Expert MVP prototyping and development from our team ensures that your solutions are tailored to your specific needs and designed for success.",
		image: PROTODEVImage,
	},
	{
		step: 4,
		title: "Test & Go",
		para: "Our team is dedicated to helping you test and go with your MVP, using proven techniques and technologies to ensure that your solutions are reliable and ready for the market.",
		image: TGImage,
	},
];
