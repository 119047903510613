import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

const Pagination = ({ currentPage, handlerNextOrPrevAction }) => {
	// const hasPrevIndex = () => pageNumber !== 0 || pageNumber > 0;
	// const hasNextIndex = () => {};

	console.debug(currentPage);

	return (
		<div className="h-auto w-auto p-1">
			<div className="flex items-center gap-x-2">
				<button
					title="previous page"
					type="button"
					className="rotate-180 transform rounded
          border bg-transparent px-2 py-2 text-dark-gunmetal/50 transition-all
          duration-300 ease-in-out hover:border-dark-cyan hover:text-dark-cyan active:scale-75"
					onClick={() => handlerNextOrPrevAction(-1)}
				>
					<MdArrowForwardIos />
				</button>
				<button
					title="next page"
					type="button"
					className="transform rounded border bg-transparent px-2
        py-2 text-dark-gunmetal/50 transition-all duration-300
          ease-in-out hover:border-dark-cyan hover:text-dark-cyan active:scale-75"
					onClick={() => handlerNextOrPrevAction(1)}
				>
					<MdArrowForwardIos />
				</button>
			</div>
		</div>
	);
};

export default React.memo(Pagination);
