import React, { useEffect, useState } from "react";
import SEO from "components/shared/seo";

import { getAllBlogs } from "services/all-admin-service";
import RenderRequestOrPendingLayout from "./RenderRequestOrPendingLayout";

function BlogPage() {
	const [blogposts, setBlogposts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function fetchAllData() {
			setIsLoading(true);
			const res = await getAllBlogs();
			if (res?.response) {
				const { response } = res;

				setBlogposts(response.data);
				setIsLoading(false);
			} else if (res?.err) {
				console.log("Error:", res.err);
				setIsLoading(false);
			}
		}
		fetchAllData();
	}, []);

	return (
		<>
			<SEO
				link="blog"
				postfixSlug="true"
				noIndex={false}
				title="Insights and Thought Leadership"
				description="Stay updated on the latest trends and best practices in software development with our blog. Read our thought leadership pieces and gain insights from our experts"
				keywords="blog, software development, trends, best practices, thought leadership"
			/>
			<section className="portfolio relative h-full min-h-[90vh] w-full md:min-h-[70vh]">
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="portforlio-main-wrapper h-full w-full lg:my-12">
						<div className="portforlio-heading mt-6  text-center lg:mt-0">
							<h1
								className="text-3xl font-medium
							tracking-wide text-arsenic-900 xl:text-5xl"
							>
								Blog & Post
							</h1>
						</div>
						<RenderRequestOrPendingLayout
							isLoading={isLoading}
							posts={blogposts}
						/>
					</div>
				</div>
			</section>
		</>
	);
}

export default BlogPage;
