import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
// import PopupWizardOverlay from "components/shared/popup-wizard";
// import { EnlargeViewOfPostModal } from "components/modals";
import { getLatestPublishedBlogs } from "services/all-client-service";
import PLACEHOLDER_URL from "assets/images/blog-comming-soon.png";

import "../Landing.styles.css";

function OurStory() {
	// const [isModal, setIsModal] = useState(false);
	// const [infoModal, setInfoModal] = useState({});
	const [blogs, setBlogs] = useState([]);

	async function fetchBlogsPosts() {
		const res = await getLatestPublishedBlogs();
		if (res.response?.status === 200) {
			const { response = {} } = res;
			setBlogs(response.data);
		}
	}

	// function handleExit() {
	// 	setIsModal(false);
	// }

	// function handleClick(info) {
	// 	if (info === undefined) return;

	// 	setIsModal(true);
	// 	setInfoModal(info);
	// }

	useEffect(() => {
		fetchBlogsPosts();
	}, []);

	function urlLink(stringLink) {
		if (stringLink === "") return "/blog";

		const urlText = stringLink?.replace(/\s+/g, "-").toLowerCase();
		return `/article/${urlText}`;
	}

	const onlyPublishedBlogs = useMemo(
		() => blogs.filter((b) => b.published),
		[blogs],
	);

	const latestBlogs = useMemo(() => {
		// const onlyPublishedBlogs = blogs.filter((b) => b.published);
		const length = onlyPublishedBlogs?.length || 0;

		if (length <= 3) return onlyPublishedBlogs;
		return onlyPublishedBlogs.slice(-3);
	}, [onlyPublishedBlogs]);

	if (onlyPublishedBlogs.length === 0) {
		return null;
	}

	return (
		<section className="mt-24 h-auto w-full">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="heading-title mx-auto max-w-2xl text-center">
					<h3 className="ff-sanspro mb-8 inline-block text-xl font-semibold text-arsenic-800">
						Our story
					</h3>
					<p className="ff-urban text-sm font-medium tracking-wide text-arsenic-600 lg:text-base">
						Learn about the journey of our software development company and our
						commitment to excellence.
					</p>
				</div>

				<div className="post-wrapper my-16 h-full w-full">
					<div className="flex h-full max-h-full w-full flex-wrap overflow-hidden lg:max-h-[65vh]">
						<div className="primary-post-block w-full md:w-1/2 lg:w-2/3">
							<Link
								to={urlLink(latestBlogs[0]?.publicLink)}
								state={{ article: latestBlogs[0] }}
								className="post-box group h-full w-full border-0 transition-all"
							>
								<img
									className="h-[220px] w-full object-cover object-center p-2 md:h-[424px] xl:h-[500px]"
									src={latestBlogs[0]?.fileUrl || PLACEHOLDER_URL}
									alt={latestBlogs[0]?.title || null}
								/>
								<span
									className="absolute top-6 left-6 z-[1] flex max-w-[90%]
									 items-start bg-black/75 p-5  opacity-0 transition-all
									 group-hover:opacity-100
									xl:items-center"
								>
									<h2 className="block text-lg font-normal capitalize tracking-wide text-orange-300 lg:text-xl">
										{latestBlogs[0]?.title || null}
									</h2>
								</span>
							</Link>
						</div>
						<div className="secondary-post-block w-full md:w-1/2 lg:w-1/3">
							<div className="flex flex-col">
								<Link
									to={urlLink(latestBlogs[1]?.publicLink)}
									state={{ article: latestBlogs[1] }}
									className="post-box group h-full w-full border-0 transition-all"
								>
									<img
										className="h-[212px] w-full rounded object-cover p-2 xl:h-[250px]"
										src={latestBlogs[1]?.fileUrl || PLACEHOLDER_URL}
										alt={latestBlogs[1]?.title || null}
									/>
									{latestBlogs[1]?.fileUrl && (
										<span
											className="absolute top-6 left-6 z-[1] flex max-w-[90%]
										items-start bg-black/75 p-5 opacity-0 transition-all
										group-hover:opacity-100 xl:items-center"
										>
											<h2 className="block text-lg font-normal capitalize tracking-wide text-orange-300 lg:text-lg">
												{latestBlogs[1]?.title || null}
											</h2>
										</span>
									)}
								</Link>

								<Link
									to={urlLink(latestBlogs[2]?.publicLink)}
									state={{ article: latestBlogs[2] }}
									className="post-box group h-full w-full border-0 transition-all"
								>
									<img
										className="h-[212px] w-full rounded object-cover p-2 xl:h-[250px]"
										src={latestBlogs[2]?.fileUrl || PLACEHOLDER_URL}
										alt={latestBlogs[2]?.title || null}
									/>
									{latestBlogs[2]?.fileUrl && (
										<span
											className="absolute top-6 left-6 z-[1] flex max-w-[90%]
										items-start bg-black/75 p-5 opacity-0 transition-all
										group-hover:opacity-100 xl:items-center"
										>
											<h2 className="block text-lg font-normal capitalize tracking-wide text-orange-300 lg:text-lg">
												{latestBlogs[2]?.title || null}
											</h2>
										</span>
									)}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Modal popup modal */}
			{/* <PopupWizardOverlay isOpen={isModal} handleClickOverlay={handleExit}>
				<EnlargeViewOfPostModal onExit={handleExit} info={infoModal} />
			</PopupWizardOverlay> */}
		</section>
	);
}

export default OurStory;
