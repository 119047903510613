import React from "react";
import NavTopbar from "./NavTopbar";
import Navbar from "./Navbar";

function Header() {
	return (
		<div className="header-wrapper z-[998] w-full">
			<div className="h-auto w-full border-b">
				<NavTopbar />
			</div>
			<div className="h-auto w-full">
				<Navbar />
			</div>
		</div>
	);
}

export default Header;
