import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import PLACEHOLDER_URL from "assets/images/placeholder.jpg";

function S3FileUpload({ setFile, previewFileUrl }) {
	const [localFile, setLocalFile] = useState(null);
	const [imageFile, setImageFile] = useState(null);

	const setFilePreviwer = (file) => {
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageFile(reader.result);
			};
			reader.readAsDataURL(file);
		} else {
			setImageFile(null);
		}
	};

	const handleFileChange = async (event) => {
		const { files = [] } = event.target;
		if (files.length > 0) {
			const file = files[0];

			setFilePreviwer(file);
			setLocalFile(file);
			setFile(file);
		}
	};

	const formatFileSize = (size) => {
		let szFormat = 0;
		const round = Math.round(size / 1024) || null;
		if (round >= 1000) {
			szFormat = ` ${(round / 1024).toFixed(2)} MB`;
		} else {
			szFormat = round ? ` ${round} KB` : null;
		}
		return szFormat;
	};

	const clearFile = () => {
		setLocalFile(null);
		setFile(null);
		setImageFile(null);
	};

	return (
		<div className="ff-urban flex flex-wrap rounded-md border-2 border-dashed">
			<div className="order-2 w-full max-w-[300px] p-3 md:w-1/2 lg:p-6">
				<div className="file-info space-y-3">
					<div className="flex items-start text-sm">
						<span className="inline-block min-w-[100px] font-bold capitalize">
							file accept
						</span>
						<span className="text-gray-500">image/ *jpeg,jpg *png</span>
					</div>
					<div className="flex items-start text-sm">
						<span className="inline-block min-w-[100px] font-bold capitalize">
							file type
						</span>
						<span className="text-gray-500">
							{localFile?.type.split("/")[1] || "unknown file extension"}
						</span>
					</div>
					<div className="flex items-start text-sm">
						<span
							className="inline-block min-w-[100px]
						 font-bold capitalize"
						>
							file size
						</span>
						<span className="text-gray-500">
							{formatFileSize(localFile?.size)}
						</span>
					</div>
				</div>
				<input
					type="file"
					id="uploadfile"
					name="uploadfile"
					onChange={handleFileChange}
					accept="image/*"
					className="my-4 text-sm outline-none"
				/>
			</div>

			<div className="order-1 w-full p-3 md:w-1/2">
				<div className="relative h-full w-full">
					{imageFile ? (
						<>
							<img
								src={imageFile}
								alt="preview"
								className="h-full max-h-[220px] w-full
								 rounded-md object-contain "
							/>
							<button
								type="button"
								className="absolute top-1/2 left-1/2 z-20
								translate-x-[-50%] translate-y-[-50%] scale-90 transform
								cursor-pointer rounded-full bg-black/60 p-1 text-white/60
								transition-all duration-300 hover:scale-100 hover:text-white"
								onClick={clearFile}
							>
								<MdOutlineClose size={21} />
								{/* unattached */}
							</button>
						</>
					) : (
						<img
							src={previewFileUrl || PLACEHOLDER_URL}
							alt="placeholder"
							className="h-full max-h-[200px]
							 w-full rounded-md object-cover"
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default React.memo(S3FileUpload);
