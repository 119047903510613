// import Logoicon from "assets/images/Logo-icon.png";
import Logoicon from "assets/images/footer-logo.png";
import { IoLogoGithub, IoLogoInstagram } from "react-icons/io";

import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";

export const Logo = Logoicon;

export const socialIcon = [
	{
		icon: <FaFacebookF />,
		hrefURL: "https://www.facebook.com/eintegersolutions",
	},
	{
		icon: <BsTwitter />,
		hrefURL: "https://twitter.com/einteger_s",
	},
	{
		icon: <FaLinkedinIn />,
		hrefURL: "https://www.linkedin.com/company/eintegersolutions/",
	},
	{
		icon: <IoLogoInstagram />,
		hrefURL: "https://www.instagram.com/eintegersl/",
	},
	{
		icon: <IoLogoGithub />,
		hrefURL: "https://github.com/eintegersolutions/",
	},
];

export const colsLinks = [
	{
		title: "services",
		childList: [
			{ text: "Artificial Intelligence", href: "/artificial-intelligence" },
			{ text: "Software Development", href: "/software-development" },
			{ text: "UI/UX Design", href: "/ui-design" },
			{ text: "Quality Assurance", href: "/quality-assurance" },
		],
	},
	{
		title: "company",
		childList: [
			{ text: "About Us", href: "/about-us" },
			{
				text: "Blog",
				href: "/blog",
			},
			{ text: "Contact Us", href: "/contact-us" },
			{ text: "Portfolio", href: "/portfolio" },
		],
	},
	{
		title: "more",
		childList: [
			{ text: "Careers", href: "/careers" },
			{ text: "FAQ", href: "/faq" },
			{
				external: true,
				text: "Packages",
				href: "https://einteger.com/packages/flyer.html#pplans",
			},
		],
	},
];

export const mainColumn = {
	text: "Have some questions that need answers?",
	cta: "Let's Talk",
};

export const copyright = "© 2022 Eintegersolutions. All Rights Reserved";
