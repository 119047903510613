import React, { useEffect, useState } from "react";
import { getAllEvents } from "services/all-admin-service";
import ScrollRow from "./ScrollRow";

function OurCulture() {
	const [gallery, setGallery] = useState([]);

	async function onLoadEventPhotos() {
		const res = await getAllEvents();
		if (res.response?.status === 200) {
			const { response } = res;
			setGallery(response.data);
		}
	}
	useEffect(() => {
		onLoadEventPhotos();
	}, []);

	if (gallery?.length === 0) {
		return null;
	}

	return (
		<section className="culture-photo-section mt-24 mb-8 h-full w-full bg-yankees-blue-900 lg:mb-32">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="heading-wrapper mb-4 pt-8">
					<h2 className="text-2xl leading-[2rem] text-white lg:text-3xl lg:leading-[4rem]">
						Our Culture
						<span className="ml-0 block text-5xl font-medium lg:text-5xl">
							Runs Deep!
						</span>
					</h2>
				</div>
			</div>

			<div className="relative py-8">
				<ScrollRow rowId="1" photos={gallery} />
			</div>
		</section>
	);
}

export default OurCulture;
