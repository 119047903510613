import React from "react";

function SingleMemoSlide({ caption, image }) {
	return (
		<div
			className="
			relative
			mx-4
			inline-flex
			h-[300px]
			w-[425px]
			"
		>
			<img
				className="
				block
				h-full
				w-full
				rounded-xl
				object-cover"
				src={image}
				alt={caption}
			/>
		</div>
	);
}

export default React.memo(SingleMemoSlide);
