import React, { Suspense } from "react";
import ErrorBoundary from "components/ErrorBoundary";
import LoadingOverlay from "components/shared/fallback-spin/LoadingOverlay";
import useGaTracker from "hook/useGaTracker";
import MaintenanceHandler from "pages/maintenance";

import RouterHandler from "router";

export default function App() {
	useGaTracker();
	const maintainState = false;

	return (
		<ErrorBoundary>
			{maintainState ? (
				<MaintenanceHandler />
			) : (
				<Suspense fallback={<LoadingOverlay />}>
					<RouterHandler />
				</Suspense>
			)}
		</ErrorBoundary>
	);
}
