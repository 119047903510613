import React from "react";
import { BsShieldExclamation } from "react-icons/bs";
/**
 *  ctaLinkUrl,
    ctaLinkText,
    customCtaMessage,
 */
function GenericError(props) {
	const { errorMessage, renderIcon = () => <BsShieldExclamation size={52} /> } =
		props;
	return (
		<div className="flex h-screen w-full items-center justify-center bg-gradient-to-t from-gray-100 via-halloween-orange-100 to-orange-50">
			<div className="flex flex-col items-center justify-center p-2">
				<div>
					<div className="text-halloween-orange-900">{renderIcon()}</div>
				</div>
				<div className="w-full text-center text-gray-600 lg:w-[75%]">
					<strong className="my-2 block text-xl md:text-2xl">
						{errorMessage}
					</strong>
					<div className="text-base">{props.children}</div>
				</div>
			</div>
		</div>
	);
}

export default GenericError;
