import _ from "lodash";
import SEO from "components/shared/seo";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CareerApplyForm from "./components/CareerApplyForm";

function CareersApplyPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const [vacant, setVacant] = useState({});
	const { state } = location;

	useEffect(() => {
		function viewstateParamsOrHandleRouter() {
			if (!state || _.isNil(state?.career)) {
				navigate("/careers");
			} else {
				setVacant(state?.career);
			}
		}
		viewstateParamsOrHandleRouter();
	}, [location, navigate, state]);

	useEffect(() => {
		return () => setVacant({});
	}, []);

	const replaceSpecialChars = (text) =>
		text && text?.replace(/[^a-zA-Z0-9 ]/g, "");

	return (
		<>
			<SEO
				link={`careers/${replaceSpecialChars(vacant?.title)}`}
				postfixSlug="true"
				noIndex={false}
				title={`Careers - ${replaceSpecialChars(vacant?.title)}`}
				description={String(vacant?.expects)}
				keywords={String(vacant?.responsibilities)}
			/>
			<section className="career-apply mb-24 h-full w-full">
				<div className="container mx-auto px-4 xl:px-0">
					<div
						className="career-info-wrapper border-0 border-dashed border-red-400
					px-0 xl:px-12 2xl:px-20"
					>
						<div className="career-title py-8">
							<h1
								className="ff-urban text-xl font-semibold capitalize
				 			 text-arsenic-900 xl:text-3xl"
							>
								{vacant?.title}
							</h1>
						</div>

						<div className="career-content mt-0 mb-8 flex w-full flex-col lg:flex-row xl:mt-8">
							{/* career:what we expect? */}
							<div className="w-full bg-orange-50 p-6 lg:w-1/2">
								<div className="content-box">
									<h3
										className="block py-4 text-base font-semibold
									 uppercase text-arsenic-900 xl:text-lg"
									>
										what we expect
									</h3>
									<ul className="mb-8 w-full space-y-4">
										{vacant.expects?.map((expect, i) => (
											<li
												key={i}
												className="ff-urban flex h-auto w-full
												items-start"
											>
												<span
													className="mt-1 mr-4 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center
												 rounded-full border-4 border-halloween-orange-400 bg-halloween-orange-900"
												/>
												<span className="font-light-0 tracking-wide">
													{expect}
												</span>
											</li>
										))}
									</ul>
								</div>

								<div className="content-box">
									<h3
										className="block py-4 text-base font-semibold
									 uppercase text-arsenic-900 xl:text-lg"
									>
										responsibilities
									</h3>
									<ul className="mb-8 w-full space-y-4">
										{vacant.responsibilities?.map((responsibility, i) => (
											<li
												key={i}
												className="ff-urban flex h-auto w-full
										items-start"
											>
												<span
													className="mt-1 mr-4 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center
												rounded-full border-4 border-halloween-orange-400 bg-halloween-orange-900"
												/>
												<span className="font-light-0 tracking-wide">
													{responsibility}
												</span>
											</li>
										))}
									</ul>
								</div>

								<div className="ff-urban mt-12 space-y-4 text-arsenic-900">
									{/* 1st box */}
									<div className="flex  w-full items-center font-bold">
										<span className="font-sm min-w-[30%]">Job Type</span>
										<span
											className="rounded-md bg-halloween-orange-200 px-4 py-2
										text-sm capitalize tracking-wider text-orange-900"
										>
											{vacant?.worktype}
										</span>
									</div>
									{/* 2nd box */}
									<div className="flex w-full items-center  font-bold">
										<span className="font-sm min-w-[30%]">Job Location</span>
										<span
											className="rounded-md bg-halloween-orange-200 px-4 py-2
									   text-sm capitalize tracking-wider text-orange-900"
										>
											{vacant?.location}
										</span>
									</div>
								</div>
							</div>

							{/* Form */}
							<div
								className="w-full bg-white px-0 py-12
							lg:w-1/2 lg:px-6 lg:py-3"
							>
								<CareerApplyForm vacant={vacant} />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default CareersApplyPage;
