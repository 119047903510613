function useExternalLink() {
	function openHref(url) {
		try {
			if (url !== null || url !== "") {
				window.open(url, "_blank");
			}
		} catch (error) {
			console.debug("Unable navigate to link: ", url);
		}
	}

	return { openHref };
}

export default useExternalLink;
