import React, { Suspense, useEffect, useState } from "react";
import { GrMenu, GrClose } from "react-icons/gr";
import { Logo, navigations } from "assets/data/header.dto";
import { Link, NavLink } from "react-router-dom";
// import { RiUserVoiceFill } from "react-icons/ri";
import { VscChevronDown } from "react-icons/vsc";
import MenuSmallScreen from "./MenuSmallScreen";
import DropdownLayoutTemplate from "./dropdown/DropdownLayoutTemplate";

const MegaDropdownComponent = React.lazy(() =>
	import("./dropdown/MegaDropdown"),
);

function Navbar() {
	const [mobNavExpand, setMobNavExpand] = useState(false);
	const [dropdownExpand, setDropdownExpand] = useState({
		status: false,
		component: null,
	});
	const [navStyles, setNavStyles] = useState({
		position: "static",
		paddingY: "0rem",
		shadow: "none",
		scrolling: false,
	});

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY >= 90) {
				setNavStyles({
					position: "fixed",
					paddingY: "0rem",
					shadow: "0 2px 6px -1px rgba(0,0,0,.2)",
					scrolling: true,
				});
			} else {
				setNavStyles({
					position: "static",
					paddingY: "0rem",
					shadow: "none",
					scrolling: false,
				});
			}
		};
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	function handleClickLink() {
		setDropdownExpand({
			status: false,
			component: null,
		});
	}

	function handleMouseOnLink(href) {
		console.debug(href);

		let activeComponent;
		let visibility;

		if (dropdownExpand.status) {
			activeComponent = null;
			visibility = false;
		}
		if (!dropdownExpand.status && href === "services") {
			activeComponent = <MegaDropdownComponent handleClick={handleClickLink} />;
			visibility = true;
		}
		setDropdownExpand((prevState) => ({
			...prevState,
			status: visibility,
			component: activeComponent,
		}));
	}

	return (
		<header
			style={{
				position: `${navStyles.position}`,
				padding: `${navStyles.paddingY} 0`,
				boxShadow: `${navStyles.shadow}`,
			}}
			className="relative top-0 left-0 z-[998] w-full
			bg-white transition-all duration-300 ease-in-out"
		>
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="relative flex w-full items-center justify-between py-4">
					<Link to="/" className="w-[150px] lg:w-[160px]">
						<img
							src={Logo}
							alt="company-logo"
							className="h-full w-full object-contain"
						/>
					</Link>
					{/* LOGO <div/> end */}
					<div className="navbar-wrapper flex items-center">
						<nav className="mx-4 hidden items-center lg:flex">
							{navigations.map((nav, index) => {
								if (nav.type === "dropdown") {
									return (
										<button
											type="button"
											key={index}
											id="services-dropdown-button"
											onClick={() => handleMouseOnLink(nav.href)}
											className={`
											ff-urban-0 inline-flex items-center gap-x-0.5
											px-4 py-4 text-base font-normal capitalize
											tracking-wide text-arsenic-800
										  	hover:text-halloween-orange-800 ${
													dropdownExpand.status && "text-halloween-orange-800"
												}`}
										>
											{nav.name}

											<VscChevronDown
												id="services-dropdown-button"
												size={16}
												className={`${
													dropdownExpand.status ? "rotate-180" : "rotate-0"
												}`}
											/>
										</button>
									);
								}
								return (
									<NavLink
										style={({ isActive }) =>
											isActive ? { color: "#F4622C" } : { color: "" }
										}
										key={index}
										to={nav.href}
										className="
										ff-urban-x
										inline-flex
										items-center
										gap-x-0.5 px-4
										py-4
										text-base font-normal capitalize
										tracking-wide text-arsenic-800
										hover:text-halloween-orange-800"
									>
										{nav.name}
									</NavLink>
								);
							})}
						</nav>
						<div className="hidden lg:inline-block">
							<Link
								to="contact-us"
								className="
								btn btn-outline
								inline-flex
								items-center 
								font-normal 
								uppercase
								tracking-wider"
								type="button"
							>
								Talk to Us
							</Link>
						</div>

						<div className="mobile-toggle inline-flex lg:hidden">
							<button
								type="button"
								onClick={() => setMobNavExpand(!mobNavExpand)}
							>
								{mobNavExpand ? <GrClose size={24} /> : <GrMenu size={24} />}
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Mobile navbar */}

			<MenuSmallScreen
				isOpen={mobNavExpand}
				scrolling={navStyles.scrolling}
				navigations={navigations}
				onHide={() => setMobNavExpand(false)}
			/>

			<DropdownLayoutTemplate
				handleClickOutEvent={handleClickLink}
				isOpen={dropdownExpand.status}
				scrolling={navStyles.scrolling}
			>
				<Suspense fallback={<div>Loading...</div>}>
					{dropdownExpand.component}
				</Suspense>
			</DropdownLayoutTemplate>
		</header>
	);
}

export default Navbar;
