import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { contactForm } from "assets/data/contact.dto";
import { CgSpinner } from "react-icons/cg";
import { sendContactusForm } from "services/all-client-service";
import MessageOrProcessing from "components/form/message-or-processing";

function ContactForm() {
	const { fields, information, subscribeText } = contactForm;
	const [checkNewsletter, setCheckNewsletter] = useState(true);
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	async function onSubmit(form) {
		if (isValid && _.isEmpty(errors) && !_.isEmpty(form)) {
			updateFormState(true, false, false);
			const resp = await sendContactusForm({ ...form, checkNewsletter });
			if (resp) {
				updateFormState(false, true, false, "success");
				reset();
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	const onError = (errors) => {
		console.log(errors);
	};

	return (
		<div
			className="form-wrapper mx-auto w-full max-w-full
		 bg-white p-8 shadow-lg md:max-w-[90%] lg:max-w-2xl"
		>
			<div className="form-welcome-quotes mb-8">
				<h1
					className="pb-2 text-4xl
       		font-semibold text-arsenic-900"
				>
					<span className="text-halloween-orange-900">Let&lsquo;s</span> have a
					<span className="text-halloween-orange-900"> talk</span>
				</h1>
				<p
					className="
					ff-urban text-base font-normal
        			tracking-wide text-arsenic-600"
				>
					Have any question? We&lsquo;d love to hear from you.
				</p>
			</div>

			{/* FORM ALERTS! */}
			<MessageOrProcessing {...formState} />

			<div className="form-content-wrapper  w-full">
				{/* submit form ui-component */}
				<form
					className="contact-form ff-urban w-full"
					onSubmit={handleSubmit(onSubmit, onError)}
				>
					<div className="flex flex-wrap gap-x-7 gap-y-2 lg:gap-y-4 2xl:gap-x-8">
						{fields.map((field) => (
							<div
								key={field.key}
								className={`input-group ${
									field.type === "textarea" ? " w-full " : "w-full  md:w-[47%] "
								}`}
							>
								<label
									className="m-0.5 block text-sm font-semibold
								  tracking-wider text-yankees-blue-600"
									htmlFor={field.key}
								>
									{field.label}
								</label>

								{field.type === "textarea" ? (
									<textarea
										name={field.key}
										id={field.key}
										className="input-feild-border-0 h-[160px]"
										{...register(`${field.key}`, {
											required: `${field.error}`,
										})}
										onKeyUp={() => trigger(`${field.key}`)}
									/>
								) : (
									<input
										id={field.key}
										type={field.type}
										name={field.key}
										autoComplete="off"
										className="
                  	input-feild-border-0"
										{...register(`${field.key}`, {
											required: field.error,
											maxLength: field.maxLength,
											pattern: field.pattern,
										})}
										onKeyUp={() => trigger(`${field.key}`)}
									/>
								)}

								<span
									className="block p-1 text-[13px]
							 tracking-[0.06rem] text-red-900"
								>
									{errors[field?.key]?.message}
								</span>
							</div>
						))}
					</div>

					<div className="checkbox-group flex items-center gap-2 py-4">
						<input
							type="checkbox"
							name="check-subcribe"
							id="check-subcribe"
							className="
							flex-shrink-0 text-halloween-orange-900
               				focus:ring-halloween-orange-300"
							checked={checkNewsletter}
							onChange={(e) => setCheckNewsletter(e.target.checked)}
						/>

						<span
							className="inline-block max-w-[90%] text-sm leading-6
            				 tracking-wide text-arsenic-700 xl:text-sm xl:leading-6"
						>
							{subscribeText}
						</span>
					</div>

					{/* submit button */}
					{formState.processing ? (
						<div
							className="mt-4 flex  items-center justify-center
						 text-sm tracking-wider text-halloween-orange-900"
						>
							<CgSpinner
								size={30}
								className="
								mr-2
								animate-spin"
							/>
							<span>Sending...</span>
						</div>
					) : (
						<button
							type="submit"
							className="ff-urban mt-4 w-full cursor-pointer
							rounded bg-halloween-orange-900 p-3 px-4 text-base
              				font-medium tracking-wider text-white
							disabled:cursor-not-allowed disabled:bg-arsenic-300"
						>
							Send Message
						</button>
					)}
				</form>
				{/* -================================================== */}
				<div className="contact-meta mt-8 w-full border-t py-4">
					<h4 className="ff-urban my-4 text-lg font-bold capitalize">
						{information.title}
					</h4>

					<ul className="space-y-8">
						{information.meta.map((meta, index) => (
							<li key={index} className="flex items-center text-arsenic-900">
								<div
									className="icon mr-4 inline-flex h-10 w-10
									flex-shrink-0 items-center justify-center
									rounded-lg  bg-yankees-blue-100 text-lg "
								>
									{meta.icon}
								</div>
								<div
									className="cursor-default text-sm  tracking-wide"
									onCopy={(e) => e.preventDefault()}
								>
									{meta.text}
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default ContactForm;
