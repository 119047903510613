import React from "react";

import { useNavigate } from "react-router-dom";
import { mainHero } from "assets/data/hero.dto";
import { HiOutlineArrowRight, HiOutlineArrowLeft } from "react-icons/hi";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay } from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "../Hero.styles.css";

function HeroSwiper() {
	const prevRef = React.useRef();
	const nextRef = React.useRef();
	const swiperRef = React.useRef();
	const navigation = useNavigate();

	const onSwiperInit = React.useCallback((swiper) => {
		setTimeout(() => {
			swiper.update();
		}, 1000);
	}, []);

	return (
		<section className="hero-swiper-section w-ful h-full min-h-[90vh]">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="hero-flex-wrapper group relative h-full w-full border-0 py-7 lg:py-14">
					<Swiper
						className="main-hero-swiper"
						modules={[Navigation, EffectFade, Autoplay]}
						navigation={{
							prevEl: prevRef?.current,
							nextEl: nextRef?.current,
						}}
						loop
						effect="fade"
						speed={1000}
						slidesPerView={1}
						onInit={onSwiperInit}
						autoplay={{
							delay: 6000,
							disableOnInteraction: false,
						}}
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper;
						}}
					>
						{mainHero.map((slide) => (
							<SwiperSlide key={slide.id}>
								<div
									className="
									hero-slider-container z-20
									flex flex-wrap items-start
								 	bg-white pr-2 pb-4 pt-0 lg:pb-6 lg:pt-8"
								>
									<div className="hero-text order-2 w-full overflow-hidden lg:order-1 lg:w-1/2 ">
										<div className="content mt-12 w-full py-6 lg:mt-0 lg:max-w-[90%] xl:py-12">
											<h1
												className="
												max-w-full
												text-3xl
												font-medium
												tracking-tight
												text-arsenic-900 lg:left-[3rem]
												lg:text-4xl
												2xl:text-5xl 2xl:leading-[4rem]"
											>
												{slide.title}
											</h1>
											<p
												className="ff-urban block pb-6 pt-4 text-sm
												font-medium leading-[1.6rem] tracking-wide
												text-arsenic-700 lg:my-4 lg:pt-0 xl:leading-[1.7rem]
												2xl:text-lg 2xl:leading-[1.7rem]"
											>
												{slide.para}
											</p>

											<button
												type="button"
												onClick={() => navigation("/contact-us")}
												className="ff-urbanx btn btn-outline flex items-center
								        		gap-4 hover:animate-pulse"
											>
												{slide.cta}
												<HiOutlineArrowRight size={24} />
											</button>
										</div>
									</div>
									{/* <div className="hero-image order-1 w-full lg:order-2 lg:w-1/2">
										<div
											className="
											images min-h-auto ml-0 max-w-[90%] md:ml-10
										 	lg:ml-16 lg:min-h-[40vh] xl:min-h-[50vh]"
										>
											<img
												className="
												img-snap
												h-full
												w-full
												object-cover
												object-right-bottom
												lg:w-[100%]"
												src={slide.image}
												alt={slide.title}
											/>
										</div>
									</div> */}
									<div className="order-1 w-full lg:order-2 lg:w-1/2">
										<div className="hero-image-wrapper md:px-5 ">
											<img
												src={slide.image}
												alt={slide.title}
												className="
												splash-img-svg
												h-full 
												w-full
												object-cover"
											/>
										</div>
									</div>
								</div>
							</SwiperSlide>
						))}
						{/* Customer Arrow Button */}
					</Swiper>
					<div className="swiper-nav-group flex items-center gap-2 opacity-0 group-hover:opacity-100">
						<button
							className="swiper-nav-prev  hover:scale-95 focus:scale-90"
							type="button"
							onClick={() => swiperRef.current?.slidePrev()}
						>
							<HiOutlineArrowLeft size={16} />
						</button>
						<button
							className="swiper-nav-next hover:scale-95 focus:scale-90"
							type="button"
							onClick={() => swiperRef.current?.slideNext()}
						>
							<HiOutlineArrowRight size={16} />
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HeroSwiper;
