import {
	MdOutlineDomainVerification,
	MdOutlineModelTraining,
	MdOutlineRunningWithErrors,
	MdOutlineThumbUp,
} from "react-icons/md";
import { BsCode, BsBraces, BsJournalCheck } from "react-icons/bs";
import {
	TbCheckupList,
	TbPresentationAnalytics,
	TbReport,
	TbWorld,
} from "react-icons/tb";
import { VscChecklist, VscRocket } from "react-icons/vsc";
import { HiOutlineLightBulb } from "react-icons/hi";
import { RiPencilRuler2Line } from "react-icons/ri";
import { CgListTree, CgPerformance } from "react-icons/cg";
import { IoMdAnalytics } from "react-icons/io";
import mobiledevpic from "assets/images/photo/svr-mobile-dev-pic.jpg";
import webdevpic from "assets/images/photo/svr-web-dev-pic.png";
import solidfoundpic from "assets/images/photo/svr-solid-found-pic.jpg";
import customerexperpic from "assets/images/photo/svr-customer-exper-pic.jpg";

import helpAIbusinespic from "assets/images/photo/svr-help-ai-business-pic.jpg";
import powerofAIpic from "assets/images/photo/svr-power-of-ai-pic.jpg";

import avoidbugsinqapic from "assets/images/photo/svr-avoid-bug-qa-pic.jpg";
import enhancequalitypic from "assets/images/photo/svr-enhance-quality-pic.jpg";

import { AiOutlineDropbox } from "react-icons/ai";
import showcase1 from "assets/images/photo/showcase1.jpg";
import techstackbrand from "assets/images/photo/tech-stack-brand.png";
// all brand list
import stack1 from "assets/images/techstack/stack1.png";
import stack2 from "assets/images/techstack/stack2.png";
import stack3 from "assets/images/techstack/stack3.png";
import stack4 from "assets/images/techstack/stack4.png";
import stack5 from "assets/images/techstack/stack5.png";
import stack6 from "assets/images/techstack/stack6.png";
import stack7 from "assets/images/techstack/stack7.png";
import stack8 from "assets/images/techstack/stack8.png";
import stack9 from "assets/images/techstack/stack9.png";
import stack10 from "assets/images/techstack/stack10.png";
import stack11 from "assets/images/techstack/stack11.png";
import stack12 from "assets/images/techstack/stack12.png";
import stack13 from "assets/images/techstack/stack13.png";
import stack14 from "assets/images/techstack/stack14.png";
import stack15 from "assets/images/techstack/stack15.png";
// ui-design tools
import figmaTool from "assets/images/techstack/figma-tool.png";
import psTool from "assets/images/techstack/ps-tool.png";
import aiTool from "assets/images/techstack/ai-tool.png";
import xdTool from "assets/images/techstack/xd-tool.png";
import sketchTool from "assets/images/techstack/sketch-tool.png";
// ai-tech tools

import aitech1 from "assets/images/techstack/ai-tech1.png";
import aitech2 from "assets/images/techstack/ai-tech2.png";
import aitech3 from "assets/images/techstack/ai-tech3.png";
import aitech4 from "assets/images/techstack/ai-tech4.png";
import aitech5 from "assets/images/techstack/ai-tech5.png";
import aitech6 from "assets/images/techstack/ai-tech6.png";
import aitech7 from "assets/images/techstack/ai-tech7.png";

// QA Tools

import qatool1 from "assets/images/techstack/qa-tool1.png";
import qatool2 from "assets/images/techstack/qa-tool2.png";
import qatool3 from "assets/images/techstack/qa-tool3.png";
import qatool4 from "assets/images/techstack/qa-tool4.png";
import qatool5 from "assets/images/techstack/qa-tool5.png";

export const softwareDevelopment = {
	caseStudies: {
		tagname: "Project insights",
		para: "Expert software development solutions brought to life through our case studies",
		showcases: [
			{
				title: "Smart Home Solutions",
				categroy: "Smart Rent ",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Spy IoT",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Health care",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
		],
		cta: { path: "/portfolio", text: "view more" },
	},
	techStack: {
		tagname: "tech stack",
		title: "We've got you coverd",
		para: "Combination of technologies a company uses to build and run an application or project. Sometimes called a “solutions stack,”",
		image: techstackbrand,
		techbrands: [
			stack1,
			stack2,
			stack3,
			stack4,
			stack5,
			stack6,
			stack7,
			stack8,
			stack9,
			stack10,
			stack11,
			stack12,
			stack13,
			stack14,
			stack15,
		],
	},

	mobileDev: {
		tagname: "mobile development",
		title: "Building mobile apps that are ready to serve millons of users",
		keyPoints: [
			{
				icon: <MdOutlineThumbUp size={24} />,
				text: "When taking your mobile app to market, speed is the key. That’s why we keep you apprised of every step along the way, to ensure your efforts grow into digital products that augment business vision.",
				color: "orange",
			},
			{
				icon: <BsCode size={24} />,
				text: "Together, let’s work on building a clean, simple, and attractive mobile application that matches what you have in mind",
				color: "green",
			},
		],
		image: mobiledevpic,
	},

	webDev: {
		tagname: "web development",
		title: "Building Bespoke Web Solutions to Boost Your Business.",
		keyPoints: [
			{
				icon: <TbWorld size={24} />,
				text: "We have a wealth of experience building versatile web applications for customers ranging from billion-dollar organizations to startups over the past 5 years.",
				color: "orange",
			},
			{
				icon: <BsBraces size={24} />,
				text: "Our development team can build you secure, robust, and scalable solutions, helping your business maintain 24/7 accessibility and efficiency.",
				color: "green",
			},
		],
		image: webdevpic,
	},

	devProcess: {
		tagname: "our development process",
		title: "Let’s see how it works",
		steps: [
			{
				num: 1,
				icon: <BsJournalCheck />,
				title: "Gather customer requirements",
				para: "We work closely with you to gather customer requirements and ensure that your custom software meets your needs and goals.",
			},
			{
				num: 2,
				icon: <CgListTree />,
				title: "Prioritize a list of features to build",
				para: "Effective software development involves prioritizing a list of features to build that meets your unique needs and goals. Our team is here to help.",
			},
			{
				num: 3,
				icon: <TbCheckupList />,
				title: "Break down tasks & groom the backlog",
				para: "Effective software development involves breaking down tasks and grooming the backlog to ensure that your custom software is efficient and effective.",
			},
			{
				num: 4,
				icon: <VscRocket />,
				title: "Deployment and maintenance",
				para: "Our team is dedicated to helping you successfully deploy and maintain your custom software. We use proven techniques to ensure that your solutions are reliable and effective.",
			},
		],
	},
};

export const uiDesing = {
	caseStudies: {
		tagname: "Project insights",
		para: "Expert software development solutions brought to life through our case studies",
		showcases: [
			{
				title: "Smart Home Solutions",
				categroy: "Smart Rent ",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Spy IoT",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Health care",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
		],
		cta: { path: "/portfolio", text: "view more" },
	},
	techStack: {
		tagname: "Design Tools",
		title: "We've got you covered",
		para: "Right from creating mobile app UI to scalable MVPs. We have the experience to take you to the next level",
		image: null,
		techbrands: [figmaTool, psTool, aiTool, xdTool, sketchTool],
	},

	customerExperience: {
		tagname: "Smooth Customer Experiences",
		title: "User-Friendly, Visually Appealing Apps",
		keyPoints: [
			{
				icon: <MdOutlineThumbUp size={24} />,
				text: "We tailor our design aesthetic specifically to your business style, ensuring that every detail is aligned with your brand and values",
				color: "orange",
			},
			{
				icon: <BsCode size={24} />,
				text: "Let's collaborate to develop a visually appealing and user-friendly mobile application that meets your specific vision and goals.",
				color: "green",
			},
		],
		image: customerexperpic,
	},

	solidFoundation: {
		tagname: "Strong Foundation for Your App",
		title: "Creating a Solid Foundation For Your Application, Right Away.",
		keyPoints: [
			{
				icon: <TbWorld size={24} />,
				text: "We believe that excellent UX should not only highlight and promote your product but should also be the key to building and growing customer confidence.",
				color: "orange",
			},
			{
				icon: <BsBraces size={24} />,
				text: "We believe that excellent UX should not only highlight and promote your product but should also be the key to building and growing customer confidence.",
				color: "green",
			},
		],
		image: solidfoundpic,
	},

	designProcess: {
		tagname: "our desing process",
		title: "Let’s see how it works",
		steps: [
			{
				num: 1,
				icon: <BsJournalCheck />,
				title: "Research",
				para: "Our team is dedicated to conducting comprehensive research to ensure that your UI/UX design meets the needs and goals of your users",
			},
			{
				num: 2,
				icon: <HiOutlineLightBulb />,
				title: "Empathize",
				para: "Effective UI/UX design involves empathizing with your users and designing interfaces that meet their needs and goals. Our team is here to help",
			},
			{
				num: 3,
				icon: <RiPencilRuler2Line />,
				title: "Create",
				para: "Creating interfaces that meet the needs and goals of your users is crucial to the success of your UI/UX design. Our team is here to help",
			},
			{
				num: 4,
				icon: <VscRocket />,
				title: "Test and develop",
				para: "Testing and developing interfaces that meet the needs and goals of your users is crucial to the success of your UI/UX design. Our team is here to help",
			},
		],
	},
};

export const aiDevelopment = {
	caseStudies: {
		tagname: "Project insights",
		para: "Expert software development solutions brought to life through our case studies",
		showcases: [
			{
				title: "Smart Home Solutions",
				categroy: "Smart Rent ",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Spy IoT",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Health care",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage.",
				image: showcase1,
			},
		],
		cta: { path: "/portfolio", text: "view more" },
	},
	techStack: {
		tagname: "Tech stack",
		title: "We've got you covered",
		para: "We build intelligent web and mobile applications powered by AI",
		image: null,
		techbrands: [aitech1, aitech2, aitech3, aitech4, aitech5, aitech6, aitech7],
	},

	helpAIToBusiness: {
		tagname: "Transforming Businesses with AI",
		title: "Leveraging AI to Transform Businesses for the Better",
		keyPoints: [
			{
				icon: <MdOutlineThumbUp size={24} />,
				text: "AI is changing lives, changing businesses. taking swift measures to leverage AI in pockets across the organization can unlock new opportunities, and we can help you get there, effortlessly.",
				color: "orange",
			},
			{
				icon: <BsCode size={24} />,
				text: "Want to know what we can do for you? Call us or drop us a text, so we can talk about how we can help you invest in the right scalable solutions & services.",
				color: "green",
			},
		],
		image: helpAIbusinespic,
	},

	powerofAI: {
		tagname: "AI and Business Strategy",
		title: "Maintaining a Competitive Edge",
		keyPoints: [
			{
				icon: <TbWorld size={24} />,
				text: "With our specialized team of Data Scientists, AI Engineers, and Software Architects, At Einteger AI, we offer AI Strategy Consulting, AI Product Engineering, and Research.",
				color: "orange",
			},
			{
				icon: <BsBraces size={24} />,
				text: "We strive to align our enterprise AI strategy with your business vision, by taking the time to understand your goals and needs, and tailoring our approach accordingly.",
				color: "green",
			},
		],
		image: powerofAIpic,
	},

	aiProcess: {
		tagname: "our ai development process",
		title: "Let’s see how it works",
		steps: [
			{
				num: 1,
				icon: <IoMdAnalytics />,
				title: "Problem analysis",
				para: "Effective artificial intelligence involves providing problem analysis to ensure that solutions are tailored to your business's specific needs. Our team is here to help",
			},
			{
				num: 2,
				icon: <TbPresentationAnalytics />,
				title: "Project scope & data analysis",
				para: "Providing project scope and data analysis is crucial to the success of your artificial intelligence projects. Our team is here to help ensure that solutions are tailored to your business's specific needs",
			},
			{
				num: 3,
				icon: <MdOutlineModelTraining />,
				title: "Model training & development",
				para: "Providing model training and development is crucial to the success of your artificial intelligence projects. Our team is here to help ensure that your AI models are accurate and effective",
			},
			{
				num: 4,
				icon: <VscRocket />,
				title: "Continuous maintenance",
				para: "Providing continuous maintenance is crucial to the success of your artificial intelligence projects. Our team is here to help ensure that your AI models are up-to-date and accurate",
			},
		],
	},
};

export const qualityAssurance = {
	techStack: {
		tagname: "Tech Tools",
		title: "We've got you covered",
		para: "Leave the Quality Testing to us, so that you can focus on your core business",
		image: null,
		techbrands: [qatool1, qatool2, qatool3, qatool4, qatool5],
	},

	enhanceQuality: {
		tagname: "enhance software quality",
		title: "Enhance your software quality without losing momentum.",
		keyPoints: [
			{
				icon: <MdOutlineThumbUp size={24} />,
				text: "To ensure the reliability and effectiveness of your software, our team offers a variety of testing solutions, including end-to-end application testing, automated testing, usability testing, and accessibility testing.",
				color: "orange",
			},
			{
				icon: <BsCode size={24} />,
				text: "With our testing solutions, you can avoid the risks and costs of software incompatibility and deliver high-quality, error-free products to the market at full speed.",
				color: "green",
			},
		],
		image: enhancequalitypic,
	},

	avoidBottleneck: {
		tagname: "Prevent bugs and errors",
		title: "Don’t let the bugs be a bottleneck for deployment.",
		keyPoints: [
			{
				icon: <TbWorld size={24} />,
				text: "Our methodology helps you deliver systems that are able to respond quickly and be available without interruption, ensuring that your users can rely on your software.",
				color: "orange",
			},
			{
				icon: <BsBraces size={24} />,
				text: "We use QA automation and carefully planned test strategies and cases to make the testing process faster and more comprehensive, ensuring that your software is ready for deployment.",
				color: "green",
			},
		],
		image: avoidbugsinqapic,
	},

	qaProcess: {
		tagname: "our quality assurance process",
		title: "Let’s see how it works",
		steps: [
			{
				num: 1,
				icon: <MdOutlineDomainVerification />,
				title: "Requirement verification",
				para: "Effective software QA involves verifying that your requirements are clear, accurate, and achievable. Our team is here to help",
			},
			{
				num: 2,
				icon: <AiOutlineDropbox />,
				title: "Test strategy /test scenario/test cases",
				para: "Developing a thorough test strategy, test scenario, and test cases is crucial to the success of your software QA. Our team is here to help",
			},
			{
				num: 3,
				icon: <VscChecklist />,
				title: "Developer Testing",
				para: "Effective software QA involves providing developer testing to ensure the quality and reliability of your software. Our team is here to help",
			},
			{
				num: 4,
				icon: <MdOutlineRunningWithErrors />,
				title: "Automation testing",
				para: "Effective software QA involves providing automation testing to ensure the quality and reliability of your software. Our team is here to help",
			},
			{
				num: 5,
				icon: <VscRocket />,
				title: "Continuous integration",
				para: "Providing continuous integration is crucial to the success of your software QA. Our team is here to help ensure that your software is continuously tested and updated",
			},

			{
				num: 6,
				icon: <CgPerformance />,
				title: "Performance testing",
				para: "Effective software QA involves providing performance testing to ensure the quality and reliability of your software. Our team is here to help",
			},
			{
				num: 7,
				icon: <TbReport />,
				title: "Defect reporting & verification",
				para: "Providing defect reporting and verification is crucial to the success of your software QA. Our team is here to help ensure that all issues in your software are identified and resolved",
			},
		],
	},
};
