import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import S3FileUpload from "components/shared/uploader/S3FileUpload";
import MessageOrProcessing from "components/form/message-or-processing";
import {
	createPortfolioCaption,
	deletePortfolioCaption,
	updatePortfolioCaption,
	uploadFileActionEvent,
} from "services/all-admin-service";

import "../../Admin.styles.css";

const colsLayout = [
	{ key: 1, value: "Column grid layout (col - 1) " },
	{ key: 2, value: "Columns grid layout (cols - 2)" },
];

function AddPortfolioCaptionForm({
	form = "insert",
	dataList,
	exclusive = {},
	onAfterFormActionEnd,
}) {
	const [uuid, setUUID] = useState(null);
	const [imageFileUrl, setImageFileUrl] = useState(null);
	const [srcfile, srcFile] = useState(null);
	const [viewCaption, setViewCaption] = useState({});
	const [published, setPublished] = useState(1);

	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	useEffect(() => {
		if (!_.isEmpty(viewCaption?.target?.value)) {
			const dropdownId = viewCaption?.target?.value || 0;
			const result = _.find(dataList, (n) =>
				_.isEqual(n.id, dropdownId) ? n : null,
			);

			setUUID(result?.id);
			setImageFileUrl(result?.fileUrl);
			setValue("title", result?.title);
			setValue("description", result?.description);
			setValue("colsLayout", result?.colsLayout);
			setPublished(result?.published);
		}
	}, [viewCaption]);

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	function resetForm() {
		reset();
		srcFile(null);
	}

	async function fileUploadWithUrl(uploadUrl) {
		let hasuploaded = false;
		if (uploadUrl) {
			const fileResp = await uploadFileActionEvent(uploadUrl, srcfile);
			const { uploadfile, uploaderr } = fileResp;
			if (uploadfile && uploadfile?.status === 200) {
				hasuploaded = true;
			} else if (uploaderr) {
				hasuploaded = false;
			}
		}

		return hasuploaded;
	}

	async function onSubmit(data) {
		let resp;
		const fileToUpload = {
			fileName: srcfile?.name,
			contentType: "portfolio-captions",
			fileExt: srcfile?.type?.split("/")[1],
		};
		if (_.isNull(srcfile) && form === "insert") {
			window.alert("Missing image file, please upload an image.");
		} else if (_.isEmpty(errors) && !_.isEmpty(data)) {
			updateFormState(true, false, false);
			if (form === "insert-caption") {
				const { linked, ...rest } = data;

				resp = await createPortfolioCaption({
					portfolioId: linked,
					...rest,
					...fileToUpload,
					published,
				});
			} else if (form === "advance") {
				const { linked, ...rest } = data;
				console.debug(linked);
				resp = await updatePortfolioCaption({
					...rest,
					...fileToUpload,
					id: uuid,
					portfolioId: exclusive?.id,
					nofile: _.isNull(srcfile),
					published,
				});
			} else {
				console.debug("Not form action :", form);
			}

			if (resp.response?.status === 200) {
				const { data = {} } = resp.response;

				if (fileUploadWithUrl(data.uploadUrl)) {
					resetForm();
					updateFormState(false, true, false, "success");
					onAfterFormActionEnd();
				} else {
					updateFormState(false, false, true, "Could not upload file");
				}
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	async function handleClickConfirm() {
		const confirm = window.confirm(
			"Are you sure you want to delete everything?",
		);

		if (confirm) {
			updateFormState(true, false, false);
			const resp = await deletePortfolioCaption(uuid);

			if (resp.response?.status === 200) {
				updateFormState(false, true, false, "deleted!");
				resetForm();
				onAfterFormActionEnd();
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	return (
		<>
			{/* FORM ALERTS! */}
			<MessageOrProcessing {...formState} />

			<form
				className="ff-urban dashboard-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="linked">
						Linked With
					</label>

					<select
						name="linked"
						className="input-feild-dark"
						{...register("linked", {
							onChange: (e) => setViewCaption(e),
							required: "Select a item you want to link",
						})}
						onKeyUp={() => trigger("linked")}
					>
						<option value="">--no--</option>
						{dataList?.map((data) => (
							<option
								key={data.id}
								value={data.id}
								className="text-sm capitalize tracking-wide"
							>
								{data.title}
							</option>
						))}
					</select>
					<span className="form-input-error-text">
						{errors.linked?.message}
					</span>
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="colsLayout">
						column layout format
					</label>

					<select
						name="colsLayout"
						className="input-feild-dark"
						{...register("colsLayout", {
							required: "Select a column format you want to view",
						})}
						onKeyUp={() => trigger("colsLayout")}
					>
						<option value="">--no--</option>
						{colsLayout?.map((format) => (
							<option
								key={format.key}
								value={format.key}
								className="text-sm capitalize tracking-wide"
							>
								{format.value}
							</option>
						))}
					</select>
					<span className="form-input-error-text">
						{errors.colsLayout?.message}
					</span>
				</div>

				<div className="dash-input-group-full">
					<label className="input-lbl" htmlFor="title">
						caption title
					</label>
					<input
						id="title"
						type="text"
						name="title"
						autoComplete="off"
						placeholder="Enter caption title (Example:Introduction)"
						className="input-feild-dark"
						{...register("title", {
							required: "Title is required.",
						})}
						onKeyUp={() => trigger("title")}
					/>
					<span className="form-input-error-text">{errors.title?.message}</span>
				</div>
				<div className="dash-input-group-full">
					<label className="input-lbl" htmlFor="description">
						caption description
					</label>

					<textarea
						name="description"
						id="description"
						placeholder="Enter caption description (Example:About introduction)"
						className="input-feild-dark h-[160px]"
						{...register("description", {
							required: "Description is required.",
						})}
						onKeyUp={() => trigger("description")}
					/>
					<span className="form-input-error-text">
						{errors.description?.message}
					</span>
				</div>

				{/* File uploader */}
				<div className="mx-4 mt-4 mb-8 w-full">
					<S3FileUpload
						previewFileUrl={imageFileUrl || exclusive?.fileUrl}
						setFile={(file) => srcFile(file)}
					/>
				</div>

				<div className="ml-4 mt-4 flex w-full items-center gap-2">
					<input
						type="checkbox"
						className="text-yankees-blue-900 focus:ring-0"
						checked={published === 1}
						onChange={() => setPublished(published === 1 ? 0 : 1)}
					/>
					<span className="text-base tracking-wide">
						Yes, want to publish this system information.
					</span>
				</div>

				{/* submit button */}

				{formState.processing ? (
					<div className="mt-4 flex w-full items-center  text-dark-cyan">
						<CgSpinner size={30} className="mr-2 animate-spin" />
						<span className=" text-sm tracking-wide"> Saving now...</span>
					</div>
				) : (
					<div
						className="mx-4 mt-8 flex w-full
					 items-center justify-between
					 space-x-4"
					>
						{form !== "advance" ? (
							<button
								onClick={resetForm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
							rounded bg-dark-jungle py-2.5 px-3 text-[15px]
          		font-medium capitalize tracking-wider text-white"
							>
								cancel
							</button>
						) : (
							<button
								onClick={handleClickConfirm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
							rounded bg-dark-jungle/40 py-2.5 px-3 text-[15px]
          		font-medium capitalize tracking-wider text-white"
							>
								delete portfolio
							</button>
						)}

						<button
							type="submit"
							className="ff-urban inline-block w-full
							cursor-pointer rounded bg-dark-cyan py-2.5 px-3 text-[15px]
							font-medium capitalize tracking-wider text-white"
						>
							{form !== "advance"
								? "save portfolio caption"
								: "update portfolio caption"}
						</button>
					</div>
				)}
			</form>
		</>
	);
}

export default React.memo(AddPortfolioCaptionForm);
