import _ from "lodash";
import React, { useState } from "react";
import { ImMobile, ImUser } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";
import { BsCalendar3 } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { deleteApplicantFileById } from "services/all-admin-service";

function GerneralViewResume({ exclusive = {}, onAfterFormActionEnd }) {
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	console.log(formState);

	const updateRequestState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	if (_.isEmpty(exclusive)) {
		return <FbSrcLoading />;
	}

	async function handleClickConfirm() {
		const confirm = window.confirm(
			"Are you sure you want to delete everything?",
		);

		if (confirm) {
			updateRequestState(true, false, false);
			const resp = await deleteApplicantFileById(exclusive?.id);

			if (resp.response?.status === 200) {
				updateRequestState(false, true, false, "deleted!");
				onAfterFormActionEnd();
			} else if (resp?.err) {
				updateRequestState(false, false, true, resp.err);
			}
		}
	}

	return (
		<div className="gerneral-view-wrapper">
			<div className="resume-meta ff-urban">
				<div className="flex w-full flex-wrap items-center justify-between">
					<div className="text-dark-jungle">
						<h5 className="py-1 text-sm font-semibold capitalize tracking-wide text-dark-cyan">
							{exclusive?.vacant.group}
						</h5>
						<h2 className="mb-3 max-w-full text-lg font-bold capitalize lg:max-w-[90%] lg:text-xl">
							{exclusive?.vacant.title}
						</h2>
					</div>

					<div>
						<a
							href={exclusive?.fileUrl}
							download
							className="
							ff-urban inline-flex w-auto cursor-pointer
							items-center gap-2
							whitespace-nowrap rounded bg-dark-cyan
							py-2 px-3 text-[13px] font-medium
							capitalize tracking-wider text-white
						hover:bg-dark-gunmetal"
						>
							download resume
							<FaDownload size={14} />
						</a>
					</div>
				</div>
			</div>
			<div className="resume-personal my-8 flex flex-wrap items-start text-arsenic-700 ">
				<div className="box w-full space-y-6 md:w-1/2">
					<div className="ff-urban flex items-center gap-3 text-base font-medium">
						<ImUser
							size={34}
							className="flex-shrink-0 rounded-full  bg-arsenic-300 p-2 text-white"
						/>
						{exclusive?.fullname}
					</div>
					<div className="ff-urban flex items-center gap-3 text-base font-medium ">
						<MdEmail
							size={34}
							className="flex-shrink-0 rounded-full  bg-arsenic-300 p-2 text-white"
						/>
						{exclusive?.email}
					</div>

					<div className="ff-urban flex items-center gap-3 text-base font-medium">
						<ImMobile
							size={34}
							className="flex-shrink-0 rounded-full  bg-arsenic-300 p-2 text-white"
						/>
						{exclusive?.phone}
					</div>
					<div className="ff-urban flex items-center gap-3 text-base font-medium">
						<BsCalendar3
							size={34}
							className="flex-shrink-0 rounded-full  bg-arsenic-300 p-2 text-white"
						/>
						{exclusive?.modifiedAt}
					</div>
				</div>
				<div className="box w-full md:w-1/2">
					<div className="w-fill ff-urban py-8 px-2 lg:py-0">
						<b>Why do you want to join with us.?</b>
						<p className="my-3 text-sm leading-6 tracking-wide">
							{exclusive?.reason}
						</p>
					</div>
				</div>
			</div>
			<div className="resume-actions flex w-full flex-wrap items-center justify-end gap-3">
				<button
					type="button"
					onClick={handleClickConfirm}
					className="
					ff-urban inline-block
					w-auto cursor-pointer
				  rounded bg-dark-jungle/75
				 	py-2.5 px-5 text-[15px]
					font-medium capitalize
					tracking-wider text-white"
				>
					ignore
				</button>
				<button
					type="button"
					className="
					ff-urban inline-block
					w-auto cursor-pointer
				  whitespace-nowrap rounded
				 	bg-dark-cyan py-2.5 px-5
					text-[15px] font-medium capitalize
					tracking-wider text-white"
				>
					share profile
				</button>
				<button
					type="button"
					className="
					ff-urban inline-block
					w-auto cursor-pointer
				  rounded bg-dark-cyan
				 	py-2.5 px-5 text-[15px]
					font-medium capitalize
					tracking-wider text-white"
				>
					shortlist
				</button>
			</div>
		</div>
	);
}

export default React.memo(GerneralViewResume);
