import React, { useState } from "react";

function ExclusiveSetting({ tabsviews }) {
	const [tabIndex, setTabIndex] = useState(0);

	function toggleLayout(event) {
		setTabIndex(Number(event.target.id));
	}

	return (
		<div className="modal-content">
			<div className="setting-controller w-full">
				{tabsviews?.length >= 2 && (
					<div className="settings flex gap-2 border-b">
						<button
							id={0}
							onClick={toggleLayout}
							type="button"
							className={`ff-urban inline-block w-auto cursor-pointer
								border-b-2 bg-transparent py-2.5 px-2 text-sm
          			font-medium capitalize tracking-wider ${
									tabIndex === 0
										? "border-dark-cyan bg-dark-cyan/10"
										: "border-transparent"
								} `}
						>
							general / read-only
						</button>

						<button
							id={1}
							onClick={toggleLayout}
							type="button"
							className={`ff-urban inline-block w-auto cursor-pointer
							border-b-2 bg-transparent py-2.5 px-2 text-sm
							font-medium capitalize tracking-wider ${
								tabIndex === 1
									? "border-dark-cyan bg-dark-cyan/10"
									: "border-transparent"
							} `}
						>
							advance settings
						</button>

						{tabsviews?.length >= 3 && (
							<button
								id={2}
								onClick={toggleLayout}
								type="button"
								className={`ff-urban inline-block w-auto cursor-pointer
									border-b-2 bg-transparent py-2.5 px-2 text-sm
									font-medium capitalize tracking-wider ${
										tabIndex === 2
											? "border-dark-cyan bg-dark-cyan/10"
											: "border-transparent"
									} `}
							>
								linked advance settings
							</button>
						)}
					</div>
				)}
			</div>
			<div className="setting-viewer my-4">{tabsviews[tabIndex]}</div>
		</div>
	);
}

export default ExclusiveSetting;
