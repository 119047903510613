import React from "react";
import { Outlet } from "react-router-dom";

function AuthLayout() {
	return (
		<div
			className="
		 	flex
		    h-full
			min-h-screen
			w-full
			items-center
			justify-center"
		>
			<Outlet />
		</div>
	);
}

export default AuthLayout;
