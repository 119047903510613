import React from "react";
import SEO from "components/shared/seo";

import { allServices as Hero } from "assets/data/hero.dto";
import { softwareDevelopment } from "assets/data/service.dto";

import CaseStudiesExamples from "components/shared/case-studies/CaseStudiesExamples";
import HeroBasic from "components/shared/heros/basic/HeroBasic";
import MobileServiceWorks from "./components/FloatLeftTextContent";
import WebServiceWorks from "./components/FloatRightTextContent";
import ServiceOfProcess from "./components/ServiceOfProcess";
import TechServiceStack from "./components/TechServiceStack";

import "./AllService.styles.css";

function SoftwareDevelopmentPage() {
	const { development } = Hero;
	const { mobileDev, webDev, devProcess, techStack, caseStudies } =
		softwareDevelopment;

	return (
		<>
			<SEO
				link="software-development"
				postfixSlug="true"
				noIndex={false}
				title="Software Development Services - Custom Software Solutions"
				description="Einteger Solutions is a leading software development company that specializes in providing custom product development services to businesses. Our experienced team offers a wide range of services including software development, AI, and software QA to help businesses grow and succeed. We use agile methodologies and the latest technologies to deliver high-quality products that drive business growth and improve efficiency. Contact us to learn more about our product development process"
				keywords="product development, software development, custom solutions, business growth, AI, software QA, agile methodologies, latest technologies, Einteger Solutions"
			/>
			<HeroBasic {...development} />
			<MobileServiceWorks {...mobileDev} />
			<WebServiceWorks {...webDev} />
			<ServiceOfProcess {...devProcess} />
			<TechServiceStack {...techStack} />
			<CaseStudiesExamples {...caseStudies} />
		</>
	);
}

export default SoftwareDevelopmentPage;
