import React from "react";
import { Link } from "react-router-dom";
import JoinNowCover from "assets/images/photo/careers-join-now1.png";

function ApplyNow() {
	return (
		<section className="vision-mission-section mt-24 mb-24 h-full w-full lg:mb-32">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				{/* from-orange-100 to-halloween-orange-500 lg:-mt-4 xl:-mt-5 2xl:-mt-9 */}

				<div
					className="relative z-0 mx-auto flex w-full max-w-full flex-col items-center overflow-hidden
					rounded-xl bg-gradient-to-r  from-[#333748] to-[#34394C]
					py-2 md:flex-row lg:max-w-[85%] xl:max-w-[70%]"
				>
					<div className="flex flex-col items-center md:flex-row">
						<div className="relative h-full max-h-72 w-[60%] p-0 md:w-1/2 lg:p-0 xl:p-0">
							<img
								src={JoinNowCover}
								alt="join-now"
								className="h-full w-full object-contain object-top"
							/>
						</div>
						<div className="content w-full p-4 md:w-1/2 lg:px-4 2xl:px-8">
							<h2
								className="ff-urban mb-4 text-2xl font-semibold text-white
						  md:text-3xl md:leading-[2.6rem] lg:text-3xl lg:leading-[2.7rem] xl:text-4xl xl:leading-[3rem]"
							>
								Apply to be part of a great team!
							</h2>

							<Link
								to="/careers"
								className="ff-urban mb-4 mt-0 inline-block  rounded bg-white
								 px-4 py-2 font-bold text-arsenic-900
								 transition-all duration-300 hover:ring-2 
								 hover:ring-yankees-blue-500 hover:ring-offset-2 hover:ring-offset-yankees-blue-600 md:mt-4"
							>
								Join with us
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ApplyNow;
