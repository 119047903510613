import _ from "lodash";
import React from "react";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

function GerneralView({ exclusive = {} }) {
	const { portfolio = {}, embedCaptions = [] } = exclusive || {};

	function renderWithSubstring(text = "") {
		let renderText = text;
		if (renderText.length > 40) {
			renderText = `${renderText.substring(0, 40)}...`;
		}

		return renderText;
	}

	if (_.isEmpty(portfolio)) {
		return <FbSrcLoading />;
	}

	return (
		<div className="gerneral-view-wrapper">
			<div className="flex gap-2 px-2 py-4">
				<div className="left w-full space-y-4 lg:w-1/3">
					<div>
						<h4 className="text-sm uppercase">thumbnail image</h4>
						<div
							className="thumbnail my-2 w-full max-w-[375px]
						 rounded border border-dashed p-2"
						>
							<img
								src={portfolio?.fileUrl}
								alt="thumbnail"
								className="h-full w-full object-contain"
							/>
						</div>
					</div>

					<div>
						<h4 className="text-sm uppercase">portfolio title</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{portfolio?.title}
						</span>
					</div>

					<div>
						<h4 className="text-sm uppercase">subtitle</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{portfolio?.subtitle}
						</span>
					</div>

					<div>
						<h4 className="text-sm uppercase">subject</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{portfolio?.subject}
						</span>
					</div>

					<div>
						<h4 className="text-sm uppercase">status</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{portfolio?.published ? "Published" : "Unpublished"}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">created</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{portfolio?.createdAt}
						</span>
					</div>
				</div>
				<div className="right w-full lg:w-2/3">
					<ul
						className="caption-list ml-0 max-h-[540px]
					 space-y-3 overflow-auto lg:ml-8"
					>
						{embedCaptions?.length < 1 ? (
							<div>
								<h4>No Caption Found </h4>
								<p className="mt-2 text-sm tracking-wide text-gray-400">
									Click{" "}
									<span className="text-dark-cyan">Link/Attach Caption</span>{" "}
									button to add new caption column under the portfolio.
								</p>
							</div>
						) : (
							embedCaptions?.map((caption, i) => (
								<li
									key={i}
									className="caption bg-gray-0 flex w-full gap-4
							 	rounded border border-dashed p-2"
								>
									<div className="caption-image h-20 w-24 flex-shrink-0">
										<img
											src={caption?.fileUrl}
											alt={caption?.title}
											className="h-full w-full object-cover"
										/>
									</div>
									<div className="caps-content ff-urban flex-shrink">
										<h4 className="block text-base font-semibold capitalize">
											{caption?.title}
										</h4>
										<small className="text-gray-600">
											{caption?.createdAt}
										</small>
										<details className="text-[13px] tracking-wide text-gray-400">
											<summary>
												{renderWithSubstring(caption.description)}
											</summary>
											{caption?.description}
										</details>
									</div>
								</li>
							))
						)}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default GerneralView;
