import { Auth } from "aws-amplify";

export async function getAuthUserInfo() {
	let userSession;
	try {
		const cognitoUser = await Auth.currentAuthenticatedUser();
		if (cognitoUser) {
			userSession = {
				user: cognitoUser.attributes,
				sessionToken: cognitoUser.signInUserSession,
			};
		}
	} catch (err) {
		console.debug("getAuthUserInfo failed cause: ", err);
		return null;
	}

	return userSession || {};
}

export async function signIn(auth) {
	const result = { userInfo: null, err: null };
	try {
		const cognitoUser = await Auth.signIn(auth.email, auth.password);
		result.userInfo = {
			user: cognitoUser.attributes,
			sessionToken: cognitoUser.signInUserSession,
		};
	} catch (err) {
		console.debug("sign-in failed cause: ", err);
		result.err = err?.message;
	}

	return result;
}

export async function signOut() {
	const result = { isLogout: false };
	try {
		await Auth.signOut();
		result.isLogout = true;
	} catch (err) {
		console.debug("sign-out failed cause: ", err);
		result.err = err?.message;
		result.isLogout = false;
	}

	return result;
}
