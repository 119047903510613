import React from "react";
import { banner } from "assets/data/aboutus.dto";

function CompanyStats() {
	const { stats } = banner;
	return (
		<div className="stats-wrapper relative top-0 left-0 h-auto w-full border-b bg-white">
			<div className="container mx-auto flex flex-wrap items-center justify-center px-4 lg:px-16 xl:px-20 2xl:px-24">
				{stats.map((stat, index) => (
					<div
						key={index}
						className="ff-urban-x flex w-full flex-col px-0 py-2 md:w-1/3  lg:py-6"
					>
						<div
							className="mb-2 self-center font-semibold capitalize tracking-wider
             text-arsenic-600 lg:mb-2"
						>
							{stat.tag}
						</div>
						<div className="inline-flex items-center gap-4 self-center">
							<div
								className="flex-shrink-0 text-5xl
             text-halloween-orange-900 xl:text-5xl 2xl:text-6xl "
							>
								{stat.icon}
							</div>
							<div
								className="flex-shrink-0 text-4xl font-bold text-yankees-blue-800
              xl:text-4xl 2xl:text-5xl "
							>
								{stat.value}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default CompanyStats;
