import React from "react";
import SEO from "components/shared/seo";
import { useNavigate } from "react-router-dom";
import { viableProduct } from "assets/data/home.dto";
import DefaultContentColsLayout from "./components/DefaultContentColsLayout";
import SimpleBannerLayout from "./components/SimpleBannerLayout";
import HowItWorkLayout from "./components/HowItWorkLayout";

function MinimumViableProductPage() {
	const { defaultSection, mainSection } = viableProduct;

	const navigate = useNavigate();

	function handleCallToAction(path) {
		navigate(path);
	}

	return (
		<>
			<SEO
				link="minimum-viable-product"
				postfixSlug="true"
				noIndex={false}
				title="MVP Development Services - Accelerate Your Product to Market Success"
				description="Einteger Solutions is a leading software development company that specializes in building minimum viable products (MVPs) that succeed in the marketplace. Our expert team uses proven techniques and technologies to build lightweight, scalable MVPs that provide value to customers and drive business growth. Our transparent, efficient, and collaborative MVP development process ensures that your solutions are tailored to your specific needs and are ready for the market. Learn more about our MVP development services on our website."
				keywords="MVP development, minimum viable products, market success, expert team, proven techniques, technologies, lightweight, scalable MVPs, business growth, tailored solutions, Einteger Solutions, MVP development process, product to market"
			/>
			<SimpleBannerLayout {...mainSection} ctaLinkEvent={handleCallToAction} />
			<DefaultContentColsLayout {...defaultSection} />
			<HowItWorkLayout workType="viable-product" />
		</>
	);
}

export default MinimumViableProductPage;
