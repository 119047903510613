import _ from "lodash";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

export function VacantList({ dataLoading, data, current, index }) {
	function renderReplaceSpaceWithLowercase(name = "") {
		const text = name.replace(/\s+/g, "-").toLowerCase();
		return text.replaceAll("/", "or");
	}

	return (
		<ul
			className={`tab-content  w-full space-y-1 overflow-hidden
      	bg-white transition-all duration-300 ease-in-out ${
					index === current ? "max-h-[50vh]" : "max-h-0"
				}`}
		>
			{dataLoading ? <FbSrcLoading /> : null}
			{_.isEmpty(data) ? (
				<div className="w-full p-4 text-center text-sm">
					No Positions Available
				</div>
			) : (
				data.map((vacancy, idx) =>
					vacancy.published === 1 ? (
						<li
							key={idx}
							className="flex items-center justify-between gap-2
            			  bg-yankees-blue-50 py-3 px-3 "
						>
							<span
								className="flex-shrink text-base tracking-wide
						 text-arsenic-600 "
							>
								{vacancy.title}
							</span>
							<Link
								to={`/careers/${renderReplaceSpaceWithLowercase(
									vacancy.title,
								)}`}
								state={{ career: vacancy }}
								className="flex-shrink-0 whitespace-nowrap rounded-full
								border  bg-gradient-to-r from-[#de6262] to-[#ffb88c] px-2
								py-1.5 text-xs font-medium capitalize tracking-wide
							  text-arsenic-100 hover:animate-pulse"
							>
								apply now
							</Link>
						</li>
					) : null,
				)
			)}
		</ul>
	);
}

function AccordionColsView({
	navTabs,
	currentTab,
	loading,
	vacantPositions,
	onChangeVacancntType,
}) {
	return (
		<div className="block shadow-xl lg:hidden">
			<div className="table-accordion-wrapper">
				{navTabs.map((tab, index) => (
					<div
						key={index}
						className="item border-b border-gray-100 last:border-b-0"
					>
						<button
							type="button"
							onKeyPress={() => onChangeVacancntType(tab)}
							onClick={() => onChangeVacancntType(tab)}
							key={index}
							className={`tab-title flex w-full items-center justify-between
							p-4 text-lg font-medium capitalize tracking-wide text-white ${
								tab.key === currentTab
									? "bg-yankees-blue-900"
									: "bg-yankees-blue-400"
							}`}
						>
							<span className="text-base capitalize">{tab.value}</span>
							<BiChevronDown
								size={24}
								className={`${index === currentTab ? "rotate-0" : "rotate-90"}`}
							/>
						</button>

						{/* sperate component> */}
						<VacantList
							dataLoading={loading}
							data={vacantPositions}
							current={currentTab}
							index={tab.key}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

export default React.memo(AccordionColsView);
