import React from "react";
import _ from "lodash";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

function GerneralView({ exclusive = {} }) {
	if (_.isEmpty(exclusive)) {
		return <FbSrcLoading />;
	}

	return (
		<div className="gerneral-view-wrapper">
			<div className="flex gap-2 px-2 py-4">
				<div className="left w-full space-y-4 lg:w-1/3">
					<div>
						<h4 className="text-sm uppercase">designation</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.title}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">group</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.group}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">work area</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.location}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">status</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.published ? "Published" : "Unpublished"}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">created</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.createdAt}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">work type</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.worktype}
						</span>
					</div>
				</div>

				<div className="left w-full lg:w-2/3">
					<div>
						<h4 className="text-sm uppercase">what we expect</h4>
						<ul className="my-4">
							{exclusive?.expects.map((expect, i) => (
								<li
									key={i}
									className="ff-urban mb-2 text-sm tracking-wide text-gray-400"
								>
									{expect}
								</li>
							))}
						</ul>
					</div>

					<div>
						<h4 className="text-sm uppercase">responsibilities</h4>
						<ul className="my-4">
							{exclusive?.responsibilities.map((responsibility, i) => (
								<li
									key={i}
									className="ff-urban mb-2 text-sm tracking-wide text-gray-400"
								>
									{responsibility}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GerneralView;
