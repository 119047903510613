export const askquestions = [
	{
		question: "What services does Einteger Solutions offer?",
		reply:
			"Einteger Solutions offers a range of software development, product development, and artificial intelligence services to help businesses achieve success.",
	},
	{
		question: "How does Einteger Solutions approach product development?",
		reply:
			"Einteger Solutions follows a process of gathering customer requirements, prioritizing a list of features to build, breaking down tasks and grooming the backlog, and deploying and maintaining the finished product.",
	},
	{
		question:
			"What is Einteger Solutions' approach to Minimum Viable Products (MVPs)?",
		reply:
			"Einteger Solutions helps businesses build MVPs that are lightweight and scalable, with the goal of quickly testing and launching products in the market.",
	},
	{
		question:
			"What is the process for working with Einteger Solutions on a software development project?",
		reply:
			"The process for working with Einteger Solutions on a software development project typically includes sharing ideas, creating a design brief and project plan, prototyping and development, and testing and deployment.",
	},
	{
		question: "What is Einteger Solutions' approach to Quality Assurance (QA)?",
		reply:
			"Einteger Solutions provides a variety of testing solutions, including end-to-end application testing, automated testing, usability testing, and accessibility testing, to ensure that products are error-free and meet customer requirements.",
	},
	{
		question:
			"What is Einteger Solutions' approach to artificial intelligence?",
		reply:
			"Einteger Solutions follows a process of research, empathizing, creating, testing and developing, and continuous maintenance when working on artificial intelligence projects.",
	},
	{
		question: "How does Einteger Solutions approach UI/UX design?",
		reply:
			"Einteger Solutions follows a process of research, empathizing, creating, and testing and developing when working on UI/UX design projects.",
	},
];
