import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import SingleMemoSlide from "./SingleMemoSlide";

function ScrollRow({ rowId, photos }) {
	const slideLeft = () => {
		const slider = document.getElementById(`slider-${rowId}`);
		slider.scrollLeft -= 500;
	};
	const slideRight = () => {
		const slider = document.getElementById(`slider-${rowId}`);
		slider.scrollLeft += 500;
	};

	return (
		<div className="relative bg-yankees-blue-900 pb-4">
			<div className="group relative flex items-center">
				<MdChevronLeft
					onClick={slideLeft}
					className="absolute left-0 z-10 hidden cursor-pointer rounded-full
					 bg-white opacity-50 hover:opacity-100 group-hover:block"
					size={40}
				/>
				<div
					id={`slider-${rowId}`}
					className="relative h-full w-full select-none
					snap-x snap-mandatory overflow-scroll
					overscroll-contain scroll-smooth whitespace-nowrap scrollbar-hide"
				>
					{/* {Array.from(Array(10).keys()).map((num, index) => (
						<SingleMemoSlide key={index} item={num} poster={photo} />
					))} */}

					{photos.length > 0
						? photos.map((photo, index) => (
								<SingleMemoSlide
									key={index}
									caption={photo.caption}
									image={photo.fileUrl}
								/>
						  ))
						: null}
				</div>
				<MdChevronRight
					onClick={slideRight}
					className="
					absolute right-0 z-10 hidden cursor-pointer rounded-full
					bg-white opacity-50 hover:opacity-100 group-hover:block"
					size={40}
				/>
			</div>
		</div>
	);
}

export default React.memo(ScrollRow);
