import React from "react";

function FloatLeftTextContent({ tagname, title, keyPoints, image }) {
	return (
		<section className="service-worker-section mt-12 mb-8 h-full w-full lg:mb-12">
			<div className="container z-[1] mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="service-wrapper h-full w-full">
					<div className="block-container flex flex-col py-8 lg:flex-row lg:py-16">
						<div className="left order-2 w-full lg:order-1 lg:w-1/2">
							<div className="content-box max-w-[90%]">
								<div className="title hidden lg:block">
									<h4
										className="ff-sanspro block text-lg font-semibold
							    capitalize text-teal-700"
									>
										{tagname}
									</h4>
									<h1
										className="block py-4 text-3xl font-normal leading-[2.5rem] text-arsenic-900
                    xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
									>
										{title}
									</h1>
								</div>
								<div className="keypoint-wrapper mt-8 space-y-8">
									{keyPoints.map((point, index) => (
										<div key={index} className="flex gap-4 lg:gap-8">
											<div
												className={`xtext-halloween-orange-dark flex
                      h-12 w-12 flex-shrink-0 items-center
										justify-center bg-teal-100 text-teal-900
											`}
											>
												{point.icon}
											</div>
											<div
												className="ff-urban text-[13px] font-light
                      leading-[24px] tracking-wider text-arsenic-800
											 2xl:text-[15px] 2xl:leading-[2rem]"
											>
												{point.text}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="right order-1 flex w-full flex-col gap-4 lg:order-2 lg:w-1/2 lg:flex-row">
							<div className="title block lg:hidden">
								<h4
									className="ff-sanspro block text-lg font-semibold
							     capitalize text-teal-900"
								>
									{tagname}
								</h4>
								<h1
									className="block py-4 text-2xl font-normal leading-[2.5rem] text-arsenic-900
                    xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
								>
									{title}
								</h1>
							</div>
							<div className="image-box lg:1/2 w-full md:h-[460px] lg:h-[560px]">
								<img
									src={image}
									alt={title}
									className="h-full w-full object-contain"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default FloatLeftTextContent;
