// import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ImDownload } from "react-icons/im";
import { MdDeleteForever, MdNoteAdd } from "react-icons/md";
import { deleteEvent, getAllEvents } from "services/all-admin-service";
import PopupWizardOverlay from "components/shared/popup-wizard";
import FormPopupWizard from "../components/FormPopupWizard";
import AddEventForm from "../components/form/AddEventForm";

const modalInitialState = { modal: null, state: false };

function AdminTeamEventsPage() {
	const [viewModal, setViewModal] = useState(modalInitialState);
	const [gallery, setGallery] = useState([]);

	async function onLoadPhotoGallery() {
		const res = await getAllEvents();
		if (res.response?.status === 200) {
			const { response } = res;
			setGallery(response.data);
		}
	}

	function handleModal(modal) {
		setViewModal((prev) => ({
			modal,
			state: !prev.state,
		}));
	}

	useEffect(() => {
		onLoadPhotoGallery();
	}, []);

	function handleExitModal() {
		setViewModal(modalInitialState);
	}

	function handleAfterFormActionEnd() {
		setViewModal(modalInitialState);
		setTimeout(() => onLoadPhotoGallery(), 3000);
	}

	async function handleClickConfirm(eventId) {
		const confirm = window.confirm(
			"Are you sure you want to delete this event.?",
		);

		if (confirm) {
			const resp = await deleteEvent(eventId);

			if (resp.response?.status === 200) {
				handleAfterFormActionEnd();
			} else if (resp?.err) {
				window.alert(resp?.err);
			}
		}
	}

	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div className="content-header block items-center justify-between lg:flex">
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								photos / team events
							</h3>

							<p
								className="ff-urban max-w-full py-2
								text-base tracking-wider text-dark-jungle/70
								lg:max-w-[80%] xl:lg:max-w-[60%]"
							>
								Team building activities to do outside the office and large
								group team building activities.
							</p>
						</div>
						<div className="top-right">
							<div className="actions space-x-4">
								<button
									type="button"
									onClick={() => handleModal("insert")}
									className="inline-flex items-center whitespace-nowrap
							 		rounded bg-dark-cyan px-3 py-2 text-[15px]
									tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<MdNoteAdd size={19} className="text-white" />
									<span className="ff-urban ml-1">Generate events gallery</span>
								</button>
								<button
									type="button"
									onClick={onLoadPhotoGallery}
									className="group inline-flex items-center
							 		whitespace-nowrap rounded bg-dark-cyan px-3 py-2 text-[15px]
									tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<ImDownload
										size={19}
										className="
										text-white transition-all
										duration-500 ease-out
										group-active:animate-bounce"
									/>
									<span className="ff-urban ml-1">Fetch Gallery</span>
								</button>
							</div>
						</div>
					</div>
					{/* END HEADER */}

					{/* grid layout of photo galley */}
					<div className="my-12 px-0">
						<div className="gallery-wrapper grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
							{gallery?.map((event, index) => (
								<div
									key={index}
									className="gallery-card bg-white p-3 shadow-md"
								>
									<img
										src={event.fileUrl}
										alt={event.caption}
										className="h-[225px] w-full object-cover"
									/>
									<div className="mt-3 flex items-center gap-2 text-gray-500">
										<button
											type="button"
											className="hover:text-red-700"
											onClick={() => handleClickConfirm(event.id)}
										>
											<MdDeleteForever size={24} />
										</button>
										<small className="text-sm capitalize">
											{event.caption}
										</small>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			{/*  */}
			<PopupWizardOverlay
				isOpen={viewModal.state}
				handleClickOverlay={() => console.debug("overlay")}
			>
				<FormPopupWizard onExit={handleExitModal} title="add new photo gallery">
					<AddEventForm
						form="insert"
						onAfterFormActionEnd={handleAfterFormActionEnd}
					/>
				</FormPopupWizard>
			</PopupWizardOverlay>
		</section>
	);
}

export default AdminTeamEventsPage;
