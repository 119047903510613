import React from "react";
import { VscClose } from "react-icons/vsc";

function FormPopupWizard({ title, onExit, children }) {
	return (
		<div className="modal modal--lg">
			<div className="modal-wrapper">
				<div className="modla-header flex items-center justify-between border-b px-4 py-3">
					<h3 className="ff-urban font-mediumx font-bold uppercase lg:text-base">
						{title}
					</h3>
					<button
						type="button"
						onKeyPress={onExit}
						onClick={onExit}
						className="
						text-maastricht-blue-600
						hover:text-maastricht-blue-900
						hover:animate-pulse hover:cursor-pointer"
					>
						<VscClose size={24} />
					</button>
				</div>

				{/* Component */}
				<div className="p-5 xl:p-10">{children}</div>
			</div>
		</div>
	);
}

export default FormPopupWizard;
