import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmbedCaptionByPortfolioId } from "services/all-admin-service";
import ReadyContactUs from "components/shared/ready-to-contact/ReadyContactUs";
import { CgSpinner } from "react-icons/cg";
import FloatLeftTextInfo from "./components/FloatLeftTextInfo";
import FloatRightTextInfo from "./components/FloatRightTextInfo";
import ViewEndInfo from "./components/ViewEndInfo";
import "./Portfolio.styles.css";

function SinglePortfolioPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const [cols, setCols] = useState([]);
	const [rows, setRows] = useState([]);
	const { state } = location;

	function filterPortfolioLayout(data = []) {
		if (_.isEmpty(data)) return;

		const rowLayout = _.filter(data, { colsLayout: "1" });
		const colLayout = _.filter(data, { colsLayout: "2" });
		setRows(rowLayout);
		setCols(colLayout);
	}

	useEffect(() => {
		async function fetchPortfolioById(request) {
			const res = await getEmbedCaptionByPortfolioId(request.id);
			if (res?.response && res.response.status === 200) {
				const { response } = res;
				filterPortfolioLayout(response.data);
			} else if (res?.err) {
				console.log("Error:", res.err);
			}
		}

		if (!state || _.isNil(state?.portfolio)) navigate("/portfolio");
		fetchPortfolioById(state?.portfolio);
	}, [location, navigate, state]);

	useEffect(() => {
		return () => {
			setCols([]);
			setRows([]);
		};
	}, []);

	function isEven(index) {
		return index % 2 === 0;
	}

	return (
		<section className="top-portfolio-section h-full min-h-[70vh] w-full bg-white">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="intro-portfolio-wrapper m-0 h-full w-full">
					<div className="intro-heading mb-8 pt-8 text-center lg:mb-0 lg:py-4 xl:py-6 2xl:py-8">
						<h1
							className="
							ff-urban-x text-2xl font-bold
						 	text-arsenic-600 lg:text-3xl xl:text-4xl"
						>
							{state?.portfolio?.title || "unknown title"}
						</h1>
					</div>

					<div className="portfolio-meta-wrapper">
						{_.isEmpty(cols) ? (
							<div className="flex items-center gap-2">
								<CgSpinner size={30} className="animate-spin" /> Loading...
							</div>
						) : (
							cols?.map((meta, index) => {
								return isEven(index) ? (
									<FloatLeftTextInfo meta={meta} key={index} />
								) : (
									<FloatRightTextInfo meta={meta} key={index} />
								);
							})
						)}

						<div className="portfolio-full-cols">
							{_.isEmpty(rows)
								? null
								: rows.map((meta, index) => (
										<ViewEndInfo meta={meta} key={index} />
								  ))}
						</div>
					</div>
				</div>
			</div>
			<ReadyContactUs />
		</section>
	);
}

export default SinglePortfolioPage;
