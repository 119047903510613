import React from "react";
import Pagination from "components/pagination";
import { TbDotsVertical } from "react-icons/tb";
import { RiFolderSettingsFill, RiRefreshLine } from "react-icons/ri";

function DataTableView({
	onrefresh,
	theadCols,
	sheet,
	cols,
	handleClickActionButton,
	handlePageNavsClick,
}) {
	function renderColsAsShort(text = "") {
		let renderText = text;
		if (renderText.length > 80) {
			renderText = `${renderText.substring(0, 80)}...`;
		}

		return renderText;
	}

	return (
		<div className="table-panel-wrapper ff-urban-0 mt-12 overflow-auto rounded-xl bg-white p-2">
			<div className="table-tools-controlls block items-center justify-between p-2 lg:flex">
				<div className="relative w-auto min-w-[300px]">
					<div className="ff-urban flex items-center ">
						<span className="mr-1 text-base font-semibold">
							Table content filter functionality
						</span>
						<TbDotsVertical size={18} />
					</div>
					<ul
						className="filter-dropdown ff-urban absolute
        				top-[120%] left-0 hidden space-y-0 rounded border
        			  bg-white font-medium text-gray-500 shadow-lg"
					>
						<li className="ff-ur block px-4 py-2 text-sm">
							Frontend Developer Created
						</li>
						<li className="block px-4 py-2 text-sm">
							Backend Developer Created
						</li>
						<li className="block px-4 py-2 text-sm">Devops Engineer Created</li>
					</ul>
				</div>

				<div className="flex items-center">
					<button
						title="refresh sheet"
						type="button"
						className="
						group mr-1 transform rounded border p-1.5 text-dark-gunmetal/50 transition-all
						duration-300 ease-in-out hover:border-dark-cyan hover:text-dark-cyan active:scale-75"
						onClick={onrefresh}
					>
						<RiRefreshLine
							size={20}
							onClick={onrefresh}
							className="group-active:animate-spin"
						/>
					</button>
					<Pagination
						currentPage={1}
						handlerNextOrPrevAction={handlePageNavsClick}
					/>
				</div>
			</div>
			<div className="table-cotainer mt-2">
				<table className="w-full">
					<thead className="border-b-0 border-gray-100 bg-gray-50">
						<tr>
							{theadCols?.map((col, i) => (
								<th
									key={i}
									className="
									ff-urban p-3 text-left text-xs font-bold
									uppercase tracking-wider text-gray-400"
								>
									{col.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="w-full space-y-4">
						{sheet?.length > 0
							? sheet.map((row, idx) => {
									return (
										<tr key={idx} className="bg-white">
											{cols.map((key) => (
												<td
													title={row[key]}
													key={key}
													className="
													ff-urban
													p-3 text-sm
													font-semibold
													tracking-wide
												  text-gray-700"
												>
													{renderColsAsShort(row[key])}
												</td>
											))}
											<td
												className="ff-urban float-right p-3
                    		text-sm tracking-wide text-gray-700"
											>
												<button
													type="button"
													onClick={() =>
														handleClickActionButton("settings", row)
													}
													className="text-white-0 whitespace-nowrap rounded-full border-2
                  				border-transparent bg-gray-100 px-2 py-2 font-medium tracking-wide
                    		text-dark-gunmetal hover:border-dark-cyan hover:text-dark-cyan"
												>
													{/* All Settings */}
													<RiFolderSettingsFill size={19} />
												</button>
											</td>
										</tr>
									);
							  })
							: null}
					</tbody>
				</table>
			</div>
		</div>
	);
}

// export default React.memo(DataTableView);
export default DataTableView;
