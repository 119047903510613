import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { dropdownMenu } from "assets/data/header.dto";

/**
 * collapse menu dropdown component
 * @param {*} param0
 * @returns
 */

function LinkCollapseDropdown({ item, onPressed }) {
	const { services } = dropdownMenu;
	const { categories } = services;
	const [collapse, setCollapse] = useState(true);
	const collapseStyles = collapse ? "max-h-0" : "max-h-[300px]";
	return (
		<>
			<button
				type="button"
				onClick={() => setCollapse(!collapse)}
				className="ff-urban-0 relative inline-flex w-full items-center
				justify-between border-b border-gray-100 px-4 py-4 text-lg
				font-normal capitalize tracking-wide text-arsenic-800
				"
			>
				{item.href === "services" ? "all services" : item.name}
				{!collapse ? <VscChevronUp size={16} /> : <VscChevronDown size={16} />}
			</button>
			<div
				className={`${collapseStyles} w-full
				overflow-hidden bg-orange-50 transition-all
				duration-300 ease-in-out `}
			>
				{categories.map((item, index) => (
					<NavLink
						key={index}
						to={item.path}
						onClick={onPressed}
						className="
						mb-1 inline-flex w-full py-2 px-4 text-base
						tracking-wide text-orange-800 hover:text-orange-600"
					>
						{item.text}
					</NavLink>
				))}
			</div>
		</>
	);
}

/**
 * collapse menu dropdown component :end
 */

function MenuSmallScreen({ isOpen, navigations, scrolling, onHide }) {
	const toggleStyles = isOpen ? "block lg:hidden" : "hidden";
	const scrollStyles = scrolling ? "top-[4.21rem]" : "top-28";

	return (
		<div
			className={`
			${toggleStyles}
			${scrollStyles}
		 	absolute left-0 z-[998]
			h-screen w-full border-t
			bg-white`}
		>
			<nav className="top-20 flex flex-col items-center">
				{navigations.map((nav, index) => {
					if (nav.type === "dropdown") {
						return (
							<LinkCollapseDropdown key={index} item={nav} onPressed={onHide} />
						);
					}
					return (
						<NavLink
							key={index}
							to={nav.href}
							onClick={onHide}
							className="
							inline-flex
							w-full border-b
							border-gray-100
							px-4 py-4 text-lg
							font-normal capitalize
							tracking-wide text-arsenic-800 transition-all duration-300
							hover:border-transparent hover:bg-halloween-orange-800
						  	hover:text-white"
						>
							{nav.name}
						</NavLink>
					);
				})}
				<Link
					to="contact-us"
					onClick={onHide}
					className="
					btn btn-fill 
					mb-3 inline-flex w-full 
					items-center justify-center 
					text-center font-bold 
					uppercase"
				>
					talk to us
				</Link>
			</nav>
		</div>
	);
}

export default React.memo(MenuSmallScreen);
