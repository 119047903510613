import React from "react";
import SEO from "components/shared/seo";
import { aiDevelopment } from "assets/data/service.dto";
import { allServices as Hero } from "assets/data/hero.dto";
import HeroBasic from "components/shared/heros/basic/HeroBasic";
import CaseStudiesExamples from "components/shared/case-studies/CaseStudiesExamples";
import ServiceOfProcess from "./components/ServiceOfProcess";
import TechServiceStack from "./components/TechServiceStack";
import SolidFoundation from "./components/FloatLeftTextContent";
import CustomerExperience from "./components/FloatRightTextContent";
import "./AllService.styles.css";

function ArtificialIntelligencePage() {
	const { artificial } = Hero;
	const { powerofAI, helpAIToBusiness, aiProcess, techStack, caseStudies } =
		aiDevelopment;

	return (
		<>
			<SEO
				link="artificial-intelligence"
				postfixSlug="true"
				noIndex={false}
				title="AI Services - Einteger AI - AI Strategy Consulting and Product Engineering"
				description="At Einteger AI, we specialize in providing AI strategy consulting, AI product engineering, and research services. Our team of data scientists, AI engineers, and software architects work with businesses to develop customized solutions that leverage the power of AI to drive growth and success. Contact us to learn more about how we can help you invest in the right scalable solutions and services."
				keywords="AI services, AI strategy consulting, AI product engineering, research, data scientists, AI engineers, software architects, business growth, artificial intelligence, Einteger AI."
			/>
			<HeroBasic {...artificial} />
			<SolidFoundation {...powerofAI} />
			<CustomerExperience {...helpAIToBusiness} />
			<ServiceOfProcess {...aiProcess} />
			<TechServiceStack {...techStack} />
			<CaseStudiesExamples {...caseStudies} />
		</>
	);
}

export default ArtificialIntelligencePage;
