import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import MessageOrProcessing from "components/form/message-or-processing";
import S3FileUpload from "components/shared/uploader/S3FileUpload";
import {
	createBlogLink,
	deleteBlogLink,
	updateBlogLink,
	uploadFileActionEvent,
} from "services/all-admin-service";

import "../../Admin.styles.css";

function AddBloggerForm({
	form = "insert",
	exclusive = {},
	onAfterFormActionEnd,
}) {
	const [published, setPublished] = useState(1);
	const [srcfile, srcFile] = useState(null);

	const [uuid, setUUID] = useState(null);
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	useEffect(() => {
		if (!_.isEmpty(exclusive)) {
			setUUID(exclusive?.id);
			setValue("title", exclusive?.title);
			setValue("publicLink", exclusive?.publicLink);
			setPublished(exclusive?.published);
		}
	}, [exclusive]);

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	function resetForm() {
		reset();
		srcFile(null);
	}

	async function fileUploadWithUrl(uploadUrl) {
		let hasuploaded = false;
		if (uploadUrl) {
			const fileResp = await uploadFileActionEvent(uploadUrl, srcfile);
			const { uploadfile, uploaderr } = fileResp;
			if (uploadfile && uploadfile?.status === 200) {
				hasuploaded = true;
			} else if (uploaderr) {
				hasuploaded = false;
			}
		}

		return hasuploaded;
	}

	async function onSubmit(data) {
		let resp;

		const fileToUpload = {
			fileName: srcfile?.name,
			contentType: "blogs",
			fileExt: srcfile?.type?.split("/")[1],
		};

		if (_.isNull(srcfile) && form === "insert") {
			window.alert("Missing image file, please upload an image.");
		} else if (_.isEmpty(errors) && !_.isEmpty(data)) {
			updateFormState(true, false, false);

			if (form === "insert") {
				resp = await createBlogLink({
					...data,
					...fileToUpload,
					published,
				});
			} else if (form === "advance") {
				resp = await updateBlogLink({
					...data,
					...fileToUpload,
					id: uuid,
					published,
					nofile: _.isNull(srcfile),
				});
			} else {
				console.debug("Not form action :", form);
			}

			if (resp.response?.status === 200) {
				const { data = {} } = resp.response;
				if (fileUploadWithUrl(data.uploadUrl)) {
					resetForm();
					updateFormState(false, true, false, "success");
					onAfterFormActionEnd();
				} else {
					updateFormState(false, false, true, "Could not upload file");
				}
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	async function handleClickConfirm() {
		const confirm = window.confirm(
			"Are you sure you want to delete everything?",
		);

		if (confirm) {
			updateFormState(true, false, false);
			const resp = await deleteBlogLink(uuid);

			if (resp.response?.status === 200) {
				updateFormState(false, true, false, "deleted!");
				onAfterFormActionEnd();
				resetForm();
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	return (
		<>
			{/* FORM ALERTS! */}
			<MessageOrProcessing {...formState} />
			<form
				className="ff-urban dashboard-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="dash-input-group-full">
					<label className="input-lbl" htmlFor="title">
						headline / blog name
					</label>
					<input
						id="title"
						type="text"
						name="title"
						autoComplete="off"
						placeholder=""
						className="input-feild-dark"
						{...register("title", {
							required: "Headline or title is required.",
						})}
						onKeyUp={() => trigger("title")}
					/>
					<span className="form-input-error-text">{errors.title?.message}</span>
				</div>
				<div className="dash-input-group-full">
					<label className="input-lbl" htmlFor="publicLink">
						public link URL
					</label>
					<small className="text-orange-900">
						This link should be your path of URL Ex : &rdquo;the my blog
						2023&rdquo;
					</small>
					<input
						id="publicLink"
						type="text"
						name="publicLink"
						autoComplete="off"
						placeholder=""
						className="input-feild-dark"
						{...register("publicLink", {
							required: "Public link url is required.",
						})}
						onKeyUp={() => trigger("publicLink")}
					/>
					<span className="form-input-error-text">
						{errors.publicLink?.message}
					</span>
				</div>

				{/* File uploader */}
				<div className="mx-4 mt-4 mb-8 w-full">
					<S3FileUpload
						previewFileUrl={exclusive?.fileUrl}
						setFile={(file) => srcFile(file)}
					/>
				</div>

				<div className="ml-4 flex w-full items-center gap-2">
					<input
						type="checkbox"
						className="text-yankees-blue-900 focus:ring-0"
						checked={published === 1}
						onChange={() => setPublished(published === 1 ? 0 : 1)}
					/>
					<span className="text-base tracking-wide">
						Yes, want to publish this system information.
					</span>
				</div>

				{/* submit button */}

				{formState.processing ? (
					<div className="mt-4 flex w-full items-center  text-dark-cyan">
						<CgSpinner size={30} className="mr-2 animate-spin" />
						<span className=" text-sm tracking-wide"> Saving now...</span>
					</div>
				) : (
					<div
						className="mx-4 mt-8 flex w-full
					 items-center justify-between
					 space-x-4"
					>
						{form !== "advance" ? (
							<button
								onClick={resetForm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
							rounded bg-dark-jungle py-2.5 px-3 text-[15px]
          					font-medium capitalize tracking-wider text-white"
							>
								cancel
							</button>
						) : (
							<button
								onClick={handleClickConfirm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
							rounded bg-dark-jungle/40 py-2.5 px-3 text-[15px]
          					font-medium capitalize tracking-wider text-white"
							>
								delete blog infomation
							</button>
						)}

						<button
							type="submit"
							className="ff-urban inline-block w-full
							cursor-pointer whitespace-nowrap rounded bg-dark-cyan py-2.5 px-3
							text-[15px] font-medium capitalize tracking-wider text-white"
						>
							{form !== "advance"
								? "save blog information"
								: "update blog information"}
						</button>
					</div>
				)}
			</form>
		</>
	);
}

export default AddBloggerForm;
