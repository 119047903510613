import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";

function AdminDashboardPage() {
	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div
						className="content-header block flex-col items-start justify-between lg:flex
					  lg:flex-row lg:items-center"
					>
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								dashboard
							</h3>
							<p
								className="ff-urban max-w-full py-2 text-sm
							tracking-wider text-dark-jungle/60 lg:max-w-[80%] lg:text-base"
							>
								Dashboard is a way of displaying various types of visual data in
								one place.
							</p>
						</div>
						<div className="top-right">
							<div className="actions">
								<button
									type="button"
									className="inline-flex items-center rounded
									 bg-dark-cyan px-3 py-2 text-[15px]
									  tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<AiOutlineFileSearch size={19} className="text-white" />
									<span className="ff-urban ml-1">
										Generate reports in access
									</span>
								</button>
							</div>
						</div>
					</div>
					{/* END HEADER */}
					<div className="content-panel mt-12 rounded-xl bg-white p-6">
						<div
							className="summary-box my-8 grid grid-cols-1 gap-4
						 md:grid-cols-2 lg:gap-6 xl:grid-cols-4 2xl:gap-8"
						>
							{Array.from(Array(4).keys()).map((_, index) => (
								<div key={index} className=" bg-gray-50 px-8 py-4">
									<div className="title-meta">
										<h4 className="text-lg font-semibold text-dark-jungle">
											Admin
										</h4>
										<p
											className="ff-urban py-1
										text-sm tracking-wide text-dark-jungle/75"
										>
											Total system users & developers
										</p>
									</div>
									<div className="stats mt-4 flex w-full justify-between">
										<div className="avg">
											<span className="text-6xl font-bold text-dark-cyan">
												20
											</span>
										</div>
										<ul className="points mr-4 space-y-2">
											<li className="text-sm capitalize text-gray-500 ">
												4 super admin
											</li>
											<li className="text-sm capitalize text-gray-500 ">
												6 admin
											</li>
											<li className="text-sm capitalize text-gray-500 ">
												10 users
											</li>
										</ul>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default AdminDashboardPage;
