import React from "react";

import { help } from "assets/data/contact.dto";
import { trustedByList } from "assets/data/home.dto";

function ContactOtherInfo() {
	const { para, title } = help;
	return (
		<div className="other-info-wrapper w-full p-3 lg:p-9 xl:p-12">
			<div className="top-block mb-6">
				<h1
					className="mb-6 text-2xl font-semibold capitalize
       		      text-arsenic-900 lg:text-3xl xl:text-4xl"
				>
					{title}
				</h1>
				<p
					className="
					ff-urban 
					text-base 
					tracking-wide
				  text-arsenic-700"
				>
					{para}
				</p>
			</div>

			{/* TODO Topic */}

			<div className="trusted-block mt-12 hidden md:block">
				<h4 className="py-4 uppercase text-halloween-orange-900">trusted by</h4>
				<div className="flex items-center">
					{trustedByList.map((trust, index) => (
						<div key={index}>
							<div
								className="
								flex
             					items-center
								justify-center 
								opacity-60"
							>
								<img
									className="
									h-[120px]
									w-auto
									max-w-[200px]
                     				object-contain
									object-center
									grayscale invert-[0.3]"
									src={trust.image}
									alt={trust.title}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default ContactOtherInfo;
