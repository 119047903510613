import Amplify from "aws-amplify";
import { AWS_AMPLIFY_HOST } from "constants/aws-vars-consts";

Amplify.configure({
	Auth: {
		region: AWS_AMPLIFY_HOST.REGION,
		userPoolId: AWS_AMPLIFY_HOST.AUTH_USER_POOL_ID,
		userPoolWebClientId: AWS_AMPLIFY_HOST.AUTH_WEB_CLIENT_ID,
	},
	Storage: {
		AWSS3: {
			level: AWS_AMPLIFY_HOST.S3_ACCESS_LEVEL,
			bucket: AWS_AMPLIFY_HOST.S3_BUCKET,
			region: AWS_AMPLIFY_HOST.REGION,
		},
	},
});
