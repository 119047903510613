import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

function TabColsViews({
	onChangeVacancntType,
	vacantPositions,
	navTabs,
	loading,
	currentTab,
}) {
	function renderReplaceSpaceWithLowercase(name = "") {
		const text = name.replace(/\s+/g, "-").toLowerCase();
		return text.replaceAll("/", "or");
	}

	return (
		<div className="table-wrapper mx-auto hidden border-0 lg:block">
			<div className="w-full px-0 lg:px-24 xl:px-32">
				<div className="table-nav-wrapper bg-white">
					<div className=" nav-buttons flex h-20 flex-nowrap whitespace-nowrap">
						{navTabs.map((type, index) => (
							<button
								type="button"
								onKeyPress={() => onChangeVacancntType(type)}
								onClick={() => onChangeVacancntType(type)}
								key={index}
								className={`tab-nav-button ff-urban flex-1 cursor-pointer px-2
								py-4 text-base capitalize tracking-normal transition-all duration-300
							  hover:text-yankees-blue-800 xl:text-lg ${
									type.key === currentTab
										? "bg-white text-arsenic-900"
										: "bg-yankees-blue-600 text-arsenic-100"
								}`}
							>
								{type.value}
							</button>
						))}
					</div>
				</div>
				<div className="table-row-wrapper ff-urban-0 space-y-3 bg-white p-4 shadow-lg">
					{loading ? <FbSrcLoading /> : null}
					{_.isEmpty(vacantPositions) ? (
						<div className="w-full p-4 text-center text-lg">
							No Positions Available
						</div>
					) : (
						vacantPositions.map((vacancy, index) =>
							vacancy.published === 1 ? (
								<div
									key={index}
									className="row flex items-center justify-between bg-yankees-blue-50 p-4 px-6"
								>
									<span className="text-base tracking-wide text-arsenic-600 xl:text-lg">
										{vacancy.title}
									</span>
									<Link
										to={`/careers/${renderReplaceSpaceWithLowercase(
											vacancy.title,
										)}`}
										state={{ career: vacancy }}
										className="rounded-full bg-gradient-to-r from-[#de6262] to-[#ffb88c] px-4
                  py-2 text-sm font-semibold capitalize tracking-wider text-white hover:animate-pulse"
									>
										apply now
									</Link>
								</div>
							) : null,
						)
					)}
				</div>
			</div>
		</div>
	);
}

export default React.memo(TabColsViews);
