import React from "react";
import SEO from "components/shared/seo";
import OurStory from "pages/landing/components/OurStory";
import OurSprit from "./components/OurSpirit";
import TopBanner from "./components/TopBanner";
import VisionAndMission from "./components/VisionAndMission";
import CustomerFootprint from "./components/CustomerFootprint";
import CompanyStats from "./components/CompanyStats";
import OurCulture from "./components/OurCulture";
import ApplyNow from "./components/ApplyNow";

import "./About.styles.css";

function AboutUsPage() {
	return (
		<>
			<SEO
				link="about-us"
				postfixSlug="true"
				noIndex={false}
				title="Custom Technology Solutions for Businesses"
				description="Learn about Einteger Solutions, a software development company that specializes in providing custom technology solutions for businesses. Our experienced team offers a range of services including software development, product development, artificial intelligence, and software QA. We are dedicated to helping businesses grow and succeed through innovative technology solutions."
				keywords="About Us, Einteger Solutions, software development, custom technology solutions, business growth, innovative solutions, artificial intelligence, software QA"
			/>
			<TopBanner />
			<CompanyStats />
			<CustomerFootprint />
			<OurSprit />
			<VisionAndMission />
			<OurStory />
			<OurCulture />
			<ApplyNow />
		</>
	);
}

export default AboutUsPage;
