import App from "App";
import React from "react";
import ErrorBoundary from "./ErrorBoundary";

function Root() {
	const redirectMessage = "Try Again";
	const redirectUrl = "/";
	return (
		<ErrorBoundary redirectMessage={redirectMessage} redirectUrl={redirectUrl}>
			<App />
		</ErrorBoundary>
	);
}

export default Root;
