import React, { useEffect, useRef } from "react";
import Portal from "components/Portal";

function PopupWizardOverlay({ isOpen, children, handleClickOverlay }) {
	const modalRef = useRef();

	useEffect(() => {
		const cssClass = "body-scroll-none";
		const hasClass = document.body.classList?.contains(cssClass);
		if (isOpen && !hasClass) {
			document.body.classList.add(cssClass);
		} else if (!isOpen && hasClass) {
			document.body.classList.remove(cssClass);
		}
	}, [isOpen]);

	useEffect(() => {
		document.addEventListener("mousedown", function (event) {
			if (
				!modalRef.current?.contains(event.target) &&
				event.target?.id !== "overlay-has-scroll"
			) {
				handleClickOverlay();
			}
		});
	}, []);

	// if (!isOpen) return;

	return (
		<Portal>
			<div
				className={`fixed top-0 left-0 z-[999] h-full w-full bg-black/75
				transition-all duration-300 ease-in-out ${
					isOpen ? "visible opacity-100" : "invisible opacity-0 "
				}`}
			>
				<div
					id="overlay-has-scroll"
					className="overlay-inner h-full w-full overflow-y-auto"
				>
					<div id="overlay-modal-container" className="relative h-full w-full ">
						<div
							ref={modalRef}
							className={`${
								isOpen ? "scale-100" : "scale-95"
							} inner-container absolute
							left-1/2 top-0 -translate-x-1/2 transform transition-all duration-300
							ease-in-out`}
						>
							{children}
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}

export default React.memo(PopupWizardOverlay);
