import _ from "lodash";
import React, { useState, useEffect } from "react";
import { MdBookmarkAdd, MdNoteAdd } from "react-icons/md";

import {
	getAllPortfolios,
	getEmbedCaptionByPortfolioId,
	getPortfolioById,
} from "services/all-admin-service";
import PopupWizardOverlay from "components/shared/popup-wizard";
import AddPortfolioForm from "../components/form/AddPortfolioForm";
import GerneralView from "../components/portfolio/GerneralView";
import FormPopupWizard from "../components/FormPopupWizard";
import DataTableView from "../components/DataTableView";
import ExclusiveSetting from "../components/ExclusiveSetting";
import AddPortfolioCaptionForm from "../components/form/AddPortfolioCaptionForm";
import { tblportfolio } from "../admin.data";

const modalInitialState = { modal: null, state: false };

function AdminPortfolioPage() {
	const [viewModal, setViewModal] = useState(modalInitialState);
	const [tableSheet, setTableSheet] = useState([]);
	const [openRow, setOpenRow] = useState(null);

	function handleUpdatePage(request) {
		console.debug(request);
	}

	async function onLoadTableSheet() {
		const res = await getAllPortfolios();
		if (res?.response) {
			const { response } = res;
			setTableSheet(response.data);
		}
	}

	useEffect(() => {
		onLoadTableSheet();
	}, []);

	const fetchWithPromiseAll = async (id) => {
		const portfolioPromise = getPortfolioById(id);
		const captionPromise = getEmbedCaptionByPortfolioId(id);

		const [portfolio, captions] = await Promise.all([
			portfolioPromise,
			captionPromise,
		]);

		if (portfolio.response && captions.response) {
			const responseBody = {
				portfolio: portfolio.response?.data || {},
				embedCaptions: captions.response?.data || [],
			};
			setOpenRow(responseBody);
		} else if (portfolio.err || captions.err) {
			console.log(portfolio.err);
			console.log(captions.err);
		}
	};

	async function getRowById(id = null) {
		if (id === null) return;

		fetchWithPromiseAll(id);
	}

	function reloadTableSheet() {
		onLoadTableSheet();
	}

	function handleModal(modal, row = null) {
		if (!_.isNil(row)) {
			getRowById(row.id);
		}
		setViewModal((prev) => ({
			modal,
			state: !prev.state,
		}));
	}

	function modalHead(modal = "") {
		let title = null;

		if (modal === "insert") {
			title = "add new portfolio thumbnail";
		} else if (modal === "insert-caption") {
			title = "add portfolio additional caption";
		} else {
			title = "exclusive setting portfolio";
		}

		return title;
	}

	function handleExitModal() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
	}

	function handleAfterFormActionEnd() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
		// reload table-sheet
		setTimeout(() => reloadTableSheet(), 3000);
	}

	// const onlyPortfolioCaptions = React.memo(() => {
	// 	return openRow?.embedCaptions;
	// }, [openRow]);

	// const onlyPortfolioDefault = useMemo(() => {
	// 	return openRow?.portfolio;
	// }, [openRow]);

	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div className="content-header block items-center justify-between lg:flex">
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								Portfolio / publish Portfolio
							</h3>

							<p
								className="ff-urban max-w-full py-2
								text-base tracking-wider text-dark-jungle/70 lg:max-w-[80%] xl:lg:max-w-[60%]"
							>
								A portfolio is a living and changing collection of records that
								reflect your accomplishments, skills, experiences, and
								attributes.
							</p>
						</div>
						<div className="top-right">
							<div className="actions flex items-center gap-4">
								<button
									type="button"
									onClick={() => handleModal("insert-caption")}
									className="inline-flex items-center whitespace-nowrap
							 		rounded bg-dark-cyan px-3 py-2 text-[15px]
									tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<MdBookmarkAdd size={19} className="text-white" />
									<span className="ff-urban ml-1">Link / Attach captions</span>
								</button>
								<button
									type="button"
									onClick={() => handleModal("insert")}
									className="inline-flex items-center whitespace-nowrap
							 		rounded bg-dark-cyan px-3 py-2 text-[15px]
									tracking-wider text-white hover:bg-dark-cyan/75"
								>
									<MdNoteAdd size={19} className="text-white" />
									<span className="ff-urban ml-1">Generate new portfolio</span>
								</button>
							</div>
						</div>
					</div>
					{/* END HEADER */}

					<DataTableView
						onrefresh={reloadTableSheet}
						handleClickActionButton={handleModal}
						handlePageNavsClick={handleUpdatePage}
						theadCols={tblportfolio.headings}
						sheet={tableSheet}
						cols={[
							"id",
							"title",
							"subtitle",
							"subject",
							"published",
							"createdAt",
						]}
					/>
					{/* content-Panel box end */}
				</div>
			</div>

			{/* Modal popup modal */}
			<PopupWizardOverlay
				isOpen={viewModal.state}
				handleClickOverlay={() => console.debug("overlay")}
			>
				<FormPopupWizard
					onExit={handleExitModal}
					title={modalHead(viewModal.modal)}
				>
					{viewModal.modal === "insert" ? (
						<AddPortfolioForm
							form="insert"
							onAfterFormActionEnd={handleAfterFormActionEnd}
						/>
					) : null}
					{viewModal.modal === "insert-caption" ? (
						<AddPortfolioCaptionForm
							form="insert-caption"
							dataList={tableSheet}
							onAfterFormActionEnd={handleAfterFormActionEnd}
						/>
					) : null}
					{viewModal.modal === "settings" ? (
						<ExclusiveSetting
							tabsviews={[
								<GerneralView exclusive={openRow} />,
								<AddPortfolioForm
									form="advance"
									exclusive={openRow?.portfolio}
									onAfterFormActionEnd={handleAfterFormActionEnd}
								/>,
								<AddPortfolioCaptionForm
									form="advance"
									dataList={openRow?.embedCaptions}
									exclusive={openRow?.portfolio}
									onAfterFormActionEnd={handleAfterFormActionEnd}
								/>,
							]}
						/>
					) : null}
				</FormPopupWizard>
			</PopupWizardOverlay>
		</section>
	);
}

export default AdminPortfolioPage;
