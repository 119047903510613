import React from "react";

function PrivacyPolicyPage() {
	return (
		<section className="faq relative h-full min-h-[90vh] w-full md:min-h-[70vh]">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="content-wrapper h-full w-full pb-16 lg:my-24">
					<div className="page-headline mt-12 mb-12 block w-full text-center lg:mt-0">
						<h1 className="text-2xl font-medium lg:text-4xl">Privacy Policy</h1>
					</div>
					{/* SECTION #1 */}
					<div className="section-container p-3 md:p-4 xl:p-6 ">
						<h5 className="ff-urban pb-6 text-lg font-bold text-yankees-blue-900">
							Why do we process your data?
						</h5>
						<div className="infos ff-sanspro text-sm text-yankees-blue-700 md:text-base">
							<p className="w-full max-w-[1024px]">
								We will only process your personal data where we have a legal
								basis to do so. The legal basis will depend on the reasons we
								have collected and need to use your personal data for. We may
								process your personal data because of..
							</p>
							<ul className="list-disc p-8">
								<li>you have consented to us using your personal data</li>
								<li>we need to comply with a legal obligation</li>
								<li>it is in our legitimate business interests</li>
							</ul>
							<p className="w-full max-w-[1024px]">
								More specifically, personal data submitted to us through this
								website and personal data we collected from you or third parties
								or from public sources will be used for the purposes based on
								the legal grounds as follows ...
							</p>
							<ul className="list-disc p-8">
								<li>
									Communications, based on Intellectsoft legitimate interest
								</li>
								<li>Marketing and public relations, based on your consent</li>
								<li>Recruitment</li>
								<li>
									Business relations, based on Intellectsoft legitimate interest
								</li>
								<li>
									Corporate and investor relations, based on Intellectsoft
									compliance obligations
								</li>
								<li>Security, based on Intellectsoft legitimate interest</li>
							</ul>
						</div>
					</div>

					{/* SECTION #2 */}
					<div className="section-container p-3 md:p-4 xl:p-6 ">
						<h5 className="ff-urban pb-6 text-lg font-bold text-yankees-blue-900">
							What information do we process?
						</h5>
						<div className="infos ff-sanspro text-sm text-yankees-blue-700 md:text-base">
							<p className="w-full max-w-[1024px]">
								Here is a complete list of the personal data we may want to know
								about you..
							</p>
							<ul className="list-disc p-8">
								<li>Your name</li>
								<li>Your email</li>
								<li>Your phone number</li>
								<li>The company you work at</li>
								<li>Where is your company located</li>
								<li>Your CV</li>
								<li>Your job title</li>
								<li>The fact that you’ve visited our website</li>
								<li>
									Have you downloaded any marketing materials from our website
								</li>
							</ul>
							<p className="w-full max-w-[1024px]">
								Most of the information comes directly from you.
								<br />
								When you visit our Websites we may collect the following
								information about you:
							</p>
							<ul className="list-disc p-8">
								<li>
									information about your device(s), in particular, hardware
									model, version of the operating system;
								</li>
								<li>
									information about your visits to and use of this Website,
									including your IP address, geographical location, browser type
									and version, <br />
									referral source, page views and Website navigation paths
								</li>
							</ul>
						</div>
					</div>

					{/* SECTION #3 */}
					<div className="section-container p-3 md:p-4 xl:p-6 ">
						<h5 className="ff-urban pb-6 text-lg font-bold text-yankees-blue-900">
							Retention of the Data
						</h5>
						<div className="infos ff-sanspro text-sm text-yankees-blue-700 md:text-base">
							<p className="w-full max-w-[1024px]">
								We will retain your personal data only for as long as is
								necessary for the purposes set out in this Privacy Policy. We’ll
								always delete your data when we receive a request from you to do
								so. We will retain and use your personal data to the extent
								necessary to comply with our legal obligations (for example, if
								we are required to retain your data to comply with applicable
								laws), resolve disputes, and enforce our legal agreements and
								policies.
								<br />
								<br />
								The Company will also retain Usage Data for internal analysis
								purposes. Usage Data is generally retained for a shorter period
								of time, except when this data is used to strengthen the
								security or to improve the functionality of Our Service, or We
								are legally obligated to retain this data for longer time
								periods.
							</p>
						</div>
					</div>
					{/* SECTION #4 */}
					<div className="section-container p-3 md:p-4 xl:p-6 ">
						<h5 className="ff-urban pb-6 text-lg font-bold text-yankees-blue-900">
							Cookies
						</h5>
						<div className="infos ff-sanspro text-sm text-yankees-blue-700 md:text-base">
							<p className="w-full max-w-[1024px]">
								Cookies are small pieces of text that are stored on your device
								when you visit a website. During your further visits to that
								website, the information stored in the cookie is sent back to
								the site. This allows the website to recognise you and tailor
								its content to your need
								<br />
								<br />
								Functional cookies - these Cookies are essential to enable you
								to move around the Intellectsoft websites and use their
								features. These Cookies make sure you can view the websites and
								use them in a proper way. They also give you access to secured
								parts of the Intellectsoft websites.
								<br />
								<br />
								Analytical cookies and other cookies - when cookies are used to
								analyse how you use our websites and to monitor website
								performance. This allows us to identify and fix any issues that
								arise quickly. For example, we use Google Analytics cookies to
								keep track of which pages are the most popular and to
								&ldquo;remember&ldquo; what a user has done visiting other pages
								on the website.
								<br />
								<br />
								Third-party cookies - these Cookies help third parties to help
								track and manage the effectiveness of, for example, their
								websites, ads, and number of visitors. More information about
								these Cookies may be available on the relevant third party’s
								website.
							</p>
						</div>
					</div>

					{/* SECTION #5 */}
					<div className="section-container p-3 md:p-4 xl:p-6 ">
						<h5 className="ff-urban pb-6 text-lg font-bold text-yankees-blue-900">
							Security of your personal data
						</h5>
						<div className="infos ff-sanspro text-sm text-yankees-blue-700 md:text-base">
							<p className="w-full max-w-[1024px]">
								The security of your personal data is important to Us,Therefore
								we don’t allow any unreliable practices regarding the security
								of the information we deal with. Consequently, we take all
								appropriate technical and organisational measures to keep your
								data safe, While We strive to use commercially acceptable means
								to protect your personal data, We cannot guarantee its absolute
								security.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PrivacyPolicyPage;
