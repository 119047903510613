import React from "react";
import { useNavigate } from "react-router-dom";
import "../Hero.styles.css";

function HeroBasic(props) {
	const { tagname, title, para, cta, image } = props;
	const navigation = useNavigate();

	return (
		<section className="hero-basic-section w-ful h-full bg-gray-50 lg:min-h-[60vh]">
			<div className="container z-[1] mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="basic-hero-wrapper relative mt-0 h-full w-full lg:mt-6 xl:mt-7 2xl:mt-14">
					<div className="basic-container mb-6 flex w-full flex-col items-center justify-between md:flex-row lg:mb-12">
						<div className="my-8 border-0 text-arsenic-900">
							<h4
								className="ff-sanspro block py-0 text-lg
								font-semibold capitalize text-orange-700"
							>
								{tagname}
							</h4>

							<div className="content max-w-[90%] xl:max-w-[65%]">
								<h1
									className="block pt-4 text-3xl
							 		lg:text-4xl lg:leading-[3rem] xl:text-4xl
									xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
								>
									{title}
								</h1>
								<p
									className="ff-urban block pb-6 pt-4 text-sm
												font-medium leading-[1.6rem] tracking-normal
												text-arsenic-600 lg:my-4 lg:pt-0 xl:leading-[1.8rem]
												2xl:text-lg 2xl:leading-[1.8rem]"
								>
									{para}
								</p>
							</div>
							<button
								type="button"
								onClick={() => navigation("/contact-us")}
								className="ff-urbanx btn btn-outline flex items-center
								gap-4 font-medium hover:animate-pulse"
							>
								{cta.label}
								{cta.icon}
							</button>
						</div>
						{/* Photo card container */}
						<div className="relative float-right">
							<div
								className="
								bshero-image-box
								mb-8
								h-[375px]
								w-[300px] p-4 md:mb-0 md:p-8
								lg:h-[425px] lg:w-[360px]
								xl:h-[480px] xl:w-[400px]
								xl:pb-12 2xl:p-0"
							>
								<img
									src={image}
									alt={tagname}
									className="bshero-cover-img
									z-10 h-full w-full border-8
									border-white object-cover object-center ring-4
									ring-gray-400 ring-offset-4"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HeroBasic;
