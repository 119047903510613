import React from "react";
import SEO from "components/shared/seo";
import LoadingOverlay from "components/shared/fallback-spin/LoadingOverlay";
import HeroSwiper from "components/shared/heros/swiper/HeroSwiper";
import ReadyContactUs from "components/shared/ready-to-contact/ReadyContactUs";
import TrustedByList from "./components/TrustedByList";
// import Development from "./components/Development";
// import ViableProducts from "./components/ViableProducts";
// import OurStory from "./components/OurStory";
// import Testimonials from "./components/Testimonials";
// import TeamMembersNoAvatar from "./components/TeamMembersNoAvatar";
// import TeamMembersList from "./components/TeamMembersList";

const LazyOurStory = React.lazy(() => import("./components/OurStory"));
const LazyTestimonials = React.lazy(() => import("./components/Testimonials"));
const LazyTeamMembersNoAvatar = React.lazy(() =>
	import("./components/TeamMembersNoAvatar"),
);
const LazyDevelopment = React.lazy(() => import("./components/Development"));
const LazyViableProducts = React.lazy(() =>
	import("./components/ViableProducts"),
);

function LandingPage() {
	return (
		<>
			<SEO
				link="/"
				postfixSlug="true"
				noIndex={false}
				title="Custom Software Development for Business Growth"
				description="Einteger Solutions is a leading software development company that specializes in creating custom solutions for businesses. Our services include product development, MVP creation, and legacy system modernization. We have a team of experienced developers who can help your business achieve its goals. Browse our website to learn more about our solutions, team, and case studies."
				keywords="software development, custom solutions, business growth, product development, MVP, legacy system modernization, Einteger Solutions, experienced developers, case studies"
			/>
			<HeroSwiper />
			<TrustedByList />
			<React.Suspense fallback={<LoadingOverlay />}>
				<LazyDevelopment />
				<LazyViableProducts />
				<LazyTeamMembersNoAvatar />
				<LazyOurStory />
				<LazyTestimonials />
				<ReadyContactUs />
			</React.Suspense>
		</>
	);
}

export default LandingPage;
