import axios from "axios";
import { Auth } from "aws-amplify";
import httpAdapter from "axios/lib/adapters/http";

const APP_HOST_API_PREFIX = process.env.REACT_APP_API_PREFIX;
const NO_AUTH_CHECK_URL = [
	"https://es-plat-be-prod-contents-resources.s3.us-east-2.amazonaws.com",
	"https://es-plat-be-dev-contents-resources.s3.us-east-2.amazonaws.com",
	"/careers/upload",
	"/subscriptions",
];

export const instance = axios.create({
	baseURL: APP_HOST_API_PREFIX,
	withCredentials: false,
	adapter: httpAdapter,
	headers: {},
});

instance.interceptors.request.use(
	async (requestConfig) => {
		const tokenHeader = "Authorization";

		if (
			requestConfig.method !== "get" &&
			!requestConfig.url.includes(NO_AUTH_CHECK_URL[0]) &&
			!requestConfig.url.includes(NO_AUTH_CHECK_URL[1]) &&
			!requestConfig.url.includes(NO_AUTH_CHECK_URL[2]) &&
			!requestConfig.url.includes(NO_AUTH_CHECK_URL[3]) &&
			requestConfig.headers &&
			!(tokenHeader in requestConfig.headers)
		) {
			const authenticated = await Auth.currentAuthenticatedUser();
			if (authenticated) {
				const token = await Auth.currentSession();
				if (token) {
					requestConfig.headers[tokenHeader] = `${token.accessToken.jwtToken}`;
				}
			}
		}
		return requestConfig;
	},
	(error) => {
		return Promise.reject(error);
	},
);

// check the endpoint response

instance.interceptors.response.use(
	(response) => {
		// Todo manupulate the response data
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			return Promise.reject("401:Unauthorized");
		}
		if (error.response?.status === 403) {
			return Promise.reject("403:Forbidden");
		}
		if (error.response?.status === 500) {
			return Promise.reject("500:Server Error");
		}
		return Promise.reject(error);
	},
);

class ApiService {
	get(path, { params = {}, headers = {} } = {}) {
		return new Promise((resolve, reject) => {
			instance
				.get(path, {
					headers,
					params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}

	post(path, { params = {}, data = {}, headers = {} } = {}) {
		return new Promise((resolve, reject) => {
			instance
				.post(path, data, {
					headers,
					params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}

	put(path, { params = {}, data = {}, headers = {} } = {}) {
		return new Promise((resolve, reject) => {
			instance
				.put(path, data, {
					headers,
					params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}

	patch(path, { params = {}, data = {}, headers = {} } = {}) {
		return new Promise((resolve, reject) => {
			instance
				.patch(path, data, {
					headers,
					params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}

	del(path, { params = {}, headers = {} } = {}) {
		return new Promise((resolve, reject) => {
			instance
				.delete(path, {
					headers,
					params,
				})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => reject(err));
		});
	}
}

export default new ApiService();
