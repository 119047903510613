// export const APPS_ENDPOINT = `${API_PREFIX}/apps`;
// const BASE_ENDPOINT_URL = "http://localhost:1337/api/v1";

const APIs = {
	requestSubscriptionURL: () => {
		return "/subscriptions";
	},

	fetchApplicantURL: () => {
		return "/careers";
	},

	getApplicantByIdURL: (id) => {
		return `/careers/${id}`;
	},

	delApplicantByIdURL: (id) => {
		return `/careers/${id}`;
	},

	sendApplicantURL: () => {
		return "/careers/upload";
	},

	fetchContactRequestMsgURL: () => {
		return "/contactus";
	},

	getContactRequestMsgByIdURL: (id) => {
		return `/contactus/${id}`;
	},

	sendContactRequestURL: () => {
		return "/contactus";
	},

	deleteContactRequestURL: (id) => {
		return `/contactus/${id}`;
	},

	saveOrFetchEmploymentURL: () => {
		return "/employments";
	},
	getEmploymentByIdURL: (id) => {
		return `/employments/${id}`;
	},
	getEmploymentByGroupNameURL: (name) => {
		return `/employments/${name}`;
	},

	delOrUpdateEmploymentURL: (id) => {
		return `/employments/${id}`;
	},

	// ------------------Testimonials ----------------------------

	fetchTestimonialsURL: () => {
		return "/testimonials";
	},

	saveWithUploadTestimonialURL: () => {
		return "/testimonials/upload";
	},

	deleteTestimonialURL: (id) => {
		return `/testimonials/${id}`;
	},

	getTestimonialByIdURL: (id) => {
		return `/testimonials/${id}`;
	},

	updateOnlyAttrTestimonialURL: (id) => {
		return `/testimonials/${id}`;
	},

	updateWithFileTestimonialURL: () => {
		return "/testimonials/upload";
	},

	// ------------------Events------------------------------
	fetchEventsURL: () => {
		return "/events";
	},

	saveWithUploadEventURL: () => {
		return "/events/upload";
	},

	deleteEventByIdURL: (id) => {
		return `/events/${id}`;
	},

	// ------------------Blogs------------------------------
	fetchBlogURL: () => {
		return "/blogs";
	},

	saveWithUploadBlogURL: () => {
		return "/blogs/upload";
	},

	getBlogByIdURL: (id) => {
		return `/blogs/${id}`;
	},

	updateOnlyAttrBlogURL: (id) => {
		return `/blogs/${id}`;
	},

	updateWithFileBlogURL: () => {
		return "/blogs/upload";
	},

	deleteBlogURL: (id) => {
		return `/blogs/${id}`;
	},

	// ------------------Portfolio------------------------------

	fetchPortfolioURL: () => {
		return "/portfolios";
	},

	saveWithUploadPortfolioURL: () => {
		return "/portfolios/upload";
	},

	getPortfolioByIdURL: (id) => {
		return `/portfolios/${id}`;
	},

	updateOnlyAttrPortfolioURL: (id) => {
		return `/portfolios/${id}`;
	},

	updateWithFilePortfolioURL: () => {
		return "/portfolios/upload";
	},

	deletePortfolioURL: (id) => {
		return `/portfolios/${id}`;
	},

	// *** 2222222222222222222 embed caption-> links 222222222222222222222222222222222222222

	fetchPortfolioCaptionsURL: () => {
		return "/portfolio-captions";
	},

	getPortfolioCaptionByIdURL: (id) => {
		return `/portfolio-captions/${id}`;
	},

	getPortfolioCaptionByPorfolioIdURL: (id) => {
		return `/portfolio-captions/by-portfolio-id/${id}`;
	},

	saveWithUploadPortfolioCaptionURL: () => {
		return "/portfolio-captions/upload";
	},

	updateOnlyAttrPortfolioCaptionURL: (id) => {
		return `/portfolio-captions/${id}`;
	},

	updateWithFilePortfolioCaptionURL: () => {
		return "/portfolios-captions/upload";
	},

	deletePortfolioCaptionURL: (id) => {
		return `/portfolio-captions/${id}`;
	},
};

export default APIs;
