import React, { useEffect, useRef } from "react";
import "../head.styles.css";

function DropdownLayoutTemplate({
	isOpen,
	children,
	scrolling,
	handleClickOutEvent,
}) {
	const overlayRef = useRef();

	const toggleStyles = isOpen ? "invisible lg:visible" : "invisible";
	const positionStyles = !scrolling ? "top-[130px]" : "top-[86px]";

	useEffect(() => {
		document.addEventListener("mousedown", function (event) {
			if (
				!overlayRef.current?.contains(event.target) &&
				event.target.id !== "services-dropdown-button"
			) {
				handleClickOutEvent();
			}
		});
	}, []);

	return (
		<section
			className={`nav-dropdown-template absolute transform ${toggleStyles} ${positionStyles}
			left-0 z-[998] hidden h-full min-h-screen w-full 
      		border-t-0 bg-black/20 transition-all duration-75 lg:fixed lg:block `}
		>
			<div className="menu-container h-auto w-full bg-white" ref={overlayRef}>
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					{children}
				</div>
			</div>
		</section>
	);
}

export default React.memo(DropdownLayoutTemplate);
