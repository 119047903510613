import React from "react";
import SEO from "components/shared/seo";
import HeroBasic from "components/shared/heros/basic/HeroBasic";
import { uiDesing } from "assets/data/service.dto";
import { allServices as Hero } from "assets/data/hero.dto";
import CaseStudiesExamples from "components/shared/case-studies/CaseStudiesExamples";
import SolidFoundation from "./components/FloatLeftTextContent";
import CustomerExperience from "./components/FloatRightTextContent";
import ServiceOfProcess from "./components/ServiceOfProcess";
import TechServiceStack from "./components/TechServiceStack";
import "./AllService.styles.css";

function UIDesignPage() {
	const { uiux } = Hero;
	const {
		customerExperience,
		solidFoundation,
		designProcess,
		techStack,
		caseStudies,
	} = uiDesing;
	return (
		<>
			<SEO
				link="quality-assurance"
				postfixSlug="true"
				noIndex={false}
				title="UI/UX Design Services - User-centered Design"
				description="Einteger Solutions is a leading software development company that specializes in providing UI/UX design services. Our team of experienced designers is dedicated to conducting comprehensive research, empathizing with your users, and creating interfaces that meet their needs and goals. We use the latest technologies and techniques to deliver efficient, effective and user-centered design solutions that meet your unique needs and goals. Browse our website to learn more about our services and how we can help you achieve your business goals."
				keywords="UI/UX design, user-centered design, user research, user empathy, interface design, testing, Einteger Solutions"
			/>
			<HeroBasic {...uiux} />
			<SolidFoundation {...solidFoundation} />
			<CustomerExperience {...customerExperience} />
			<ServiceOfProcess {...designProcess} />
			<TechServiceStack {...techStack} />
			<CaseStudiesExamples {...caseStudies} />
		</>
	);
}

export default UIDesignPage;
