import scalableMVPs from "assets/images/photo/lightweight_scalable_MVPs.png";
import prodevelopment1 from "assets/images/photo/product-development1.jpg";
import prodevelopment2 from "assets/images/photo/product-development2.jpg";
import viableproduct1 from "assets/images/photo/viable-product1.jpg";
import viableproduct2 from "assets/images/photo/viable-product2.jpg";
import showcase1 from "assets/images/photo/showcase1.jpg";
import Image from "assets/images/photo/innovation_diagram.png";
import teamCover from "assets/images/photo/our-team-bg-cover3.jpg";
import TeamMemberLookAbove from "assets/images/photo/team-member-left-removebg-preview.png";

// trusted-clients-logos
import client1 from "assets/images/clients/Duca-Inglish-white.png";
import client2 from "assets/images/clients/Elizen.png";
import client3 from "assets/images/clients/EZdoc_logo.png";
import client4 from "assets/images/clients/Watersedge.png";

import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { HiOutlineArrowRight } from "react-icons/hi";

export const devlopment = {
	smTitle: "Product Development",
	lgTitle:
		"Contemporary solutions, robustly engineered for your business needs",
	para: "Our Product Development team delivers contemporary software solutions that are engineered for success.",
	ctaPath: "/product-development",
	ctaText: "Explore more",
	image: Image,
};

export const trustedByList = [
	{
		title: "Duca",
		image: client1,
	},
	{
		title: "Elizen",
		image: client2,
	},
	{
		title: "EZdoc",
		image: client3,
	},
	{
		title: "Watersedge",
		image: client4,
	},
];

export const vblproducts = {
	smTitle: "Minimum Viable Products",
	lgTitle: "Expertly constructed MVPs to get your product to market fast",
	para: "Our team has the expertise to create lightweight, scalable MVPs that allow you to test and validate your ideas without investing a lot of time and resources.",
	ctaPath: "/minimum-viable-product",
	ctaText: "Explore more",
	image: scalableMVPs,
};

export const meetTeam = {
	smTitle: "Leadership Team",
	lgTitle: "Experience the passion and expertise of our team",
	para: "Meet the talented and passionate individuals who make up our Leadership Team. Each team member brings their unique skills and experience to the table, working together to provide the best software development solutions for our clients.",
	ctaPath: "/teams",
	ctaText: "Explore more",
	image: teamCover,
	image2: TeamMemberLookAbove,

	members: [
		{
			id: 1,
			name: "Nayanajith  Premasinghe",
			title: "CEO founder of company",
			avatar: "https://www.einteger.com/wp-content/uploads/2020/01/member1.jpg",
			para: "",
		},
		{
			id: 2,
			name: "Tharanga Thennakoon",
			title: "Chief Technology Officer (CTO)",
			avatar: "https://www.einteger.com/wp-content/uploads/2020/01/member4.jpg",
			para: "",
		},
		{
			id: 3,
			name: "Sameera Rathnayake",
			title: "UI/Front-end development Specialist",
			avatar: "https://www.einteger.com/wp-content/uploads/2020/01/member7.jpg",
			para: "",
		},
		{
			id: 4,
			name: "Chathura Edirisinghe",
			title: "Mobile Development Specialist",
			avatar: "https://www.einteger.com/wp-content/uploads/2020/01/member3.jpg",
			para: "",
		},
	],

	social: [
		{
			icon: <FaFacebookF />,
			hrefURL: "https://www.facebook.com/username",
		},
		{
			icon: <BsTwitter />,
			hrefURL: "https://twitter.com/username",
		},
		{
			icon: <FaLinkedinIn />,
			hrefURL: "https://www.linkedin.com/username",
		},
	],
};

export const areYouReady = {
	title: "Ready to bring your project to life?",
	para: "Are you ready to take the next step in working with our software development company? Our Ready to Get Started? section provides all the information and resources you need to get started on your project.",
	cta1: "send us a message to connect",
	cta2: "shedule a call",
};

// Product develpment

export const prodDevelopment = {
	mainSection: {
		tagname: "Product Development",
		title: "Augment your vision with our expert software development services",
		para: "Our Product Development team is dedicated to augmenting your vision with our expertise. We use the latest technologies and techniques to deliver innovative software solutions that meet your unique needs and goals.",
		cta: {
			label: "Contact a free consultation",
			href: "/contact-us",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: prodevelopment1,
	},

	defaultSection: {
		tagname: null,
		title: "Combine your vision with our expertise for business growth",
		para: "Our Product Development team combines your vision with our expertise to create innovative software solutions that give your business a new impulse for growth. We use the latest technologies and techniques to deliver reliable, scalable solutions that meet your unique needs and goals.",
		image: prodevelopment2,
	},

	caseStudies: {
		tagname: "Project insights",
		para: "Expert software development solutions brought to life through our case studies",
		showcases: [
			{
				title: "Smart Home Solutions",
				categroy: "Smart Rent ",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage, protect and automate daily operational processes utilizing our proprietary Alloy Automation.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Spy IoT",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage, protect and automate daily operational processes utilizing our proprietary Alloy Automation.",
				image: showcase1,
			},
			{
				title: "Smart Home Solutions",
				categroy: "Health care",
				desc: "An enterprise smart home automation company developing software and hardware that empowers property owners, managers and homebuilders to effectively manage, protect and automate daily operational processes utilizing our proprietary Alloy Automation.",
				image: showcase1,
			},
		],
		cta: { path: "/portfolio", text: "view more" },
	},
};

// viableProduct

export const viableProduct = {
	mainSection: {
		tagname: "Minimum Viable Products",
		title: "Build MVPs that succeed in the marketplace with our expert team",
		para: "Our Minimum Viable Products (MVPs) are designed to win the marketplace. We use proven techniques and technologies to build lightweight, scalable MVPs that provide value to customers and drive business growth.",
		cta: {
			label: "Contact a free consultation",
			href: "/contact-us",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: viableproduct1,
	},

	defaultSection: {
		tagname: null,
		title: "Go to market fast with our expert MVP development team.",
		para: "Our team is dedicated to helping you go to market fast with your MVP. We use proven techniques and technologies to build lightweight, scalable MVPs that provide value to customers and drive business growth.",
		image: viableproduct2,
	},
};
