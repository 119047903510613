import React from "react";

function FloatRightTextInfo({ meta }) {
	return (
		<div
			className=" info-container relative my-6 flex h-auto w-full flex-col items-start border-0
border-green-600 py-4  lg:my-12 lg:flex-row lg:py-8 xl:my-16"
		>
			<div
				className="absolute top-0 right-0 z-[0] rounded-xl bg-orange-50
			 lg:right-[35%] lg:h-40 lg:w-40 xl:h-52 xl:w-52 2xl:h-64 2xl:w-64"
			/>
			{/* text introduction */}
			<div
				className="text-content  z-[1] order-1 mt-4 w-full px-0
text-arsenic-900 lg:order-2 lg:w-1/2 lg:px-16 xl:px-20 2xl:px-24"
			>
				<h4
					className="ff-urban  mb-4 flex text-xl font-semibold 
capitalize text-yankees-blue-900 lg:text-2xl xl:text-3xl"
				>
					{meta.title}
				</h4>

				<p
					className=" ff-urban  w-full text-sm leading-6 
 tracking-wider lg:text-base lg:leading-7 "
				>
					{meta?.description}
				</p>
			</div>
			{/* product snapshot */}
			<div className="image-content order-2 mt-5  flex justify-center lg:order-1 lg:mt-0 lg:w-1/2">
				<div
					className="single-portfolio-image image-left
 max-h-92 mb-6 h-full lg:h-80 lg:w-80 2xl:h-96 2xl:w-96"
				>
					<img
						src={meta?.fileUrl}
						alt={meta?.title}
						className="h-full w-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
}

export default FloatRightTextInfo;
