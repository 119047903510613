import _ from "lodash";
import React, { useState } from "react";
import { careersApply } from "assets/data/careers.dto";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { sendApplicantForm } from "services/all-client-service";
import MessageOrProcessing from "components/form/message-or-processing";
import PDFFileUpload from "components/shared/uploader/PDFFileUpload";
import { uploadFileActionEvent } from "services/all-admin-service";

function CareerApplyForm({ vacant = {} }) {
	const { form, checkAgreementText, subscribeText } = careersApply;
	const { title, subtitle, fields } = form;

	const [srcfile, srcFile] = useState(null);
	const [checkAgrement, setCheckAgrement] = useState(false);
	const [checkNewsletter, setCheckNewsletter] = useState(true);
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	async function fileUploadWithUrl(uploadUrl) {
		let hasuploaded = false;
		if (uploadUrl) {
			const fileResp = await uploadFileActionEvent(uploadUrl, srcfile);
			const { uploadfile, uploaderr } = fileResp;
			if (uploadfile && uploadfile?.status === 200) {
				hasuploaded = true;
			} else if (uploaderr) {
				hasuploaded = false;
			}
		}

		return hasuploaded;
	}

	async function onSubmit(form) {
		const fileToUpload = {
			fileName: srcfile?.name,
			contentType: "careers",
			fileExt: srcfile?.type?.split("/")[1],
		};

		console.debug(fileToUpload);

		if (isValid && checkAgrement && _.isEmpty(errors) && !_.isEmpty(form)) {
			updateFormState(true, false, false);
			const resp = await sendApplicantForm({
				...form,
				...fileToUpload,
				checkAgrement,
				checkNewsletter,
				designation: vacant?.title,
				vacant,
			});

			if (resp.response?.status === 200) {
				const { data = {} } = resp.response;
				if (fileUploadWithUrl(data.uploadUrl)) {
					updateFormState(
						false,
						true,
						false,
						"Resume has been submitted successfully.!",
					);
					setCheckAgrement(false);
					srcFile(null);
					reset();
				} else {
					updateFormState(false, false, true, "Could not upload file");
				}
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}

			// if (resp.response) {
			// 	updateFormState(false, true, false, "success");
			// 	setCheckAgrement(false);
			// 	srcFile(null);
			// 	reset();
			// } else if (resp.err) {
			// 	updateFormState(false, false, true, resp.err);
			// }
		}
	}

	const onError = (errors) => {
		console.log(errors);
	};

	function noValidForm() {
		return !isValid || !checkAgrement || _.isNull(srcfile);
	}

	return (
		<div className="w-full px-0 lg:px-4">
			<div className="form-wrapper text-gray-900">
				<div className="form-header ff-urban ">
					<h3 className="text-3xl font-medium">{title}</h3>
					<p className="mt-4 mb-8 text-base font-light tracking-wider text-arsenic-700">
						{subtitle}
					</p>
				</div>
				{/* FORM ALERTS! */}
				<MessageOrProcessing {...formState} />

				<form
					className="career-form ff-urban"
					onSubmit={handleSubmit(onSubmit, onError)}
				>
					{fields.map((field) => (
						<div key={field.key} className="input-group my-4 w-full">
							<label
								className="m-0.5 block text-sm font-semibold
								tracking-wider text-yankees-blue-600"
								htmlFor={field.key}
							>
								{field.label}
							</label>

							{field.type === "textarea" ? (
								<textarea
									name={field.key}
									id={field.key}
									className="input-feild-border-0 h-[160px]"
									{...register(`${field.key}`, {
										required: false,
									})}
									onKeyUp={() => trigger(`${field.key}`)}
								/>
							) : (
								<input
									id={field.key}
									type={field.type}
									name={field.key}
									autoComplete="off"
									className="
                  input-feild-border-0 "
									{...register(`${field.key}`, {
										required: field.error,
										maxLength: field.maxLength,
										pattern: field.pattern,
									})}
									onKeyUp={() => trigger(`${field.key}`)}
								/>
							)}

							<span
								className="block p-1 text-[13px]
							 tracking-[0.06rem] text-red-900"
							>
								{errors[field?.key]?.message}
							</span>
						</div>
					))}

					<div className="input-group my-4 w-full">
						<label
							className="m-0.5 block text-sm font-semibold
							tracking-wider text-yankees-blue-600"
							htmlFor="cv"
						>
							Upload CV / Resume *
						</label>
						<PDFFileUpload setFile={(file) => srcFile(file)} />
						<span
							className="block p-1 text-[13px]
							 tracking-[0.06rem] text-red-900"
						>
							{errors.cv?.message}
						</span>
					</div>

					<div className="checkbox-group flex items-start gap-4 py-2">
						<input
							type="checkbox"
							name="submit-agrement"
							id="submit-agrement"
							className="flex-shrink-0 text-halloween-orange-900
               focus:ring-halloween-orange-300"
							checked={checkAgrement}
							onChange={(e) => setCheckAgrement(e.target.checked)}
						/>
						<span
							className="-mt-1 inline-block max-w-[90%] text-sm leading-6
             tracking-wide text-arsenic-700 xl:text-sm xl:leading-6"
						>
							{checkAgreementText}
						</span>
					</div>

					<div className="checkbox-group flex items-start gap-2 py-4">
						<input
							type="checkbox"
							name="check-subcribe"
							id="check-subcribe"
							className="flex-shrink-0 text-halloween-orange-900
               focus:ring-halloween-orange-300"
							checked={checkNewsletter}
							onChange={(e) => setCheckNewsletter(e.target.checked)}
						/>
						<span
							className="-mt-1 inline-block max-w-[90%] text-sm leading-6
             tracking-wide text-arsenic-700 xl:text-sm xl:leading-6"
						>
							{subscribeText}
						</span>
					</div>
					{/* submit button */}
					{formState.processing ? (
						<div
							className="mt-4 flex  items-center justify-center
						 text-sm tracking-wider text-halloween-orange-900"
						>
							<CgSpinner
								size={30}
								className="
								mr-2
								animate-spin"
							/>
							<span>Submitting...</span>
						</div>
					) : (
						<button
							type="submit"
							disabled={noValidForm()}
							className="ff-urban mt-4 w-full cursor-pointer
							rounded bg-halloween-orange-900 p-3 px-4 text-base
              font-medium tracking-wider text-white
						disabled:cursor-not-allowed disabled:bg-arsenic-300"
						>
							Submit
						</button>
					)}
				</form>
			</div>
		</div>
	);
}

export default React.memo(CareerApplyForm);
