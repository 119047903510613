import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "./WorkGuideSlides.styles.css";

// pagination={{ el: "#swiper-1 .swiper-pagination", clickable: true }}

function WorkGuideSlides({ guidelines }) {
	const [activeIndex, setActiveIndex] = useState(1);

	return (
		<div className="flex h-auto w-full flex-wrap">
			<div className="order-2 w-full lg:order-1 lg:w-1/2">
				<ul className="ml-2 mt-8 w-full space-y-14 p-2 lg:space-y-12 lg:p-4">
					{guidelines.map((step, idx) => (
						<li
							key={idx}
							className={`ff-urban flex transform gap-0 transition-all duration-300 ${
								step.step === activeIndex
									? "text-arsenic-900"
									: "text-arsenic-400"
							}`}
						>
							{/* <div
								className={`inline-flex h-9 w-9 flex-shrink-0 transform
								items-center justify-center rounded-xl border-2
							 	text-base font-medium text-arsenic-600 transition-all duration-300 ${
									step.step === activeIndex
										? "border-halloween-orange-900 text-halloween-orange-900"
										: "border-gray-400"
								}`}
							>
								{`0${step.step}`}
							</div> */}
							<div
								className={`relative border-l-4 py-0 pl-4 tracking-wider transition-all duration-300 ${
									step.step === activeIndex
										? " border-halloween-orange-900 "
										: " border-arsenic-100"
								}`}
							>
								<span
									className={`absolute -top-10 -left-[18px] inline-flex h-8 w-8
								 flex-shrink-0 items-center justify-center  text-base
								font-medium text-white transition-all duration-300 ${
									step.step === activeIndex
										? "bg-halloween-orange-900 "
										: "rounded-2xl bg-gray-300"
								}`}
								>
									{`0${step.step}`}
								</span>
								<h3
									className={`mb-2 text-sm font-semibold uppercase xl:text-base ${
										step.step === activeIndex
											? " text-halloween-orange-900"
											: " text-arsenic-400"
									}`}
								>
									{step.title}
								</h3>
								<p className="pr-6 text-[13px] xl:pr-12 xl:text-sm xl:leading-6">
									{step.para}
								</p>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="order-1 mb-8 w-full lg:order-2 lg:w-1/2">
				<Swiper
					loop
					lazy
					effect="fade"
					modules={[Navigation, EffectFade, Autoplay, Pagination]}
					autoplay={{ delay: 3000, disableOnInteraction: false }}
					keyboard={{ enabled: true }}
					onSlideChange={(swiper) =>
						setActiveIndex(swiper.activeIndex > 4 ? 1 : swiper.activeIndex)
					}
					className="wgs-swiper relative"
					id="swiper-1"
				>
					<div className="wgs-swiper-wrapper">
						{guidelines.map((data, index) => (
							<SwiperSlide key={index} className="overflow-hidden">
								{({ isActive }) => (
									<div
										className="relative h-[325px] w-full overflow-hidden pl-0
								 		lg:pl-8 xl:h-[440px] 2xl:h-[460px]"
									>
										<img
											src={data.image}
											alt={data.title}
											className="h-full w-full object-cover"
										/>
										<div
											className={`absolute bottom-4 -right-1 transform rounded-l-3xl bg-halloween-orange-dark/75
									 		py-2 px-4 text-sm font-medium uppercase tracking-wider text-white 
											transition-all duration-300 ${
												isActive ? "-translate-x-0" : "translate-x-full"
											}`}
										>
											{data.title}
										</div>
									</div>
								)}
							</SwiperSlide>
						))}
					</div>
				</Swiper>
			</div>
		</div>
	);
}

export default WorkGuideSlides;
