import React, { Component } from "react";
import {
	CRASH_ERROR_HEAD,
	CRASH_ERROR_SUBTEXT,
} from "constants/error-messages";
import GenericError from "./errors/GenericError";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		console.debug("derived-state-error:", error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.debug(error, errorInfo);
		this.setState({
			error,
			errorInfo,
		});
	}

	render() {
		const { hasError, errorInfo, error } = this.state;
		const errorMessage = `${CRASH_ERROR_HEAD}`;
		console.debug(error);
		const contactInfo = (
			<div>
				<p>{CRASH_ERROR_SUBTEXT}</p>
				<button
					type="button"
					onClick={() => window.location.reload()}
					className="btn btn-outline mt-8"
				>
					Reload this page
				</button>
			</div>
		);

		if (hasError || errorInfo) {
			return (
				<GenericError
					errorMessage={errorMessage}
					ctaLinkText={this.props.redirectMessage}
					ctaLinkUrl={this.props.redirectUrl}
					errorId={null}
				>
					{contactInfo}
				</GenericError>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
