import React, { useEffect, useState } from "react";
import SEO from "components/shared/seo";
import { fetchAllVacancy } from "services/all-client-service";
import { careers } from "assets/data/careers.dto";
import Newsletter from "components/shared/Newsletter/Newsletter";
import TabColsViews from "./components/TabColsViews";
import AccordionColsView from "./components/AccordionColsView";
import "./Careers.styles.css";

function CareersPage() {
	const { title, quotes, jobsTypes } = careers;
	const [vacancyAll, setVacancyAll] = useState([]);
	const [jobForView, setJobForView] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [group, setGroup] = useState(null);

	useEffect(() => {
		async function onLoadVacancyList() {
			setIsLoading(true);
			const res = await fetchAllVacancy();
			if (res.response) {
				const { response } = res;
				setVacancyAll(response.data);
				setGroup(jobsTypes[0]);
				setIsLoading(false);
			} else if (res.err) {
				console.log("onload-vacancy-list:", res.err);
				setIsLoading(false);
			}
		}
		onLoadVacancyList();
	}, [jobsTypes]);

	useEffect(() => {
		const currTabViewList = vacancyAll.filter((x) => x.group === group?.key);
		setJobForView(currTabViewList);
	}, [group, vacancyAll]);

	return (
		<>
			<SEO
				link="careers"
				postfixSlug="true"
				noIndex={false}
				title="Career Opportunities - Join the Einteger Solutions Team"
				description="Einteger Solutions is a leading software development company and we are always looking for talented individuals to join our team. Our team is dedicated to delivering innovative technology solutions to help businesses succeed. We offer a dynamic and collaborative work environment and opportunities for growth and development. Browse our current job openings and apply today to be part of our team."
				keywords="career, job openings, software development, technology solutions, growth, development, Einteger Solutions"
			/>
			<section
				className="
				careers-bg-cover careers relative
			 	h-full min-h-[35vh] w-full
			 	bg-blend-screen lg:h-[40vh] xl:h-[50vh]"
			>
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="careers-main-wrapper relative z-[2] h-full  w-full lg:my-0">
						<div className="careers-heading my-0 pt-12 text-center lg:mt-0 ">
							<h1
								className="ff-urbanx text-3xl font-medium capitalize
				 			tracking-wide text-arsenic-100 xl:text-4xl"
							>
								{title}
							</h1>

							<p
								className="ff-urban mx-auto mt-5 max-w-3xl
				 			text-center text-sm tracking-wider text-arsenic-100 2xl:text-base"
							>
								{quotes}
							</p>
						</div>
						{/* Hello */}
					</div>
				</div>
			</section>
			<section>
				<div className="careers-view-wrapper relative -top-20">
					<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
						{/* large screen only */}

						<TabColsViews
							loading={isLoading}
							currentTab={group?.key}
							navTabs={jobsTypes}
							onChangeVacancntType={(name) => setGroup(name)}
							vacantPositions={jobForView}
						/>

						{/* mobile/tablet screen only */}

						<AccordionColsView
							loading={isLoading}
							currentTab={group?.key}
							navTabs={jobsTypes}
							onChangeVacancntType={(name) => setGroup(name)}
							vacantPositions={jobForView}
						/>
					</div>
				</div>
			</section>
			<section className="subscription-section mt-14">
				<Newsletter />
			</section>
		</>
	);
}

export default CareersPage;
