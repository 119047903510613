import React from "react";
import Footer from "components/shared/footer";
import Header from "components/shared/header";
import { Outlet } from "react-router-dom";

export default function DefaultLayout() {
	return (
		<div
			className="
			flex
			h-full
			w-full
			flex-col"
		>
			<Header />

			<Outlet />

			<Footer />
		</div>
	);
}
