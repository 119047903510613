import React from "react";
import { CgSpinner } from "react-icons/cg";

function FbSrcLoading() {
	return (
		<div className="flex items-center font-light text-gray-500">
			<CgSpinner size={24} className="mr-1 animate-spin" />
			<span className="text-base">Loading...</span>
		</div>
	);
}

export default FbSrcLoading;
