import React, { useState } from "react";
import SEO from "components/shared/seo";
import { Link } from "react-router-dom";
import { AiOutlineQuestion } from "react-icons/ai";
import faqaskquestions from "assets/images/photo/faq-ask-qestions.png";
import { askquestions } from "assets/data/faq.dto";
import FaqExpandableBox from "./components/FaqExpandableBox";

function FaqPage() {
	const [expandFaqIndex, setExpandFaqIndex] = useState(0);

	function handleExpandEvent(id) {
		if (id === expandFaqIndex) {
			setExpandFaqIndex(null);
		} else {
			setExpandFaqIndex(id);
		}
	}

	return (
		<>
			<SEO
				link="faq"
				postfixSlug="true"
				noIndex={false}
				title="Frequently Asked Questions"
				description="Find answers to common questions about our services, pricing, and process on our FAQ page. Learn more about how we can help your business succeed."
				keywords="FAQ, services, pricing"
			/>
			<section className="faq relative h-full min-h-[90vh] w-full md:min-h-[70vh]">
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="faq-main-wrapper h-full w-full lg:my-24">
						<div className="mt-12 lg:flex">
							<div className="w-full lg:w-1/2">
								<span className="mb-12 ml-1 inline-block font-semibold capitalize tracking-wide text-arsenic-400">
									fell free to{" "}
									<Link
										to="/contact-us"
										className="underline underline-offset-2 hover:text-[#002753]"
									>
										contact us
									</Link>
								</span>
								<h3 className="text-xl font-bold leading-[3rem] tracking-wide md:text-3xl xl:text-4xl">
									Do you have any{" "}
									<span className="text-[#FFA840]">questions</span>
								</h3>

								<p
									className="
								ff-urban
								max-w-[600px]
								text-sm
								font-medium
								tracking-wide
								md:mt-2 
								xl:text-base
								xl:leading-[1.5rem]"
								>
									We are always happy to assist and clarify any doubts you may
									have. Do you have any questions about how we can help you
									achieve your goals?
								</p>

								<div className="my-12 block w-full">
									<div className="mb-8 flex items-center gap-4">
										<div className="rounded-md bg-[#002753] p-3 text-white">
											<AiOutlineQuestion size={32} />
										</div>
										<div>
											<h2 className="text-2xl font-bold text-arsenic-900">
												F.A.Qs
											</h2>
											<p className="font-medium text-arsenic-600">
												Frequently asked questions
											</p>
										</div>
									</div>
								</div>
								<div className="flex w-full justify-center">
									<img
										src={faqaskquestions}
										alt="faq-ask-question"
										className="h-full w-full max-w-sm object-contain"
									/>
								</div>
							</div>
							<div className="w-full lg:w-1/2">
								<div className="faq-ask-list-wrapper my-8 space-y-4 lg:my-0">
									{askquestions.map((ask, index) => (
										<FaqExpandableBox
											key={index}
											id={index}
											question={ask.question}
											answer={ask.reply}
											shouldExpand={index === expandFaqIndex}
											onExpandEvent={handleExpandEvent}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FaqPage;
