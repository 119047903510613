import React, { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";

function PDFFileUpload({ setFile }) {
	const [localFile, setLocalFile] = useState(null);
	const MAXKB = 10240;

	const handleFileChange = async (event) => {
		const { files = [] } = event.target;
		if (files.length > 0) {
			const file = files[0];
			const round = Math.round(file.size / 1024) || null;

			if (round > MAXKB) {
				window.alert("file size exceeded the maximum 10 MB.");
				return;
			}
			setLocalFile(file);
			setFile(file);
		}
	};

	const formatFileSize = (size) => {
		let szFormat = 0;
		const round = Math.round(size / 1024) || null;
		if (round >= 1000) {
			szFormat = ` ${(round / 1024).toFixed(2)} MB`;
		} else {
			szFormat = round ? ` ${round} KB` : null;
		}
		return szFormat;
	};

	// const clearFile = () => {
	// 	setLocalFile(null);
	// 	setFile(null);
	// };

	return (
		<div
			className="relative my-2 h-full min-h-[170px] w-full rounded
 			border-2 border-dashed border-yankees-blue-300 bg-gray-100"
		>
			<input
				type="file"
				id="cv"
				name="cv"
				onChange={handleFileChange}
				accept="application/pdf"
				className="
				relative
				z-10 h-full
				min-h-[200px] w-full
				cursor-pointer
				opacity-0"
			/>
			<button
				type="button"
				className="absolute top-0 left-0 bottom-0
				flex h-full w-full flex-col items-center
				justify-center text-yankees-blue-800"
			>
				<div>
					{localFile ? (
						<VscFilePdf size={32} />
					) : (
						<AiOutlineCloudUpload size={32} />
					)}
				</div>
				<span className="inline-flex items-center pt-2 text-xs tracking-wider text-gray-400">
					{localFile?.name || "No File Found"} {formatFileSize(localFile?.size)}
				</span>
				<div className="mt-2 text-xs font-medium leading-5 tracking-wider">
					Maximum file size 10 MB.
					<br /> Supports: PDF
				</div>
			</button>
		</div>
	);
}

export default React.memo(PDFFileUpload);
