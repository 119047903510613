import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { getLatestPublishedPortfolios } from "services/all-client-service";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function CaseStudiesExamples(props) {
	const { tagname, para, cta } = props;

	const [portfolios, setPortfolios] = useState([]);

	async function fetchPortfoliosPosts() {
		const res = await getLatestPublishedPortfolios();
		if (res.response?.status === 200) {
			const { response = {} } = res;
			setPortfolios(response.data);
		}
	}

	useEffect(() => {
		fetchPortfoliosPosts();
	}, []);

	const onlyPublishedPortfolio = useMemo(
		() => portfolios.filter((b) => b.published),
		[portfolios],
	);

	function renderTextAsShort(text = "") {
		let renderText = text;
		if (renderText.length > 120) {
			renderText = `${renderText.substring(0, 120)}...`;
		}

		return renderText;
	}

	const latestPortfolios = useMemo(() => {
		const length = onlyPublishedPortfolio?.length || 0;

		if (length <= 3) return onlyPublishedPortfolio;

		return onlyPublishedPortfolio.slice(-3);
	}, [onlyPublishedPortfolio]);

	if (onlyPublishedPortfolio.length === 0) {
		return null;
	}

	return (
		<section className="service-worker-section mb-8 h-full w-full lg:mb-12">
			<div className="container z-[1] mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="techstack-wrapper h-full w-full">
					<div className="block-container flex flex-col justify-center py-8 lg:py-16">
						<div className="title text-center">
							<h4
								className="ff-sanspro block text-base font-bold capitalize
								tracking-wide text-halloween-orange-dark lg:text-xl"
							>
								{tagname}
							</h4>

							<p
								className="ff-urban  mx-auto max-w-[90] py-8 text-sm
							 tracking-wider text-arsenic-600 lg:max-w-3xl lg:text-base"
							>
								{para}
							</p>
						</div>

						<div className="showcases-wrapper my-12 xl:my-24">
							<Swiper
								modules={[Pagination]}
								pagination={{ clickable: true }}
								centeredSlides={false}
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 10,
									},
									768: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									1024: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
								}}
								className="showcase-swiper-slider my-6 h-auto w-full"
							>
								{latestPortfolios.map((shawcase, index) => (
									<SwiperSlide
										key={index}
										className="card-wrapper items-center-x mb-12
										flex w-full flex-col"
									>
										{/* sperate basic-card */}
										<div className="flex-shrink">
											<img
												src={shawcase?.fileUrl}
												alt={shawcase?.title}
												className="h-full max-h-[275px] w-full rounded-xl object-cover"
											/>
										</div>
										<div className="content px-4 py-4">
											<h2 className=" text-xl font-medium capitalize text-arsenic-900 xl:text-2xl">
												{shawcase?.title}
											</h2>
											<h4
												className="my-4 font-semibold uppercase tracking-wider
											 text-halloween-orange-900"
											>
												{shawcase?.subject}
											</h4>
											<p
												className="ff-urban text-sm font-normal leading-6
											 tracking-wider text-arsenic-600 xl:text-[15px] xl:leading-7"
											>
												{renderTextAsShort(shawcase?.subtitle)}
											</p>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
						{/* showcases-wrapper end */}
						<div className="w-full text-center">
							<Link
								to={cta.path}
								className="ff-urban rounded 
								bg-halloween-orange-900 px-6 py-3 text-sm capitalize
								tracking-wider text-halloween-orange-200
								hover:bg-halloween-orange-dark hover:text-white"
							>
								{cta.text}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default React.memo(CaseStudiesExamples);
