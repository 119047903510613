import React from "react";
import { Link } from "react-router-dom";
import { areYouReady } from "assets/data/home.dto";

function ReadyContactUs() {
	const { title, para, cta1 } = areYouReady;
	return (
		<section className="mb-20 mt-24 h-auto w-full">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="w-full px-0 lg:px-16 xl:px-36 2xl:px-52">
					<div
						className="clip-path-wrap clip-path-1 clip-path-2
							ff-urban flex flex-col items-center justify-center
						 rounded-2xl bg-gradient-to-r from-cinnabar-800 to-pink-600 py-6 px-12 text-white"
					>
						<h2 className="z-[1] mb-4 text-center text-2xl font-bold lg:text-4xl">
							{title}
						</h2>
						<p className="z-[1] w-full text-center text-sm tracking-wider lg:max-w-[80%] xl:max-w-[70%]">
							{para}
						</p>
						<div className="button-cta-group z-[1] mt-6 mb-3 flex flex-col items-center gap-5 md:flex-row">
							<Link
								to="/contact-us"
								className="
								ff-urban-x whitespace-nowrap rounded
								bg-white px-6 py-3 text-sm font-semibold uppercase
							  	tracking-wide text-arsenic-900 transition-all duration-300
								hover:text-pink-600 hover:ring-2 hover:ring-pink-400
							    hover:ring-offset-4 hover:ring-offset-pink-200"
							>
								{cta1}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ReadyContactUs;
