import React from "react";
import { GrFormDown } from "react-icons/gr";

function FaqExpandableBox({
	id,
	question,
	answer,
	onExpandEvent,
	shouldExpand,
}) {
	const answerStyles = shouldExpand
		? "max-h-[300px] opacity-100 pb-8"
		: "max-h-0 opacity-0 pb-0";
	const iconStyles = !shouldExpand ? "rotate-180" : "rotate-0";
	const boxActiveStyles = shouldExpand
		? "bg-[#FFA840]/10 border-[#FFA840]"
		: "bg-white";

	return (
		<div
			role="button"
			tabIndex="0"
			onKeyPress={() => onExpandEvent(id)}
			onClick={() => onExpandEvent(id)}
			className={`
			w-full rounded-md border-2
			border-dashed outline-none ${boxActiveStyles}`}
		>
			<div className="heading flex items-center justify-between p-6">
				<h2
					className="
					question-tag
					block
					max-w-[90%]
					text-xl
					font-semibold"
				>
					{question}
				</h2>
				<div
					role="button"
					tabIndex="0"
					onKeyPress={() => onExpandEvent(id)}
					onClick={() => onExpandEvent(id)}
					className={`
					flex h-6 w-6
					flex-shrink-0
					transform
					cursor-pointer
					items-center
					justify-center
					rounded-full
					bg-[#FFA840]
					transition-all
					duration-300
					${iconStyles}`}
				>
					<GrFormDown size={16} />
				</div>
			</div>
			<p
				className={`
				ff-urban-x
				transform
				px-6
				text-base
				leading-6 
				tracking-wide
				text-arsenic-600
				transition-all
				duration-300 ease-out
				${answerStyles}`}
			>
				{answer}
			</p>
		</div>
	);
}
export default FaqExpandableBox;
