import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signIn } from "services/auth-amplify-service";
import { Logo } from "assets/data/header.dto";
import MessageOrProcessing from "components/form/message-or-processing";
import { FaSpinner } from "react-icons/fa";

export default function LoginPage() {
	const history = useNavigate();
	const location = useLocation();
	const [backToNavUrl, setBackToNavUrl] = useState(null);
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});
	const {
		register,
		handleSubmit,
		trigger,
		reset,
		formState: { errors, isValid },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	useEffect(() => {
		const { state = {} } = location;
		if (state && state?.redirectUrl) {
			setBackToNavUrl(state?.redirectUrl);
		} else {
			setBackToNavUrl(null);
		}
	}, [location]);

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	const routeAfterAuthenticated = () => {
		if (backToNavUrl) {
			history(backToNavUrl);
		} else {
			history("/");
		}
	};

	async function onSubmit(auth) {
		if (isValid && _.isEmpty(errors)) {
			updateFormState(true, false, false);
			const res = await signIn(auth);
			if (res.userInfo) {
				updateFormState(false, true, false);
				reset();
				routeAfterAuthenticated();
			} else if (res.err) {
				updateFormState(false, false, true, res.err);
			}
		}
	}

	const onError = (errors) => {
		console.log(errors);
	};

	return (
		<div className="singin-form-overlay h-full min-h-screen w-full">
			<div
				className=" mx-auto mt-[15%] flex w-full max-w-xl
        flex-col items-center bg-transparent px-4 lg:mt-0"
			>
				<div
					className="form-wizard mt-14 w-full rounded-2xl bg-white
					px-4 py-6 sm:py-8 lg:mt-12 lg:px-14 xl:mt-16
					xl:py-14 2xl:mt-32"
				>
					<div className="flex w-full justify-center">
						<div
							className="
							brand-logo
							mb-8
							-ml-4
						    w-[150px]
							lg:w-[160px]"
						>
							<img
								src={Logo}
								alt="company-logo"
								className="h-full w-full object-contain"
							/>
						</div>
					</div>

					{/* FORM ALERTS! */}
					<MessageOrProcessing {...formState} />

					<form onSubmit={handleSubmit(onSubmit, onError)}>
						<div className="form-input-group my-1 inline-block w-full">
							<label id="email" htmlFor="email" className="form-lbl-text">
								administrator email address
							</label>
							<input
								type="email"
								name="email"
								id="email"
								className="input-feild-gray"
								autoComplete="on"
								{...register("email", {
									required: "Email address is required",
									pattern: {
										value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
										message: "Email address is invalid",
									},
								})}
								onKeyUp={() => trigger("email")}
							/>

							<small className="form-input-error-text">
								{errors.email?.message}
							</small>
						</div>

						<div className="form-input-group my-1 inline-block w-full">
							<label id="password" htmlFor="password" className="form-lbl-text">
								administrator password
							</label>
							<input
								type="password"
								name="password"
								id="password"
								autoComplete="off"
								className="input-feild-gray"
								{...register("password", {
									required: "Password is required",
								})}
								onKeyUp={() => trigger("password")}
							/>

							<small className="form-input-error-text">
								{errors.password?.message}
							</small>
						</div>

						<div className="mt-2 flex w-full items-center">
							<input
								type="checkbox"
								className="text-yankees-blue-900 focus:ring-0"
								defaultChecked
							/>
							<span className="ml-2 text-sm capitalize text-yankees-blue-600">
								remember me
							</span>
						</div>
						{formState.processing ? (
							<div className="flex w-full justify-center py-3">
								<FaSpinner size={24} className="animate-spin self-center" />
							</div>
						) : (
							<button
								disabled={formState.processing}
								type="submit"
								className="mt-12 inline-block
								w-full rounded bg-yankees-blue-900
								py-3 text-sm uppercase
								tracking-wider text-white hover:opacity-75"
							>
								sign in
							</button>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}
