import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const skipByTrakingHost = [
	"localhost",
	"127.0.0.1",
	"https://d1ydvqjs4hop9d.cloudfront.net",
];
// const TrakingId = "UA-220159878-1"; // prev-traking-id
const TrakingId = "G-C77GJ4SX9D";

const useGaTracker = () => {
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);

	const isDevHost = () => {
		let skipTracking = false;
		skipByTrakingHost.forEach((host) => {
			if (!skipTracking && window.location.href.includes(host)) {
				skipTracking = true;
			}
		});

		return skipTracking;
	};

	useEffect(() => {
		if (!isDevHost()) {
			ReactGA.initialize(TrakingId);
			setInitialized(true);
		} else {
			setInitialized(false);
		}
	}, []);

	useEffect(() => {
		if (initialized) {
			ReactGA.pageview(location.pathname + location.search);
			console.debug(location.pathname + location.search);
		}
	}, [initialized, location]);
};

export default useGaTracker;

/**
	 *
	 *  handleSignUpClick = () => {
		Track event when a user signs up
			ReactGA.event({
			category: 'User',
			action: 'Created an Account',
			label:"user email",
			value:user.email
		});
		}
	 */
