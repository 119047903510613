import React from "react";
import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function PostedCard({ post }) {
	function renderWithSubstring(text = "") {
		let renderText = text;
		if (renderText.length > 120) {
			renderText = `${renderText.substring(0, 120)}...`;
		}

		return renderText;
	}

	function urlLink(stringLink) {
		if (stringLink === "") return "/blog";

		const urlText = stringLink.replace(/\s+/g, "-").toLowerCase();
		return `/article/${urlText}`;
	}

	return (
		<Link
			to={urlLink(post?.publicLink)}
			state={{
				article: post,
				shareUrl: `${process.env.REACT_APP_HOST_PREFIX}${urlLink(
					post?.publicLink,
				)}`,
			}}
			className="
			group h-auto w-full  max-w-[325px]
			rounded-md shadow-md hover:opacity-75"
		>
			<div className="h-56 w-full overflow-hidden">
				<img
					src={post?.fileUrl}
					alt={post?.title}
					className="h-full w-full rounded-t-md object-cover
					transition-all duration-300 group-hover:scale-105"
				/>
			</div>
			<div className="content p-3">
				<div className="meta inline-flex items-center gap-2 text-sm text-arsenic-600">
					<FaRegCalendarAlt size={16} />
					<span>{moment(post?.createdAt).format("ll")}</span>
				</div>
				<h1 className="mt-2 text-lg text-arsenic-900">
					{renderWithSubstring(post?.title)}
				</h1>
			</div>
		</Link>
	);
}

export default PostedCard;
