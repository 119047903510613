import { FaPhoneAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { MdEmail } from "react-icons/md";

export const contactForm = {
	fields: [
		{
			key: "name",
			label: "Name",
			type: "text",
			maxLength: null,
			pattern: null,
			error: "Please enter your name",
		},
		{
			key: "company",
			label: "Your Company website",
			type: "text",
			maxLength: null,
			pattern: null,
			error: "Please enter your company name",
		},
		{
			key: "email",
			label: "E-mail",
			type: "email",
			maxLength: null,
			pattern: {
				value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
				message: "Email address is invalid",
			},
			error: "Please enter a valid email address",
		},

		{
			key: "phone",
			label: "Phone",
			type: "text",
			maxLength: {
				value: 14,
				message: "Phone number length should less than 14 (+94) digits",
			},
			pattern: {
				value: /^[0-9]*$/gm,
				message: "Match only digits",
			},
			error: "Please enter your mobile number",
		},
		{
			key: "reason",
			label: "What are you planning to build?",
			type: "textarea",
			maxLength: null,
			pattern: null,
			error: "Please let us know more",
		},
	],
	subscribeText: "Subscribe to Einteger Solutions newsletter",
	checkAgreementText:
		"I agree to give the above personal data to Rootcode labs. Rootcode labs may use the data to evaluate, store for further processing and use anonymised data to train our models to improve our recruitment process.",
	information: {
		title: "Contact Information",
		meta: [
			{
				icon: <ImLocation />,
				text: "Colombo, SriLanka.",
			},
			{ icon: <FaPhoneAlt />, text: "+(94)-77 661 0303" },
			{ icon: <MdEmail />, text: "hello@einteger.com" },
		],
	},
};
export const help = {
	title: "how can we help?",
	para: "Please select a topic below related to your inquiry.if you don not find what you need, fill out contact form.",
	inquiry: [],
};
