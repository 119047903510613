import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthUserInfo } from "services/auth-amplify-service";

const AuthContext = React.createContext();

function useAuthContext() {
	return useContext(AuthContext);
}

function AuthContextProvider(props) {
	const navigate = useNavigate();

	const [user, setUser] = useState({});
	const [token, setToken] = useState(null);

	useEffect(() => {
		async function requestAuthFromAmplify() {
			const userSession = await getAuthUserInfo();
			if (userSession && userSession?.sessionToken) {
				setUser(userSession.user);
				setToken(userSession.sessionToken);
			} else {
				console.debug("request should be authenticated at admin level.");
				navigate("/signin", { state: { redirectUrl: "/es-admin" } });
			}
		}
		requestAuthFromAmplify();
	}, []);

	const memoState = useMemo(
		() => ({
			user,
			setUser,
			token,
			setToken,
		}),
		[token, user],
	);

	return (
		<AuthContext.Provider value={memoState}>
			{token ? props.children : <div>loading..</div>}
		</AuthContext.Provider>
	);
}

export { AuthContextProvider, useAuthContext };
