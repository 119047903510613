import React from "react";
import {
	Logo,
	mainColumn,
	socialIcon,
	colsLinks,
} from "assets/data/footer.dto";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import useExternalLink from "hook/useExternalLink";

function Footer() {
	const { openHref } = useExternalLink();

	return (
		<footer className="mt-0 h-auto w-full bg-gray-900">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div
					className="
					00-mx-3 ff-urban flex flex-row flex-wrap
        			py-8 text-arsenic-100/75 lg:justify-between lg:py-16"
				>
					{/* left section */}
					<div className="w-full max-w-full flex-shrink lg:w-2/6">
						<div className="max-w-full pb-12 sm:max-w-xs lg:pb-0">
							<p className="text-lg tracking-wider ">{mainColumn.text}</p>
							<Link
								to="/contact-us"
								className="
								inline-flex items-center gap-x-4 py-4 text-3xl
               					hover:animate-pulse hover:text-cinnabar-900
								lg:text-4xl xl:text-5xl"
							>
								{mainColumn.cta}
								<HiOutlineArrowRight size={42} />
							</Link>
							<ul className="mt-6 mb-6 space-x-3 lg:mb-0">
								{socialIcon.map((item, index) => (
									<li
										key={index}
										className="
										inline-flex h-10 w-10 items-center
										justify-center bg-gray-800"
									>
										<button
											type="button"
											onClick={() => openHref(item.hrefURL)}
											className="text-xl"
										>
											{item.icon}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
					{/* right section */}
					<div className="w-full max-w-full flex-shrink lg:w-4/6">
						<div className="flex flex-row flex-wrap">
							{colsLinks.map((item, index) => (
								<div
									key={index}
									className="
									mb-6 w-full max-w-full flex-shrink px-1
									md:w-1/3 lg:mb-0 lg:w-1/2 lg:px-4 xl:w-1/3"
								>
									<h4
										className="
										mb-8 text-lg font-semibold
										capitalize tracking-wide"
									>
										{item.title}
									</h4>
									<ul>
										{item?.childList?.map((child, index) => (
											<li
												key={index}
												className="
												mb-2 text-sm font-light tracking-wider
												text-arsenic-100/75 hover:text-cinnabar-900"
											>
												{child?.external ? (
													<a href={child.href} target="_blank" rel="noreferrer">
														{child.text}
													</a>
												) : (
													<Link to={child.href}>{child.text}</Link>
												)}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div id="footer-end" className="border-t border-gray-800">
				<div className="ff-urban container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="flex flex-col items-center justify-between py-2 text-xs font-light text-arsenic-100/75 lg:flex-row lg:text-xs">
						<div className="col-span-6 flex  flex-wrap  items-center justify-center">
							<img src={Logo} alt="logo" className="w-28 py-3 lg:py-0" />
							<p className="d-block ml-4 tracking-wider">{`© ${new Date().getFullYear()} Einteger Solutions. All Rights Reserved`}</p>
						</div>
						<div className="col-span-6">
							<div className="my-4 space-x-4 tracking-wider lg:my-0">
								<Link to="/privacy-policy">Privacy Policy </Link>
								<Link to="/terms-and-conditions">Terms and Conditions </Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
