import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Root from "components/Root";
import reportWebVitals from "./reportWebVitals";
import "configs/aws-amplify";
import "styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.REACT_APP_NODE_ENV === "production") console.log = () => {};

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Root />
		</BrowserRouter>
	</React.StrictMode>,
);

reportWebVitals();
