import React from "react";
import { banner } from "assets/data/aboutus.dto";

function TopBanner() {
	const { title, para } = banner;

	return (
		<section className="aboutus-bg-cover careers relative h-full min-h-[40vh] w-full bg-blend-screen lg:h-[40vh] xl:h-[50vh]">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="careers-main-wrapper relative z-[2] h-full  w-full lg:my-0">
					<div className="careers-heading my-0 pt-12 text-center lg:mt-0 ">
						<h1
							className="ff-urbanx text-3xl font-medium capitalize
			 				tracking-wide text-arsenic-100 xl:text-4xl"
						>
							{title}
						</h1>
						<p
							className="ff-urban mx-auto mt-5 max-w-3xl
				 			text-center text-sm tracking-wider text-arsenic-100 2xl:text-base"
						>
							{para}
						</p>
					</div>
					{/* Hello */}
				</div>
			</div>
		</section>
	);
}

export default TopBanner;
