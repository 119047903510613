import React from "react";
import { footprint } from "assets/data/aboutus.dto";
import SL from "assets/images/flags/sl.png";
import UAE from "assets/images/flags/uae.png";
import CANADA from "assets/images/flags/ca.png";
import AUS from "assets/images/flags/aus.png";
import NWZ from "assets/images/flags/nwz.png";
import USA from "assets/images/flags/usa.png";
import MapMarkPoint from "./MapMarkPoint";

function CustomerFootprint() {
	const { tagname, para, worldmap } = footprint;

	return (
		<section className="xoverflow-hidden relative mt-24 mb-8 h-full w-full bg-white lg:mb-32">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="heading-title mx-auto max-w-lg text-center">
					<h3 className="ff-sanspro mb-8 inline-block text-xl font-semibold text-arsenic-800">
						{tagname}
					</h3>
					<p className="ff-urban text-sm font-medium tracking-wider text-arsenic-600 lg:text-base">
						{para}
					</p>
				</div>
				{/* world map */}
				<div
					className="relative mt-14 h-full w-full
				 bg-transparent"
				>
					<img
						src={worldmap}
						alt="worldmap"
						className="
						relative 
						h-full
						w-full
						scale-90
						object-contain
						object-center
						contrast-[0.2]
						filter"
					/>

					<MapMarkPoint
						flag={SL}
						name="sri lanka"
						top="52"
						right="32"
						projects={[
							"Healthcare",
							"Hotel",
							"Construction",
							"Artificial Intelligence",
							"E-commerce",
						]}
					/>
					<MapMarkPoint
						flag={UAE}
						name="united arab emirates"
						top="44"
						right="41"
						projects={[
							"Travel and tourism",
							"Restaurent",
							"Entertainment",
							"E-commerce",
						]}
					/>
					<MapMarkPoint
						flag={CANADA}
						name="canada"
						top="22"
						right="79"
						projects={[
							"E-commerce",
							"Entertainment",
							"Artificial Intelligence",
						]}
					/>
					<MapMarkPoint
						flag={USA}
						name="United States"
						top="39"
						right="76"
						projects={["Fin Tech"]}
					/>
					<MapMarkPoint
						flag={NWZ}
						name="New Zealand"
						top="78"
						right="10"
						projects={["E-commerce"]}
					/>
					<MapMarkPoint
						flag={AUS}
						name="Australia"
						top="70"
						right="18"
						projects={["E-commerce"]}
					/>
				</div>
			</div>
		</section>
	);
}

export default CustomerFootprint;
