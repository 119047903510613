import React, { useEffect, useState } from "react";
import SEO from "components/shared/seo";
import { getAllPortfolios } from "services/all-admin-service";
import RenderRequestOrPendingLayout from "./components/RenderRequestOrPendingLayout";

import "./Portfolio.styles.css";

function PortfolioPage() {
	const [portfolios, setPortfolios] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function fetchAllData() {
			setIsLoading(true);
			const res = await getAllPortfolios();
			if (res.response?.status === 200) {
				const { response } = res;
				setPortfolios(response.data);
				setIsLoading(false);
			} else if (res?.err) {
				console.log("Error:", res.err);
				setIsLoading(false);
			}
		}
		fetchAllData();
	}, []);

	return (
		<>
			<SEO
				link="portfolio"
				postfixSlug="true"
				noIndex={false}
				title="Our Work and Case Studies"
				description="Explore our portfolio and see how we've helped businesses succeed through custom software solutions. View our case studies to see the impact of our work"
				keywords="portfolio, case studies, custom software solutions, business success"
			/>
			<section className="portfolio relative h-full min-h-[90vh] w-full md:min-h-[70vh]">
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="portforlio-main-wrapper h-full w-full lg:my-12">
						<div className="portforlio-heading mt-6  text-center lg:mt-0">
							<h1
								className="text-3xl font-medium
						 		tracking-wide text-arsenic-900 xl:text-5xl"
							>
								Portfolio
							</h1>
						</div>
						<RenderRequestOrPendingLayout
							isLoading={isLoading}
							portfolio={portfolios}
						/>
					</div>
				</div>
			</section>
		</>
	);
}

export default PortfolioPage;
