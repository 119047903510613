import _ from "lodash";
import React from "react";
import PLACEHOLDER_URL from "assets/images/placeholder.jpg";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

function GerneralView({ exclusive = {} }) {
	function renderWithSubstring(text = "") {
		let renderText = text;
		if (renderText.length > 30) {
			renderText = `${renderText.substring(0, 30)}...`;
		}

		return renderText;
	}

	if (_.isEmpty(exclusive)) {
		return <FbSrcLoading />;
	}

	return (
		<div className="gerneral-view-wrapper">
			<div className="flex gap-2 px-2 py-4">
				<div className="left w-full space-y-4 lg:w-1/3">
					<div>
						<h4 className="text-sm uppercase">blog / title</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.title}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">public url</h4>
						<a
							title={exclusive?.publicLink}
							href={exclusive?.publicLink}
							target="_blank"
							rel="noreferrer"
							className="ff-urban whitespace-pre text-sm text-gray-400
							underline hover:animate-pulse hover:text-blue-900"
						>
							{renderWithSubstring(exclusive?.publicLink)}
						</a>
					</div>
					<div>
						<h4 className="text-sm uppercase">status</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.published ? "Published" : "Unpublished"}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">created</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.createdAt}
						</span>
					</div>
				</div>
				<div className="right w-full lg:w-2/3">
					<div className="thumbnail relative h-full max-h-[325px] w-full overflow-hidden pl-4">
						<img
							src={exclusive?.fileUrl || PLACEHOLDER_URL}
							alt={exclusive?.title}
							className="h-full w-full object-contain"
						/>
						<div
							className="absolute bottom-0 left-0 ml-4 -mt-4 inline-block w-full
						 bg-white px-0 py-2 text-sm"
						>
							{exclusive?.fileName}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GerneralView;
