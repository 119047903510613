import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
	getAllContactusMessages,
	getContactusMessageById,
} from "services/all-admin-service";
import PopupWizardOverlay from "components/shared/popup-wizard";
import DataTableView from "../components/DataTableView";
import FormPopupWizard from "../components/FormPopupWizard";
import ExclusiveSetting from "../components/ExclusiveSetting";
import GerneralViewMessages from "../components/contact-messages/GerneralViewMessages";
import { tblcontactus } from "../admin.data";

const modalInitialState = { modal: null, state: false };

function AdminMessagesPage() {
	const [viewModal, setViewModal] = useState(modalInitialState);
	const [tableSheet, setTableSheet] = useState([]);
	const [openRow, setOpenRow] = useState(null);

	async function onLoadTableSheet() {
		const res = await getAllContactusMessages();
		if (res.response?.status === 200) {
			const { response } = res;
			setTableSheet(response.data);
		}
	}

	async function getRowById(id = null) {
		if (id === null) return;

		const res = await getContactusMessageById(id);
		console.log(res.response);
		if (res.response?.status === 200) {
			const { response } = res;
			setOpenRow(response.data);
		}
	}

	useEffect(() => {
		onLoadTableSheet();
	}, []);

	function handleUpdatePage(request) {
		console.debug(request);
	}

	function reloadTableSheet() {
		onLoadTableSheet();
	}

	function handleModal(modal, row = null) {
		if (!_.isNil(row)) {
			getRowById(row.id);
		}
		setViewModal((prev) => ({
			modal,
			state: !prev.state,
		}));
	}

	function handleExitModal() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
	}

	function modalHead(modal = "") {
		let title = null;

		if (modal === "other-modal") {
			title = "other-modal-heading";
		} else if (modal === "settings") {
			title = "received message";
		}

		return title;
	}

	function handleAfterFormActionEnd() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
		setTimeout(() => reloadTableSheet(), 3000);
	}

	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div className="content-header block items-center justify-between lg:flex">
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								messages / contact us
							</h3>

							<p
								className="ff-urban max-w-full py-2
								text-base tracking-wider text-dark-jungle/70 lg:max-w-[80%] xl:lg:max-w-[60%]"
							>
								Are easy to find, so a visitor can quickly get in touch with
								you. Explain why someone should contact your business.
							</p>
						</div>
						<div className="top-right">
							<div className="actions">{/* <button>filter</button> */}</div>
						</div>
					</div>
					{/* END HEADER */}

					<DataTableView
						onrefresh={reloadTableSheet}
						handleClickActionButton={handleModal}
						handlePageNavsClick={handleUpdatePage}
						theadCols={tblcontactus.headings}
						sheet={tableSheet}
						cols={["id", "name", "email", "company", "phone", "createdAt"]}
					/>
					{/* content-Panel box end */}
				</div>
			</div>

			{/* Modal popup modal */}
			<PopupWizardOverlay
				isOpen={viewModal.state}
				handleClickOverlay={() => console.debug("overlay")}
			>
				<FormPopupWizard
					onExit={handleExitModal}
					title={modalHead(viewModal.modal)}
				>
					{viewModal.modal === "settings" ? (
						<ExclusiveSetting
							tabsviews={[
								<GerneralViewMessages
									exclusive={openRow}
									onAfterFormActionEnd={handleAfterFormActionEnd}
								/>,
							]}
						/>
					) : null}
				</FormPopupWizard>
			</PopupWizardOverlay>
		</section>
	);
}

export default AdminMessagesPage;
