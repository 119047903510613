import React from "react";
import SEO from "components/shared/seo";
import ContactForm from "./ContactForm";
import ContactOtherInfo from "./ContactOtherInfo";

function ContactUsPage() {
	return (
		<>
			<SEO
				link="contact-us"
				postfixSlug="true"
				noIndex={false}
				title="Connect with our team today"
				description="Einteger Solutions is a leading software development company. We are always ready to help businesses of all sizes achieve their goals through the use of innovative technology solutions. Our team of experts is dedicated to delivering high-quality solutions that make a difference in the world. Connect with us today to learn more about how we can help you with your software development needs. Fill out our contact form or call us to schedule a consultation."
				keywords="contact us, software development, consultation, custom solutions, business growth, Einteger Solutions."
			/>
			<section
				className="
			contactus-bg-cover
			careers relative
		 	h-full min-h-[100vh]
			 w-full bg-[#F8F8F8]"
			>
				<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
					<div className="contactus-main-wrapper  h-full w-full">
						<div className="flex flex-col justify-center py-16 lg:flex-row">
							<div className="left-view order-2 w-full lg:order-1 lg:w-[60%]  xl:w-1/2">
								<ContactForm />
							</div>
							<div className="right-view  order-1 w-full lg:order-2 lg:w-[40%]    xl:w-1/2">
								<ContactOtherInfo />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ContactUsPage;
