import APIs from "constants/api-endpoints";
import ApiService from "utils/ApiService";

export async function sendApplicantForm(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.sendApplicantURL(), {
			data,
		});
	} catch (err) {
		console.log("send has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

export async function fetchAllVacancy() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.saveOrFetchEmploymentURL());
	} catch (err) {
		console.log("fetch has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

export async function fetchVacancyByGroupName(groupName) {
	const result = { response: null, err: null };
	// TODO
	console.log(groupName);
	return result;
}

export async function sendContactusForm(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.sendContactRequestURL(), {
			data,
		});
	} catch (err) {
		console.log("send has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

export async function subscribeForNewsletter(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.requestSubscriptionURL(), {
			data,
		});
	} catch (err) {
		console.log("send has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

export async function getLatestPublishedBlogs() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchBlogURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

export async function getLatestPublishedPortfolios() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchPortfolioURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}
