import React from "react";
import ReactDOM from "react-dom";

const htmlRoot = document.getElementById("portal");

export default class Portal extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement("div");
	}

	componentDidMount() {
		htmlRoot.appendChild(this.el);
	}

	componentWillUnmount() {
		htmlRoot.removeChild(this.el);
	}

	render() {
		const { children } = this.props;
		return ReactDOM.createPortal(children, this.el);
	}
}