import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { careers } from "assets/data/careers.dto";
import { useForm } from "react-hook-form";
import { CgPlayListAdd, CgSpinner } from "react-icons/cg";
import { TiDelete } from "react-icons/ti";
import MessageOrProcessing from "components/form/message-or-processing";
import {
	insertAnEmployment,
	updateAnEmployment,
	deleteAnEmployment,
} from "services/all-admin-service";

import "../../Admin.styles.css";

const workTypes = [
	{ key: "full-time", value: "Full Time" },
	{ key: "part-time ", value: "Part Time" },
	{ key: "contract-base", value: "Contractor" },
];

function DropList({ data, remove }) {
	return (
		<div className="my-2 max-h-40 space-y-1 overflow-y-auto">
			{data?.map((item, i) => (
				<div
					key={i}
					className="inline-flex w-full
					items-start rounded border border-dashed
					p-1 text-[13px] font-light text-gray-700"
				>
					<TiDelete
						size={24}
						onClick={() => remove(i)}
						className="mr-2 flex-shrink-0
					cursor-pointer
					text-dark-gunmetal hover:text-red-700"
					/>
					<span>{item}</span>
				</div>
			))}
		</div>
	);
}

function AddRPForm({ form = "insert", exclusive = {}, onAfterFormActionEnd }) {
	const { jobsTypes: groups } = careers;
	const expectRef = useRef(null);
	const responsibilityRef = useRef();
	const [uuid, setUUID] = useState(null);
	const [published, setPublished] = useState(1);
	const [expects, setExpects] = useState([]);
	const [responsibilities, setResponsibilties] = useState([]);

	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		setValue,
		formState: { errors },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	useEffect(() => {
		if (!_.isEmpty(exclusive)) {
			setUUID(exclusive?.id);
			setValue("group", exclusive?.group);
			setValue("worktype", exclusive?.worktype);
			setValue("title", exclusive?.title);
			setValue("location", exclusive?.location);
			setPublished(exclusive?.published);
			setExpects(exclusive.expects || []);
			setResponsibilties(exclusive.responsibilities || []);
		}
	}, [exclusive]);

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	function resetForm() {
		setExpects([]);
		setResponsibilties([]);
		reset();
	}

	async function onSubmit(data) {
		let resp;
		if (_.isEmpty(errors) && !_.isEmpty(data)) {
			updateFormState(true, false, false);
			if (form === "insert") {
				resp = await insertAnEmployment({
					...data,
					expects,
					responsibilities,
					published,
				});
			} else if (form === "advance") {
				resp = await updateAnEmployment({
					...data,
					id: uuid,
					expects,
					responsibilities,
					published,
				});
			} else {
				console.debug("Not form action :", form);
			}

			if (resp?.response) {
				updateFormState(false, true, false, "success");
				resetForm();
				onAfterFormActionEnd();
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	async function handleClickConfirm() {
		const confirm = window.confirm(
			"Are you sure you want to delete everything?",
		);

		if (confirm) {
			updateFormState(true, false, false);
			const resp = await deleteAnEmployment(uuid);

			if (resp.response?.status === 200) {
				updateFormState(false, true, false, "deleted!");
				onAfterFormActionEnd();
				resetForm();
			} else if (resp?.err) {
				updateFormState(false, false, true, resp.err);
			}
		}
	}

	function onAddExpect() {
		if (expectRef.current && !_.isEmpty(expectRef.current.value)) {
			const values = [...expects, expectRef.current.value];
			setExpects(values);
			expectRef.current.value = null;
		}
	}

	function onAddResponsibilities() {
		if (
			responsibilityRef.current &&
			!_.isEmpty(responsibilityRef.current.value)
		) {
			const values = [...responsibilities, responsibilityRef.current.value];
			setResponsibilties(values);
			responsibilityRef.current.value = null;
		}
	}

	function uncheckExpectList(index) {
		const withoutIndexElementOfTheArray = expects.filter(
			(_, idx) => idx !== index,
		);
		setExpects([...withoutIndexElementOfTheArray]);
	}

	function uncheckResponsibilityList(index) {
		const withoutIndexElementOfTheArray = responsibilities.filter(
			(_, idx) => idx !== index,
		);
		setResponsibilties([...withoutIndexElementOfTheArray]);
	}

	return (
		<>
			{/* FORM ALERTS! */}
			<MessageOrProcessing {...formState} />

			<form
				className="ff-urban dashboard-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="group">
						group
					</label>

					<select
						name="group"
						className="input-feild-dark"
						{...register("group", {
							required: "select one option",
						})}
						onKeyUp={() => trigger("group")}
					>
						<option value="">-no-</option>
						{groups?.map((group) => (
							<option
								key={group.key}
								value={group.key}
								className="text-sm capitalize tracking-wide"
							>
								{group.value}
							</option>
						))}
					</select>
					<span className="form-input-error-text">{errors.group?.message}</span>
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="worktype">
						employment work type
					</label>

					<select
						name="worktype"
						className="input-feild-dark"
						{...register("worktype", {
							required: "select one option",
						})}
						onKeyUp={() => trigger("worktype")}
					>
						<option value="">-no-</option>
						{workTypes?.map((work) => (
							<option
								key={work.key}
								value={work.value}
								className="text-sm capitalize tracking-wide"
							>
								{work.value}
							</option>
						))}
					</select>
					<span className="form-input-error-text">
						{errors.worktype?.message}
					</span>
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="title">
						designation or title
					</label>
					<input
						id="title"
						type="text"
						name="title"
						autoComplete="off"
						placeholder="Ex: Engineer React, Java, Python"
						className="input-feild-dark"
						{...register("title", {
							required: "Title is required.",
						})}
						onKeyUp={() => trigger("title")}
					/>
					<span className="form-input-error-text">{errors.title?.message}</span>
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="location">
						employment location / workplace
					</label>
					<input
						id="title"
						type="text"
						name="location"
						autoComplete="off"
						placeholder="Ex: Colombo City"
						className="input-feild-dark"
						{...register("location", {
							required: "Location / Workplace is required.",
						})}
						onKeyUp={() => trigger("location")}
					/>
					<span className="form-input-error-text">
						{errors.location?.message}
					</span>
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="expect">
						what we expect
					</label>
					<div className="-mt-0 flex flex-nowrap items-center">
						<input
							ref={expectRef}
							id="expect"
							type="text"
							name="expect"
							autoComplete="off"
							className="input-feild-dark"
						/>
						<button
							onClick={onAddExpect}
							type="button"
							className="ml-2 inline-flex flex-shrink-0
          rounded border border-dark-gunmetal/20
          p-2 text-gray-400
          hover:animate-pulse hover:text-dark-cyan"
						>
							<CgPlayListAdd size={24} />
						</button>
					</div>
					<DropList data={expects} remove={uncheckExpectList} />
				</div>

				<div className="dash-input-group">
					<label className="input-lbl" htmlFor="responsibilities">
						responsibilities
					</label>
					<div className="-mt-0 flex flex-nowrap items-center">
						<input
							ref={responsibilityRef}
							id="responsibilities"
							type="text"
							name="responsibilities"
							autoComplete="off"
							className="input-feild-dark"
						/>
						<button
							onClick={onAddResponsibilities}
							type="button"
							className="ml-2 inline-flex flex-shrink-0
          rounded border border-dark-gunmetal/20
          p-2 text-gray-400
          hover:animate-pulse hover:text-dark-cyan"
						>
							<CgPlayListAdd size={24} />
						</button>
					</div>
					{/* LIST */}
					<DropList
						data={responsibilities}
						remove={uncheckResponsibilityList}
					/>
				</div>

				<div className="ml-4 flex w-full items-center gap-2">
					<input
						type="checkbox"
						className="text-yankees-blue-900 focus:ring-0"
						checked={published === 1}
						onChange={() => setPublished(published === 1 ? 0 : 1)}
					/>
					<span className="text-base tracking-wide">
						Yes, want to publish this system information.
					</span>
				</div>

				{/* submit button */}

				{formState.processing ? (
					<div className="mt-4 flex w-full items-center  text-dark-cyan">
						<CgSpinner size={30} className="mr-2 animate-spin" />
						<span className=" text-sm tracking-wide"> Saving now...</span>
					</div>
				) : (
					<div
						className="mx-4 mt-8 flex w-full
					 items-center justify-between
					 space-x-4"
					>
						{form !== "advance" ? (
							<button
								onClick={resetForm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
								rounded bg-dark-jungle py-2.5 px-3 text-[15px]
          			font-medium capitalize tracking-wider text-white"
							>
								cancel
							</button>
						) : (
							<button
								onClick={handleClickConfirm}
								type="button"
								className="ff-urban inline-block w-full cursor-pointer
								rounded bg-dark-jungle/40 py-2.5 px-3 text-[15px]
          			font-medium capitalize tracking-wider text-white"
							>
								delete position
							</button>
						)}

						<button
							type="submit"
							className="ff-urban inline-block w-full
							cursor-pointer rounded bg-dark-cyan py-2.5 px-3 text-[15px]
							font-medium capitalize tracking-wider text-white"
						>
							{form !== "advance"
								? "save employment position"
								: "update employment position"}
						</button>
					</div>
				)}
			</form>
		</>
	);
}

export default React.memo(AddRPForm);
