import React from "react";
import SEO from "components/shared/seo";
import { allServices as Hero } from "assets/data/hero.dto";
import { qualityAssurance } from "assets/data/service.dto";
import HeroBasic from "components/shared/heros/basic/HeroBasic";
import TechServiceStack from "./components/TechServiceStack";
import ServiceOfProcess from "./components/ServiceOfProcess";
import EnhanceQuality from "./components/FloatLeftTextContent";
import AvoidBugQA from "./components/FloatRightTextContent";
import "./AllService.styles.css";

function QualityAssurancePage() {
	const { qualityassurance } = Hero;
	const { enhanceQuality, qaProcess, avoidBottleneck, techStack } =
		qualityAssurance;
	return (
		<>
			<SEO
				link="quality-assurance"
				postfixSlug="true"
				noIndex={false}
				title="Software QA Services - Enhance Software Quality and Avoid Bugs"
				description="Einteger Solutions offers a variety of software QA services to enhance the quality and reliability of your software. Our team provides end-to-end application testing, automated testing, usability testing, and accessibility testing. Our methodology helps you deliver systems that are able to respond quickly and be available without interruption. Browse our website to learn more about our testing solutions and how we can help you avoid bugs in software development."
				keywords="software QA, testing solutions, end-to-end application testing, automated testing, usability testing, accessibility testing, software reliability, software quality, bug avoidance, Einteger Solutions"
			/>
			<HeroBasic {...qualityassurance} />
			<EnhanceQuality {...enhanceQuality} />
			<AvoidBugQA {...avoidBottleneck} />
			<ServiceOfProcess {...qaProcess} />
			<TechServiceStack {...techStack} />
		</>
	);
}

export default QualityAssurancePage;
