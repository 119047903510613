import _ from "lodash";
import React, { useMemo, useState } from "react";

function MultipleFileUpload({ setFiles }) {
	let choosedFiles = [];
	const [localFiles, setLocalFiles] = useState([]);

	const handleFileChange = async (event) => {
		const { files = [] } = event.target;
		if (files.length > 0) {
			setLocalFiles(files);
			setFiles(files);
		}
	};

	const formatFileSize = (size) => {
		let szFormat = 0;
		const round = Math.round(size / 1024) || null;
		if (round >= 1000) {
			szFormat = ` ${(round / 1024).toFixed(2)} MB`;
		} else {
			szFormat = round ? ` ${round} KB` : null;
		}
		return szFormat;
	};

	choosedFiles = useMemo(() => {
		const newFiles = [];
		if (localFiles.length > 0) {
			_.forEach(localFiles, function (file) {
				newFiles.push({
					name: file?.name,
					type: file?.type,
					size: formatFileSize(file?.size),
				});
			});
		}
		return newFiles;
	}, [localFiles]);

	return (
		<div>
			<div
				className="
				multy-upload-wrapper mb-4
				flex items-center justify-between rounded
				border border-dashed p-3"
			>
				<input
					type="file"
					name="multifile"
					multiple="multiple"
					id="multifile"
					onChange={handleFileChange}
					accept="image/*"
					className="outline-none"
				/>
				{!_.isEmpty(choosedFiles) ? (
					<button
						type="button"
						className="rounded bg-dark-jungle/20
						px-3 py-1.5 capitalize text-white
						hover:bg-dark-gunmetal"
						onClick={() => {
							setLocalFiles([]);
							document.getElementById("multifile").value = "";
						}}
					>
						remove files
					</button>
				) : null}
			</div>
			<ul
				className="
				files-list h-auto
			 	max-h-[400px] basis-full
			  	space-y-3 overflow-y-auto"
			>
				{_.isEmpty(choosedFiles)
					? null
					: choosedFiles.map((f, i) => (
							<li
								key={i}
								className="
								box-border w-full
								rounded-md border-2
								border-dashed
								p-3 text-sm"
							>
								<strong className="mb-3 block text-dark-cyan">{f.name}</strong>
								<div
									className="
										flex w-full flex-wrap items-center justify-between
										tracking-wide text-arsenic-600"
								>
									<span>{f.size}</span>
									<span>{f.type}</span>
								</div>
							</li>
					  ))}
			</ul>
		</div>
	);
}

export default React.memo(MultipleFileUpload);
