//  tblemployment || exclusive
export const tblrecruitment = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "group", title: "group" },
		{ key: "title", title: "title" },
		{ key: "worktype", title: "work type" },
		{ key: "published", title: "status" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
	rows: [
		{
			id: "10002",
			title: "UI Interface Product Design Process and UX Flow",
			group: "Designers",
			worktype: "Full Time",
			status: "Published",
			createdAt: "01/10/2022",
		},
		{
			id: "10003",
			title: "Software Architect / Associate Software Architect ",
			group: "Engineering",
			worktype: "Contract Basis",
			status: "Published",
			createdAt: "04/11/2022",
		},
	],
};

export const tblblog = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "title", title: "title" },
		{ key: "publicLink", title: "public link url" },
		{ key: "published", title: "status" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
	rows: [
		{
			id: "10002",
			// thumbnail:	"",
			name: "Whatsapp promote social sharing button",
			publicLink: "https://www.google.com/",
			status: "Published",
			createdAt: "01/10/2022",
		},
		{
			id: "10003",
			// thumbnail:	"",
			name: "How to Write a Blog Post: A Step-by-Step Guide",
			publicLink: "https://blog.hubspot.com/marketing/how-to-start-a-blog",
			status: "Published",
			createdAt: "06/11/2022",
		},
	],
};

export const tbltestimonial = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "name", title: "name" },
		{ key: "designation", title: "designation" },
		{ key: "description", title: "description" },
		{ key: "published", title: "status" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
};

export const tblportfolio = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "title", title: "title" },
		{ key: "subtitle", title: "subtitle" },
		{ key: "subject", title: "subject" },
		{ key: "published", title: "status" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
	rows: [
		{
			id: "10002",
			title: "Kelly Felder",
			subtitle: "Website design for E-commerce fashion industry",
			subject: "UX/UI Design",
			published: "Published",
			createdAt: "01/10/2022",
		},
		{
			id: "10003",
			title: "Morpheus",
			subtitle: "Movement-based game control application",
			subject: "Artificial intelligence",
			published: "Unpublished",
			createdAt: "02/12/2022",
		},
	],
};

export const tblcandicates = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "designation", title: "designation" },
		{ key: "fullname", title: "full name" },
		{ key: "email", title: "email" },
		{ key: "phone", title: "phone" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
	rows: [
		{
			id: "10002",
			title: "Engineering",
			designation: "Software Architect / Associate Software Architect",
			fullname: "william franklyn miller",
			email: "williammiller@gmail.com",
			phone: "0705226920",
			createdAt: "06/12/2022",
		},
		{
			id: "10003",
			title: "Business Analysis",
			designation: "Intern Business Analysis",
			fullname: "lara flynn boyle",
			email: "laraboyle@gmail.com",
			phone: "0705776920",
			createdAt: "24/12/2022",
		},
	],
};

export const tblcontactus = {
	headings: [
		{ key: "id", title: "uuid" },
		{ key: "name", title: "name" },
		{ key: "email", title: "email" },
		{ key: "company", title: "website" },
		{ key: "phone", title: "phone" },
		{ key: "createdAt", title: "timestamp" },
		{ key: "setting", title: null },
	],
};
