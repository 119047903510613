import Hero1 from "assets/images/hero/hero-1.jpg";
import Hero2 from "assets/images/hero/hero-2.jpg";
import Hero3 from "assets/images/hero/hero-3.jpg";

import AIHero from "assets/images/basic-hero/ai-hero.jpg";
import DevelopmentHero from "assets/images/basic-hero/development-hero1.jpg";
import UiUxHero from "assets/images/basic-hero/uiux-hero.jpg";
import QAHero from "assets/images/basic-hero/qa-hero.jpg";
import { HiOutlineArrowRight } from "react-icons/hi";

export const mainHero = [
	{
		id: 1,
		title: "Expertly crafted software to fuel your success",
		para: "Our software development company helps businesses boost their growth by creating custom software solutions that meet their unique needs and goals. From developing custom applications to modernizing legacy systems, we have the expertise and experience to help your business succeed.",
		cta: "Contact a free consultation",
		image: Hero1,
	},
	{
		id: 2,
		title: "Expert solutions for reaching your target market",
		para: "Our software development company helps businesses find the best market for their products and services. With custom software solutions tailored to your unique needs, we can help you identify new opportunities and reach your target audience more effectively.",
		cta: "Contact a free consultation",
		image: Hero2,
	},
	{
		id: 3,
		title: "Expertly crafted software to guide your business",
		para: "Our software development company helps businesses achieve online success by creating custom software solutions that are tailored to their unique needs and goals. From developing custom applications to modernizing legacy systems, we have the expertise and experience to guide your business to success in the digital world.",
		cta: "Contact a free consultation",
		image: Hero3,
	},
];
//  Expertly crafted software to guide your business to success

export const allServices = {
	development: {
		tagname: "Software Development",
		title: "Software solutions that combine solid engineering with innovation",
		para: "Our software development team is dedicated to combining solid engineering with innovative solutions. We use the latest technologies and techniques to build custom software that meets the unique needs of our clients.",
		cta: {
			label: "Contact a free consultation",
			href: "/",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: DevelopmentHero,
	},
	uiux: {
		tagname: "UI/UX design",
		title: "UX about the overall feel of the experience.",
		para: "Our goal is to create exceptional products and user experiences that will make your digital products highly valued and beloved by users.",
		cta: {
			label: "Contact a free consultation",
			href: "/",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: UiUxHero,
	},
	artificial: {
		tagname: "Artificial Intelligencen",
		title: "It’s time you thought about what AI can do for you.",
		para: "Stay agile, transform your operational efficiency, innovation and performance with the power of AI",
		cta: {
			label: "Contact a free consultation",
			href: "/",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: AIHero,
	},

	qualityassurance: {
		tagname: "Quality Assurance",
		title: "Tailored Solution For Every Challenge",
		para: "We strive to ensure that your enterprise product is error-free and is excellent in performance, guaranteeing your system functionality is at its best.",
		cta: {
			label: "Book a free consultation",
			href: "/",
			icon: <HiOutlineArrowRight size={24} />,
		},
		image: QAHero,
	},
};
