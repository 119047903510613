export const AWS_AMPLIFY_HOST = {
	REGION: process.env.REACT_APP_REGION,
	USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
	WEB_CLIENT_ID: process.env.REACT_APP_WEB_CLIENT_ID,
	IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,

	AUTH_USER_POOL_ID: process.env.REACT_APP_AUTH_USER_POOL_ID,
	AUTH_WEB_CLIENT_ID: process.env.REACT_APP_AUTH_WEB_CLIENT_ID,

	S3_ACCESS_LEVEL: process.env.REACT_APP_ACCESS_LEVEL,
	S3_BUCKET: process.env.REACT_APP_BUCKET,
	S3_SIGNED_URL: process.env.REACT_APP_SIGNED_URL,
};
