import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import MessageOrProcessing from "components/form/message-or-processing";
import MultipleFileUpload from "components/shared/uploader/MultipleFileUpload";
import { createEvent, uploadFileActionEvent } from "services/all-admin-service";

import "../../Admin.styles.css";

function AddEventForm({ onAfterFormActionEnd }) {
	const [srfiles, srcFiles] = useState([]);
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		formState: { errors },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({
			...prev,
			processing,
			success,
			failed,
			message,
		}));
	};

	function resetForm() {
		reset();
		srcFiles([]);
	}

	async function fileUploadWithUrl(uploadUrl, file) {
		let hasuploaded = false;
		if (uploadUrl) {
			const fileResp = await uploadFileActionEvent(uploadUrl, file);
			const { uploadfile, uploaderr } = fileResp;
			if (uploadfile && uploadfile?.status === 200) {
				hasuploaded = true;
			} else if (uploaderr) {
				hasuploaded = false;
			}
		}

		return hasuploaded;
	}

	async function asyncHandleFile({ file, data }, callback) {
		const fileToUpload = {
			fileName: file?.name,
			contentType: "events",
			fileExt: file?.type?.split("/")[1],
		};

		const resp = await createEvent({
			...data,
			...fileToUpload,
		});

		if (resp.response?.status === 200) {
			const { data = {} } = resp.response;
			if (fileUploadWithUrl(data.uploadUrl, file)) {
				updateFormState(false, true, false, `${file.name} : OK`);
				callback();
			} else {
				updateFormState(
					false,
					false,
					true,
					`${file.name} : file unable to upload`,
				);
			}
		}
	}

	async function onSubmit(data) {
		let itemsProcessed = 0;
		const flen = srfiles?.length;

		if (flen !== 0) {
			_.forEach(srfiles, (file) => {
				updateFormState(true, false, false);
				asyncHandleFile({ file, data }, () => {
					itemsProcessed += 1;
					if (flen === itemsProcessed) {
						setTimeout(() => {
							resetForm();
							onAfterFormActionEnd();
						}, 2000);
					}
				});
			});
		}
	}

	function getDefaultEventTags() {
		return `new-event-${new Date().toISOString().split("T")}`;
	}

	return (
		<>
			{/* FORM ALERTS! */}
			<MessageOrProcessing {...formState} />
			<form
				className="ff-urban dashboard-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="dash-input-group-full">
					<label className="input-lbl" htmlFor="title">
						group / caption
					</label>
					<input
						id="caption"
						type="text"
						name="caption"
						autoComplete="off"
						placeholder=""
						defaultValue={getDefaultEventTags()}
						className="input-feild-dark"
						{...register("caption", {
							required: "caption is required.",
						})}
						onKeyUp={() => trigger("caption")}
					/>
					<span className="form-input-error-text">
						{errors.caption?.message}
					</span>
				</div>

				{/* File uploader */}
				<div className="mx-4 mt-4 mb-8 w-full">
					<MultipleFileUpload
						setFiles={(files) => {
							srcFiles(files);
						}}
					/>
				</div>

				{formState.processing ? (
					<div className="block w-full p-3">
						<CgSpinner
							size={30}
							className="mr-2 inline-block animate-spin text-dark-cyan"
						/>
						<span className="inline-block">Pending uploading files...</span>
					</div>
				) : null}

				<button
					type="submit"
					className="
					ff-urban active::opacity-60 m-3
					inline-block w-auto
					cursor-pointer whitespace-nowrap
					rounded bg-dark-cyan py-2.5 px-3
					text-[15px] font-medium
					capitalize tracking-wider
					text-white hover:opacity-60
					active:scale-95"
				>
					save photos
				</button>
			</form>
		</>
	);
}

export default AddEventForm;
