import React from "react";
import WorkGuideSlides from "components/work-guide-slides/WorkGuideSlides";
import {
	prodDevStepsGuidlines,
	vibleProdStepsGuidlines,
} from "components/work-guide-slides/steps-guide.data";

function HowItWorkLayout({ workType }) {
	return (
		<section className="mt-24 h-auto w-full">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="heading-title mx-auto max-w-3xl text-center">
					<h3 className="ff-sanspro mb-8 inline-block text-xl font-semibold text-arsenic-800">
						How it works
					</h3>
					<p className="ff-urban text-sm tracking-wider text-arsenic-600 lg:text-lg">
						{workType === "development"
							? "From initial consultation to final delivery, we work closely with you to create custom software solutions tailored to your goals"
							: workType === "viable-product"
							? "Experience the benefits of our transparent, efficient, and collaborative MVP development process."
							: null}
					</p>
				</div>

				<div className="work-step-guide-wrapper my-12 xl:my-24">
					<WorkGuideSlides
						guidelines={
							workType === "development"
								? prodDevStepsGuidlines
								: workType === "viable-product"
								? vibleProdStepsGuidlines
								: null
						}
					/>
				</div>
			</div>
		</section>
	);
}

// export default WorkStepsProcessSlider;
export default HowItWorkLayout;
