import _ from "lodash";
import React from "react";
import PLACEHOLDER_URL from "assets/images/placeholder.jpg";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";

function GerneralView({ exclusive = {} }) {
	function renderWithSubstring(text = "") {
		let renderText = text;
		if (renderText.length > 300) {
			renderText = `${renderText.substring(0, 300)}...`;
		}

		return renderText;
	}

	if (_.isEmpty(exclusive)) {
		return <FbSrcLoading />;
	}

	return (
		<div className="gerneral-view-wrapper">
			<div className="flex gap-2 px-2 py-4">
				<div className="left w-full space-y-4 lg:w-1/3">
					<div>
						<h4 className="text-sm uppercase">Reviewed by</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.name}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">designation</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.designation}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">status</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.published ? "Published" : "Unpublished"}
						</span>
					</div>
					<div>
						<h4 className="text-sm uppercase">created</h4>
						<span className="ff-urban text-sm tracking-wide text-gray-400">
							{exclusive?.createdAt}
						</span>
					</div>
				</div>
				<div className="right w-full lg:w-2/3">
					<div className="thumbnail relative h-full max-h-[325px] w-full overflow-hidden pl-4">
						<img
							src={exclusive?.fileUrl || PLACEHOLDER_URL}
							alt={exclusive?.title}
							className="h-full w-full object-contain"
						/>
						<div
							className="absolute bottom-0 left-0 ml-4 -mt-4 inline-block w-full
						 bg-white px-0 py-2 text-sm"
						>
							{exclusive?.fileName}
						</div>
					</div>
					<div className="w-full p-4 text-arsenic-500">
						<p>{renderWithSubstring(exclusive?.description)}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GerneralView;
