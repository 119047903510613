import React from "react";
import Portal from "components/Portal";
import { ImSpinner8 } from "react-icons/im";

function LoadingOverlay() {
	return (
		<Portal>
			<div className="fixed top-0 left-0 z-[999] h-full w-full bg-orange-50">
				<div className="overlay flex h-full w-full items-center justify-center">
					<div
						className="
						content mx-auto flex w-full
					  	max-w-lg items-center justify-center"
					>
						<div className="flex items-center gap-3">
							<ImSpinner8
								size={24}
								className="
								animate-spin
							  	text-halloween-orange-900"
							/>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}

export default LoadingOverlay;
