export const careers = {
	title: "Work with us",
	quotes:
		"Together, we can accomplish anything. Work with us and let's make it happen",
	jobsTypes: [
		{ tabIndex: 0, key: "engineering", value: "Engineering" },
		{ tabIndex: 1, key: "UI/Ux", value: "User Experience" },
		{ tabIndex: 2, key: "BA", value: "Business Analysis" },
		{ tabIndex: 3, key: "HR", value: "Management & HR" },
		{ tabIndex: 4, key: "marketing", value: "Marketing" },
	],
};

// tabIndex : no need for real world data => pass Id => fetch job-list
// ex: key:["engineer"]
export const careersJobs = [
	{
		id: 1,
		title: "Intern Artificial Intelligence Engineer",
		category: 0,
	},
	{
		id: 2,
		title: "Software Architect / Associate Software Architect",
		category: 0,
	},
	{ key: 3, title: "Tech Lead / Associate Tech Lead – React", category: 0 },
	{ key: 4, title: "Tech Lead / Associate Tech Lead – React", category: 0 },

	{ key: 4, title: "Senior UI / UX Designer", category: 1 },
	{ key: 5, title: "Intern UI / UX Designer", category: 1 },
	{ key: 6, title: "Intern Business Analysis", category: 2 },
	{ key: 7, title: "Technical Writer", category: 4 },
];

export const careersApply = {
	jobType: "full time",
	JobLocation: "colombo",

	infoBox: [
		{
			id: 1,
			title: "WHAT WE EXPECT",
			data: [
				{
					id: 1,
					text: "Holding a masters and/or bachelors degree in Information Technology/Computer Science or a related field",
				},
				{
					id: 2,
					text: "8+ years of experience in designing and implementing software applications",
				},
				{
					id: 3,
					text: "Outstanding collaboration and communication skills",
				},
			],
		},
		{
			id: 2,
			title: "RESPONSIBILITIES",
			data: [
				{
					id: 1,
					text: "Define all aspects of development from appropriate technology and workflow to coding standards",
				},
				{
					id: 2,
					text: "Provide technical guidance and coaching to engineers",
				},
			],
		},
	],
	form: {
		title: "Apply for this position",
		subtitle: "Fill the following fields and we will get back to you soon.",
		fields: [
			{
				key: "fullname",
				label: "Full Name *",
				type: "text",
				maxLength: null,
				pattern: null,
				error: "Fullname is required",
			},
			{
				key: "email",
				label: "E-Mail *",
				type: "email",
				maxLength: null,
				pattern: {
					value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
					message: "Email address is invalid",
				},
				error: "Email address is required",
			},

			{
				key: "phone",
				label: "Phone *",
				type: "text",
				maxLength: {
					value: 14,
					message: "Phone number length should less than 14 (+94) digits",
				},
				pattern: {
					value: /^[0-9]*$/gm,
					message: "Match only digits",
				},
				error: "Phone number is required",
			},
			{
				key: "reason",
				label: "Why do you want to join with us.? ",
				type: "textarea",
				maxLength: null,
				pattern: null,
				error: null,
			},
		],
	},
	subscribeText: "Subscribe to Einteger Solutions newsletter",
	checkAgreementText:
		"I agree to give the above personal data to Einteger Solutions. Einteger Solutions may use the data to evaluate, store for further processing and use anonymised data to train our models to improve our recruitment process.",
};
