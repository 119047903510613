import React from "react";
import Head from "react-helmet";

const BASE_DOMAIN = process.env.REACT_APP_HOST_PREFIX;
const BASE_KEYWORDS = "tech, software, development, mobile, system design";
const WEBSITE_NAME = "Einteger Solutions";
const DEFAULT_DESCRIPTION =
	"Marketing and promoting your app or mobile application to reach your target audience";

const FAVICON_SOURCE = `${BASE_DOMAIN}/favicon.ico`;

// const POSTFIX_SLUG = ` - ${DEFAULT_TITLE}`;

function SEO({
	title = "",
	description = DEFAULT_DESCRIPTION,
	link,
	keywords,
	imageLink = "",
	postfixSlug,
	noIndex,
	children,
}) {
	let metaTitle;

	if (postfixSlug) {
		metaTitle = `${WEBSITE_NAME} | ${title}`;
	} else {
		metaTitle = WEBSITE_NAME;
	}

	const metaDesc = description;
	let metaLink;
	if (link === "/") {
		metaLink = BASE_DOMAIN;
	} else {
		metaLink = `${BASE_DOMAIN}/${link}`;
	}

	const metaKeywords = keywords?.length > 0 ? keywords : BASE_KEYWORDS;

	let metaImageLink;

	if (imageLink.startsWith("https")) {
		metaImageLink = imageLink;
	} else {
		metaImageLink = `${BASE_DOMAIN} ${imageLink}`;
	}

	const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";

	return (
		<Head>
			<html lang="en" />

			<title>{metaTitle}</title>
			<meta name="description" content={metaDesc} />
			<meta name="keywords" content={metaKeywords} />
			<meta name="robots" content={metaRobots} />
			<link rel="canonical" href={metaLink} />
			<link rel="icon" content={FAVICON_SOURCE} />

			{/* OG Tags */}
			<meta property="og:url" title={metaLink} />
			<meta property="og:title" title={metaTitle} />
			<meta property="og:description" title={metaDesc} />
			<meta property="og:type" title="website" />
			<meta property="og:image" title={metaImageLink} />

			{/* Twitter Tags */}
			<meta property="twitter:site" title="https://www.einteger.com" />
			<meta property="twitter:title" title={metaTitle} />
			<meta property="twitter:description" title={metaDesc} />
			<meta property="twitter:creator" title="" />
			<meta property="twitter:image" title={metaImageLink} />

			<meta name="referrer" content="origin-when-crossorigin" />
			{/* Extra <meta/> tags */}
			{children}
		</Head>
	);
}

export default React.memo(SEO);
