import APIs from "constants/api-endpoints";
import ApiService from "utils/ApiService";

// ****************************** Testimonial Endpoints **********************************
async function getAllTestimonials() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchTestimonialsURL());
	} catch (err) {
		console.log("fetch has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getTestimonailById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.getTestimonialByIdURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function createTestimonial(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(
			APIs.saveWithUploadTestimonialURL(),
			{
				data,
			},
		);
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function updateTestimonial(data) {
	console.log(data);
	const result = { response: null, err: null };
	try {
		if (data.nofile) {
			result.response = await ApiService.put(
				APIs.updateOnlyAttrTestimonialURL(data.id),
				{
					data,
				},
			);
		} else {
			result.response = await ApiService.post(
				APIs.updateWithFileTestimonialURL(),
				{
					data,
				},
			);
		}
	} catch (err) {
		console.log("update has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteTestimonialById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deleteTestimonialURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ****************************** Constactus messages Endpoints **********************************

async function getAllContactusMessages() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchContactRequestMsgURL());
	} catch (err) {
		console.log("fetch has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getContactusMessageById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(
			APIs.getContactRequestMsgByIdURL(id),
		);
	} catch (err) {
		console.log("fetch has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteContactusById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deleteContactRequestURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ****************************** Careers applicant Endpoints **********************************

async function getAllApplicant() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchApplicantURL());
	} catch (err) {
		console.log("fetch has been failed cause:", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getApplicantPorfileById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.getApplicantByIdURL(id));
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteApplicantFileById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.delApplicantByIdURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ****************************** Portfolio Endpoints *************************************

async function getAllPortfolios() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchPortfolioURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getPortfolioById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.getPortfolioByIdURL(id));
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function createPortfolio(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.saveWithUploadPortfolioURL(), {
			data,
		});
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function updatePortfolio(data) {
	const result = { response: null, err: null };
	try {
		if (data.nofile) {
			result.response = await ApiService.put(
				APIs.updateOnlyAttrPortfolioURL(data.id),
				{
					data,
				},
			);
		} else {
			result.response = await ApiService.post(
				APIs.updateWithFilePortfolioURL(),
				{
					data,
				},
			);
		}
	} catch (err) {
		console.log("update has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deletePortfolio(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deletePortfolioURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getEmbedCaptionByPortfolioId(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(
			APIs.getPortfolioCaptionByPorfolioIdURL(id),
		);
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function createPortfolioCaption(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(
			APIs.saveWithUploadPortfolioCaptionURL(),
			{
				data,
			},
		);
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function updatePortfolioCaption(data) {
	const result = { response: null, err: null };
	try {
		if (data.nofile) {
			result.response = await ApiService.put(
				APIs.updateOnlyAttrPortfolioCaptionURL(data.id),
				{
					data,
				},
			);
		} else {
			result.response = await ApiService.post(
				APIs.updateWithFilePortfolioCaptionURL(),
				{
					data,
				},
			);
		}
	} catch (err) {
		console.log("update has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deletePortfolioCaption(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deletePortfolioCaptionURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ****************************** Team Events Endpoints ***************************************

async function getAllEvents() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchEventsURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function createEvent(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.saveWithUploadEventURL(), {
			data,
		});
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteEvent(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deleteEventByIdURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ****************************** Blogs Endpoints *******************************************

async function getAllBlogs() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.fetchBlogURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getBlogById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.getBlogByIdURL(id));
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function createBlogLink(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.saveWithUploadBlogURL(), {
			data,
		});
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function updateBlogLink(data) {
	const result = { response: null, err: null };
	try {
		if (data.nofile) {
			result.response = await ApiService.put(
				APIs.updateOnlyAttrBlogURL(data.id),
				{
					data,
				},
			);
		} else {
			result.response = await ApiService.post(APIs.updateWithFileBlogURL(), {
				data,
			});
		}
	} catch (err) {
		console.log("update has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteBlogLink(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.deleteBlogURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

// ***************************** Employment Endpoints **********************************
async function getAllEmployments() {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.saveOrFetchEmploymentURL());
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function getEmploymentById(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.get(APIs.getEmploymentByIdURL(id));
	} catch (err) {
		console.log("fetch has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function insertAnEmployment(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.post(APIs.saveOrFetchEmploymentURL(), {
			data,
		});
	} catch (err) {
		console.log("insert has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function updateAnEmployment(data) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.put(
			APIs.delOrUpdateEmploymentURL(data.id),
			{
				data,
			},
		);
	} catch (err) {
		console.log("update has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function deleteAnEmployment(id) {
	const result = { response: null, err: null };
	try {
		result.response = await ApiService.del(APIs.delOrUpdateEmploymentURL(id));
	} catch (err) {
		console.log("delete has been failed cause: ", err);
		result.err = err.message ? err.message : err;
	}
	return result;
}

async function uploadFileActionEvent(s3URL, file) {
	const result = { uploadfile: null, uploaderr: null };
	try {
		result.uploadfile = await ApiService.put(s3URL, {
			data: file,
		});
	} catch (err) {
		console.log("upload file has been failed cause: ", err);
		result.uploaderr = err.message ? err.message : err;
	}
	return result;
}

export {
	getAllApplicant,
	getApplicantPorfileById,
	insertAnEmployment,
	getAllEmployments,
	deleteApplicantFileById,
	getEmploymentById,
	updateAnEmployment,
	deleteAnEmployment,
	createBlogLink,
	updateBlogLink,
	getAllBlogs,
	getBlogById,
	deleteBlogLink,
	getAllPortfolios,
	getPortfolioById,
	createPortfolio,
	updatePortfolio,
	deletePortfolio,
	createPortfolioCaption,
	deletePortfolioCaption,
	updatePortfolioCaption,
	getEmbedCaptionByPortfolioId,
	getAllContactusMessages,
	getContactusMessageById,
	deleteContactusById,
	uploadFileActionEvent,
	createTestimonial,
	getAllTestimonials,
	deleteTestimonialById,
	getTestimonailById,
	updateTestimonial,
	getAllEvents,
	createEvent,
	deleteEvent,
};
