import React from "react";
import classnames from "classnames";

function ServiceOfProcess(props) {
	const { tagname, title, steps } = props;

	return (
		<section className="process-worker-section relative mb-8 h-full w-full overflow-hidden border bg-yankees-blue-500 lg:mb-16">
			{/* water mark design */}
			<div className="absolute -top-12 -left-12 h-72 w-72 rotate-45 transform rounded-xl bg-yankees-blue-800/20 bg-blend-multiply" />
			<div className="absolute -top-12 left-12 h-72 w-72 rotate-45 transform rounded-xl bg-yankees-blue-800/20 bg-blend-multiply" />
			<div className="absolute -bottom-12 right-12 h-72 w-72 rotate-45 transform rounded-xl bg-sky-800/20 bg-blend-multiply" />
			<div className="absolute -bottom-12 -right-12 h-72 w-72 rotate-45 transform rounded-xl bg-sky-800/20 bg-blend-multiply" />
			{/* water mark design */}
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="process-wrapper h-full w-full py-16">
					<div className="title-block w-full text-center text-white">
						<h4
							className="ff-sanspro-none block text-base font-medium uppercase
						tracking-wide lg:text-lg"
						>
							{tagname}
						</h4>
						<h1
							className="block py-4 text-3xl font-normal leading-[2.5rem]
						xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
						>
							{title}
						</h1>
					</div>

					<div className="step-block mt-6">
						<div className="step-grid-wrapper">
							<div className="grid w-full gap-4 md:grid-cols-2 lg:grid-cols-4">
								{steps.map((step, index) => (
									<div key={index} className="p-4">
										<div
											className={classnames("flex w-full -space-x-6", {
												"justify-end": step.num < 0,
												"justify-start": step.num < 4,
											})}
										>
											<div
												className={classnames(
													`cp-process-box-right inline-flex h-24 w-1/2 flex-shrink-0 items-center
											justify-start rounded-xl bg-gradient-to-r
											from-blue-500 to-purple-500 pl-8
											text-[2.5rem] text-white xl:text-[3.5rem] `,
												)}
											>
												{step.icon}
											</div>
											<div
												className="cp-process-arrow-right inline-flex h-24 w-1/2 flex-shrink-0 items-center
											justify-center	bg-gradient-to-r from-purple-500 to-sky-500
											pl-2 text-[2rem] font-bold text-white xl:text-[2rem] "
											>
												0{step.num}
											</div>
										</div>

										<div className="ff-urban my-4 text-xl font-medium tracking-wide text-white">
											{step.title}
										</div>
										<div className="ff-urban text-sm font-light leading-6 tracking-wider text-white">
											{step.para}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ServiceOfProcess;
