import _ from "lodash";
import React, { useState } from "react";
import FbSrcLoading from "components/shared/fallback-spin/FbSrcLoading";
import { MdDeleteForever, MdShare } from "react-icons/md";
import { deleteContactusById } from "services/all-admin-service";
import MessageOrProcessing from "components/form/message-or-processing";

function GerneralViewMessages({ exclusive = {}, onAfterFormActionEnd }) {
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const updateOutlookState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	async function handleClickConfirm() {
		const confirm = window.confirm(
			"Are you sure you want to delete everything?",
		);

		if (confirm) {
			updateOutlookState(true, false, false);
			const resp = await deleteContactusById(exclusive?.id);

			if (resp.response?.status === 200) {
				updateOutlookState(false, true, false, "deleted!");
				onAfterFormActionEnd();
			} else if (resp?.err) {
				updateOutlookState(false, false, true, resp.err);
			}
		}
	}

	console.log(formState);

	if (_.isEmpty(exclusive)) {
		return <FbSrcLoading />;
	}

	return (
		<>
			<MessageOrProcessing {...formState} />
			<div className="gerneral-view-wrapper">
				{/* FORM ALERTS! */}
				<div className="ff-urban  flex flex-wrap text-arsenic-900">
					<div className="w-full md:w-1/3 ">
						<div className="w-full space-y-3 text-sm">
							<div className="text-arsenic-500">
								<span className="block font-bold capitalize text-arsenic-900">
									who contact us
								</span>
								{exclusive?.name}
							</div>
							<div className="text-arsenic-500">
								<span className="block font-bold capitalize text-arsenic-900">
									email address
								</span>
								{exclusive?.email}
							</div>
							<div className="text-arsenic-500">
								<span className="block font-bold capitalize text-arsenic-900">
									mobile
								</span>
								{exclusive?.phone}
							</div>
						</div>
					</div>
					<div className="my-6 min-h-[25vh] w-full md:mt-0 md:w-2/3 md:border-l">
						<div className="lg:px-6">
							<h3 className="text-xl font-bold capitalize">
								{exclusive?.company}
							</h3>
							<p className="mt-3 text-sm leading-6 tracking-wide">
								{exclusive?.reason}
							</p>
						</div>
					</div>
				</div>
				<div
					className="resume-actions flex w-full
			 flex-wrap items-center justify-end gap-3"
				>
					<button
						type="button"
						onClick={handleClickConfirm}
						className="
					ff-urban inline-block
					w-auto cursor-pointer
				  rounded border
				 	py-2.5 px-3 text-[15px]
					font-medium capitalize tracking-wider
					text-dark-gunmetal/40 hover:text-dark-gunmetal"
					>
						<MdDeleteForever size={24} />
					</button>
					<button
						type="button"
						onClick={(e) => console.log(e)}
						className="
					ff-urban inline-block
					w-auto cursor-pointer
				  rounded border
				 	py-2.5 px-3 text-[15px]
					font-medium capitalize tracking-wider
					text-dark-gunmetal/40 hover:text-dark-gunmetal"
					>
						<MdShare size={24} />
					</button>
				</div>
			</div>
		</>
	);
}

export default React.memo(GerneralViewMessages);
