import React from "react";

function SimpleBannerLayout({
	tagname,
	title,
	para,
	cta,
	image,
	ctaLinkEvent,
}) {
	return (
		<section className="mt-0 h-full min-h-[50vh] w-full lg:mt-12">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="box-container flex flex-wrap justify-between px-0 py-4 lg:py-12">
					<div className="order-2 w-full lg:w-1/2">
						<div className="mb-8 border-0 text-arsenic-900 xl:mb-0">
							<h4
								className="ff-sanspro hidden text-lg font-semibold
								capitalize text-orange-700 lg:block"
							>
								{tagname}
							</h4>
							<div className="content max-w-[94%] lg:max-w-[90%] xl:max-w-[75%]">
								<h1
									className="block py-4 text-3xl
							 		lg:text-4xl lg:leading-[3rem] xl:text-4xl xl:leading-[3rem] 2xl:text-5xl 2xl:leading-[4rem]"
								>
									{title}
								</h1>
								<p className="ff-urban mb-8 text-sm tracking-wider text-arsenic-800 lg:text-base">
									{para}
								</p>
							</div>
							<button
								type="button"
								className="ff-urban-x btn btn-fill flex items-center
								gap-4 font-medium hover:animate-pulse"
								onClick={() => ctaLinkEvent(cta.href)}
							>
								{cta.label}
								{cta.icon}
							</button>
						</div>
					</div>
					<div className="order-1 w-full lg:order-2 lg:w-1/2">
						<h4
							className="ff-sanspro block py-2 text-lg font-semibold capitalize
							text-orange-700 lg:hidden"
						>
							{tagname}
						</h4>
						<div className="w-full lg:max-w-[400px] xl:max-w-[490px] 2xl:max-w-[575px]">
							<img
								src={image}
								alt={title}
								loading="lazy"
								className="h-full w-full object-contain"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SimpleBannerLayout;
