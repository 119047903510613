import React from "react";
import { visionAndMission } from "assets/data/aboutus.dto";

function VisionAndMission() {
	const { vision, mission } = visionAndMission;
	return (
		<section className="vision-mission-section relative mt-24 mb-8 h-full w-full lg:mb-44">
			{/* absolute */}
			<div className="absolute h-[40vh] w-full overflow-hidden bg-yankees-blue-500 lg:h-[30vh]">
				{/* water mark design */}
				<div className="absolute top-[45%] left-[4%] h-16 w-16 rotate-45 transform rounded-xl  bg-yankees-blue-400 bg-blend-multiply" />
				<div className="absolute top-0 left-[2%] h-24 w-24 rotate-45 transform rounded-xl  bg-yankees-blue-800/20 bg-blend-multiply" />
				<div
					className="-top[4%] absolute left-[2%] h-36 w-36 rotate-45 transform rounded-xl bg-yankees-blue-800/20 bg-blend-multiply
				  lg:-top-[4%] lg:left-[6%]"
				/>

				<div className="absolute bottom-[50%] right-[2%] h-16 w-16 rotate-45 transform rounded-xl  bg-yankees-blue-400 bg-blend-multiply" />
				<div className="absolute bottom-0 right-[0%] h-20 w-20 rotate-45 transform rounded-xl  bg-yankees-blue-800/20 bg-blend-multiply" />
				<div
					className="absolute bottom-[0%] right-[2%] h-32 w-32 rotate-45 transform rounded-xl bg-yankees-blue-800/20 bg-blend-multiply
				  lg:bottom-[10%] lg:right-[6%]"
				/>
			</div>

			{/* absolute */}
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div
					className="vision-mission-wrapper relative
				 top-5 border-2 border-dashed border-arsenic-100 lg:top-20 xl:p-4"
				>
					{/* BOX */}
					<div className="grid xl:grid-cols-2">
						{/* VISION */}
						<div className="vision-box m-4 bg-white shadow-xl">
							<div className="w-full p-8 text-center xl:p-10">
								<div className="flex items-center justify-center">
									<h2
										className="ff-sanspro text-2xl font-semibold
										capitalize text-arsenic-900
										lg:text-3xl xl:text-4xl"
									>
										{vision.title}
									</h2>
									<img
										className="
										w-[100px] object-contain
										object-center
										lg:w-[120px] xl:w-[160px]"
										src={vision.image}
										alt={vision.title}
									/>
								</div>
								<p
									className="
									ff-urban py-4 text-sm font-medium leading-6
								 	tracking-wider text-arsenic-600"
								>
									{vision.para}
								</p>
							</div>
						</div>

						{/* MISSION */}
						<div className="mission-box m-4  bg-white shadow-xl">
							<div className="w-full p-8 text-center xl:p-10">
								<div className="flex items-center justify-center">
									<h2
										className="ff-sanspro text-2xl font-semibold
										capitalize text-arsenic-900
										lg:text-3xl xl:text-4xl"
									>
										{mission.title}
									</h2>
									<img
										className="
										w-[100px] object-contain
										object-center
										lg:w-[120px] xl:w-[160px]"
										src={mission.image}
										alt={mission.title}
									/>
								</div>
								<p
									className="
									ff-urban py-4 text-sm font-medium leading-6
								 	tracking-wider text-arsenic-600"
								>
									{mission.para}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default VisionAndMission;
