import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "components/shared/header";
import Footer from "components/shared/footer";

function MainLayout() {
	const location = useLocation();
	const [trans, setTrans] = useState(false);

	let transInterval = null;

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		setTrans(true);

		transInterval = setTimeout(() => setTrans(false), 500);

		return () => clearInterval(transInterval);
	}, [location]);

	const getFlashText = (stringToReplace) => {
		const desiredText = stringToReplace.replace(/[^\w\s]/gi, " ");
		if (desiredText === " ") return null;
		return desiredText;
	};

	return (
		<div
			className="
			flex
			h-full
			min-h-screen
			w-full
			flex-col"
		>
			{trans && (
				<div
					className="
					absolute top-0 z-[999] flex h-screen w-full items-center justify-center
					bg-gradient-to-t from-gray-100 via-halloween-orange-100 to-orange-50"
				>
					<span
						className="
						ff-urban animate-pulse text-center text-[21px] font-semibold
					 	capitalize tracking-wider text-gray-600 md:text-[1.5vw]"
					>
						{getFlashText(location?.pathname)}
					</span>
				</div>
			)}

			<div>
				<Header />
			</div>
			<main>
				<Outlet />
			</main>
			<div className="mt-auto">
				<Footer />
			</div>
		</div>
	);
}

export default MainLayout;
