import _ from "lodash";
import React, { useEffect, useState } from "react";

import {
	getAllApplicant,
	getApplicantPorfileById,
} from "services/all-admin-service";

import PopupWizardOverlay from "components/shared/popup-wizard";
import FormPopupWizard from "../components/FormPopupWizard";
import DataTableView from "../components/DataTableView";
import GerneralViewResume from "../components/candidate/GerneralViewResume";
import ExclusiveSetting from "../components/ExclusiveSetting";
import { tblcandicates } from "../admin.data";

const modalInitialState = { modal: null, state: false };

function AdminCandidatesResumePage() {
	const [viewModal, setViewModal] = useState(modalInitialState);
	const [tableSheet, setTableSheet] = useState([]);
	const [openRow, setOpenRow] = useState(null);

	async function onLoadTableSheet() {
		const res = await getAllApplicant();
		if (res?.response && res?.response.status === 200) {
			const { response } = res;
			setTableSheet(response.data);
		}
	}

	async function getRowById(id) {
		if (id === null) return;

		const res = await getApplicantPorfileById(id);

		if (res.response?.status === 200) {
			const { response } = res;
			setOpenRow(response.data);
		}
	}

	useEffect(() => {
		onLoadTableSheet();
	}, []);

	function handleUpdatePage(request) {
		console.debug(request);
	}

	function handleModal(modal, row = null) {
		if (!_.isNil(row)) {
			getRowById(row.id);
		}
		setViewModal((prev) => ({
			modal,
			state: !prev.state,
		}));
	}

	function handleExitModal() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
	}

	function reloadTableSheet() {
		onLoadTableSheet();
	}

	function modalHead(modal = "") {
		let title = null;

		if (modal === "search-resume") {
			title = "Search candidate resume";
		} else if (modal === "settings") {
			title = "View candidate resume";
		}

		return title;
	}

	function handleAfterFormActionEnd() {
		getRowById(null);
		setOpenRow(null);
		setViewModal(modalInitialState);
		// reload table-sheet
		setTimeout(() => reloadTableSheet(), 3000);
	}

	return (
		<section className="board-view-section h-full w-full">
			<div className="container mx-auto px-4 xl:px-0">
				<div className="content-wrapper my-14 border-0 px-4">
					<div className="content-header block items-center justify-between lg:flex">
						<div className="top-left">
							<h3 className="ff-urban text-xl font-bold uppercase text-dark-jungle xl:text-2xl">
								Candidates / Candidates Resume
							</h3>

							<p
								className="ff-urban max-w-full py-2
								text-base tracking-wider text-dark-jungle/70 lg:max-w-[80%] xl:lg:max-w-[60%]"
							>
								A resume should tell a story about an applicant&apos;s
								education, experiences and interests, not just list jobs or
								skills they think you want to see.
							</p>
						</div>
					</div>
					{/* END HEADER */}

					<DataTableView
						onrefresh={reloadTableSheet}
						handleClickActionButton={handleModal}
						handlePageNavsClick={handleUpdatePage}
						theadCols={tblcandicates.headings}
						sheet={tableSheet}
						cols={[
							"id",
							"designation",
							"fullname",
							"email",
							"phone",
							"createdAt",
						]}
					/>
					{/* content-Panel box end */}
				</div>
			</div>

			{/* Modal popup modal */}
			<PopupWizardOverlay
				isOpen={viewModal.state}
				handleClickOverlay={() => console.debug("overlay")}
			>
				<FormPopupWizard
					onExit={handleExitModal}
					title={modalHead(viewModal.modal)}
				>
					{viewModal.modal === "settings" ? (
						<ExclusiveSetting
							tabsviews={[
								<GerneralViewResume
									exclusive={openRow}
									onAfterFormActionEnd={handleAfterFormActionEnd}
								/>,
							]}
						/>
					) : null}
				</FormPopupWizard>
			</PopupWizardOverlay>
		</section>
	);
}

export default AdminCandidatesResumePage;
