import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { subscribeForNewsletter } from "services/all-client-service";

function Newsletter() {
	const [formState, setFormState] = useState({
		processing: false,
		success: false,
		failed: false,
		message: null,
	});

	const {
		register,
		handleSubmit,
		trigger,
		reset,
		formState: { errors },
	} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

	const updateFormState = (processing, success, failed, message = "") => {
		setFormState((prev) => ({ ...prev, processing, success, failed, message }));
	};

	async function onSubmit(email) {
		try {
			if (_.isEmpty(errors) && !_.isEmpty(email)) {
				updateFormState(true, false, false);
				const subscribed = await subscribeForNewsletter(email);
				console.log(subscribed);
				if (subscribed && subscribed.response !== null) {
					updateFormState(false, true, false);
					reset();
				} else if (subscribed?.err) {
					updateFormState(false, false, true, subscribed.err);
				}
			}
		} catch (error) {
			console.debug(error);
		} finally {
			setTimeout(() => {
				updateFormState(false, false, false);
			}, 5000);
		}
	}
	const onError = (errors) => {
		console.log(errors);
	};

	return (
		<section className="my-16 h-auto w-full ">
			<div className="container mx-auto px-4 lg:px-16 xl:px-20 2xl:px-24">
				<div className="w-full px-0 lg:px-28 xl:px-52">
					{formState.success ? (
						<div className="ff-urban mb-4 px-2 text-sm font-semibold text-arsenic-600">
							Thank you for{" "}
							<b className="text-halloween-orange-900">subscribed newsletter</b>
							. We will keep your information.
						</div>
					) : null}
					{formState.failed ? (
						<div className="ff-urban mb-4 px-2 text-xs font-semibold text-red-800">
							<p>
								{formState?.message ||
									"Sorry ,unable send your subscription. try again.!"}
							</p>
						</div>
					) : null}
					<div
						className="rounded-2xl bg-gradient-to-r
					 from-[#de6262] to-[#ffb88c] px-8  py-10 lg:px-12"
					>
						<div className="text-content text-center text-white">
							<h2 className="mb-4 text-center text-2xl font-bold lg:text-3xl  xl:text-4xl ">
								Subscribe for our Newsletter
							</h2>
							<p className="ff-urban text-sm tracking-wider lg:text-base">
								Subscribe to get latest updates on our vacancies, events and
								much more
							</p>
						</div>
						<div className="form-group mt-10">
							<div className="form-msg mx-auto flex w-full max-w-2xl">
								<span
									className="
									form-input-error-text ff-urban
									relative text-sm tracking-wide
								  text-white"
								>
									{errors.subscribeEmail?.message}
								</span>
							</div>

							<form
								className="
								mx-auto flex w-full max-w-2xl
                				items-center gap-4 text-arsenic-900"
								onSubmit={handleSubmit(onSubmit, onError)}
							>
								<input
									type="email"
									name="subscribeEmail"
									className="focus:bottom-none w-full flex-1
                  					flex-shrink rounded border-none bg-white/40
									py-2 px-2 tracking-wide text-white focus:outline-none
                  					focus:ring-0 md:py-3 md:px-4  md:indent-3"
									autoComplete="off"
									{...register("subscribeEmail", {
										required: "Your email is required",
									})}
									onKeyUp={() => trigger("subscribeEmail")}
								/>
								<button
									type="submit"
									className="
									flex-shrink-0 whitespace-nowrap rounded bg-white py-2.5
                  					px-2 text-sm font-medium hover:opacity-60 md:py-3 md:px-4
									md:text-base"
								>
									{formState.processing ? (
										<CgSpinner
											size={20}
											className="animate-spin text-orange-600"
										/>
									) : (
										<span>Subscribe</span>
									)}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Newsletter;
